import {
    Account,
    AccountInfo,
    AccountSetupProgress,
    Activity,
    Administrator,
    EmailSettings,
    Invoice,
    Notes,
    Opportunities,
    ProjectedCharges,
    Resource,
    Skus,
    StarterOutcome,
    User,
} from './types';

export enum ResponseCode {
    // 0-49: General
    Success = 0,
    UnknownError = 1,
    NotAuthorized = 2,
    AuthenticateFailure = 3,
    ClaimNotFound = 4,
    ApiTokenFailure = 5,
    NotFound = 6,
    RecordExists = 7,
    MissingNotification = 10,
    NoLicenseAvailable = 11,
    WithinTwoWeekPeriod = 12,

    //50-99 Invalid Data
    InvalidParent = 50,
    InvalidRelationship = 51,
    InvalidLicense = 52,
    InvalidCustomer = 53,
    InvalidApplication = 54,
    InvalidTenant = 55,
    InvalidReseller = 56,
    InvalidResellerData = 57,
    InvalidUser = 58,
    InvalidOrder = 59,
    InvalidHeader = 60,

    // 100-199 User
    UserNotFound = 100,
    UserInactive = 101,
    UserMeasureOnly = 102,
    NotEnoughEngagementPoints = 103,
    NoImageFound = 104,
    UserLicenseTypeNotStarter = 105,
    UserDataNotComplete = 106,
    NoLicense = 107,
    UserExists = 108,
    UserIsStarter = 109,

    // 200-299 Skills
    InvalidSkill = 200,
    SkillLevelLocked = 201,
    PreviousSkillLevelLocked = 202,
    SkillNotActive = 203,
    MissingSkillLevels = 204,
    MissingUserSkill = 205,
    MustUnlockLowerSkillLevel = 210,
    SkillLevelNotAvailable = 211,

    // 300-399 Outcomes
    MissingUserOutcome = 301,
    OutcomeAlreadySet = 302,

    // 400 - 499 Customer/Tenant/Reseller
    CustomerNotFound = 400,
    TenantNotFound = 401,
    ResellerNotFound = 402,
    CustomerConnected = 403,
    CustomerNotStarter = 404,
    CustomerExists = 405,
    ResellerExists = 406,
    MissingTenant = 407,
    TenantNotActive = 408,
    NoInvoice = 409,

    //500 - 599 Assignment
    CannotUnassignManagerAssignedSkill = 501,
    SkillAssignmentNotFound = 502,
    CannotUnassignOutcomeAssignedSkill = 503,
    ManagerSkillAssignmentNotFound = 504,
    CannotUnassignManagerAssignedOutcome = 505,
    OutcomeAssignmentNotFound = 506,
    ManagerOutcomeAssignmentNotFound = 507,
    CannotUnassignUserSelectedOutcome = 508,
    NoHigherOutcomeLevels = 509,
    NoHigherSkillLevel = 510,
}

export enum Status {
    Error = -1,
    Success = 0,
    Warning = 1,
}

export interface ResponseBase {
    code: ResponseCode;
    status: Status;
    sessionId: string;
}

export interface UserResponse extends ResponseBase {
    data: User;
}

export interface AccountsResponse extends ResponseBase {
    data: Account[];
}

export interface AdministratorsResponse extends ResponseBase {
    data: Administrator[];
}

export interface ActivitiesResponse extends ResponseBase {
    data: Activity[];
}

export interface NotesResponse extends ResponseBase {
    data: Notes;
}

export interface ResourceResponse extends ResponseBase {
    data: Resource[];
}

export interface AccountSetupProgressResponse extends ResponseBase {
    data: AccountSetupProgress;
}

export interface OpportunitiesResponse extends ResponseBase {
    data: Opportunities;
}

export interface StarterOutcomesResponse extends ResponseBase {
    data: StarterOutcome[];
}

export interface InvoicesResponse extends ResponseBase {
    data: Invoice[];
}

export interface SkusResponse extends ResponseBase {
    data: Skus;
}

export interface ProjectedChargesResponse extends ResponseBase {
    data: ProjectedCharges;
}

export interface AccountInfoResponse extends ResponseBase {
    data: AccountInfo;
}

export interface EmailSettingsResponse extends ResponseBase {
    data: EmailSettings;
}
