import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { LOGOS_PATH, VERSION } from '../../utils/constants';
import Tabs from './Tabs';
import { AccessLevel, Tab } from '../../types/types';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../../store/StoreProvider';
import SearchAccounts from '../accounts/SearchAccounts';
import MenuButton from '../buttons/MenuButton';
import { Colors, Version } from 'common';

const useStyles = makeStyles({
    header: {
        minHeight: 64,
        maxHeight: 64,
        height: 64,
        backgroundColor: '#f9f9f9',
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 32,
        paddingRight: 32,
        width: '100%',
        position: 'fixed',
        zIndex: 2,
    },
    logoTitle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    logo: {
        width: 56,
        minWidth: 56,
        marginRight: 16,
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontSize: 14,
        letterSpacing: 2,
        lineHeight: '24px',
        marginRight: 10,
    },
    searchTabs: {
        display: 'flex',
        alignItems: 'center',
    },
    links: {
        display: 'flex',
        alignItems: 'center',
    },
});

const Header: FC = () => {
    const classes = useStyles();
    const [mainRoute, setMainRoute] = useState<string>('/accounts');
    const { user, strings, account } = useStore();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (user?.accessLevel === AccessLevel.PlatformAdmin) {
            setMainRoute('/partners');
        }
    }, [user]);

    const openAccounts = () => history.push(mainRoute);

    const isAdmin = user?.accessLevel === AccessLevel.PlatformAdmin;

    // TODO add remaining strings to strings object

    const tabs: Tab[] = [
        {
            name: isAdmin ? 'PARTNERS' : strings?.accounts ?? '',
            selected:
                location.pathname === mainRoute ||
                (account ? location.pathname === `/account-detail/${account.id}` : false),
            onClick: () => history.push(mainRoute),
        },
        {
            name: strings?.resources ?? '',
            selected: location.pathname === '/resources',
            onClick: () => history.push('/resources'),
        },
        {
            name: strings?.contact ?? '',
            selected: location.pathname === '/contact',
            onClick: () => history.push('/contact'),
        },
        {
            name: isAdmin ? user?.userName.toUpperCase() ?? '' : user?.resellerName.toUpperCase() ?? '',
            selected: location.pathname.includes('/partner-details'),
            onClick: () => history.push('/partner-details/statements'),
        },
    ];

    return (
        <div className={classes.header}>
            <div className={classes.logoTitle} onClick={openAccounts}>
                <img className={classes.logo} alt="logo" src={`${LOGOS_PATH}nulia_logo_small.svg`} />
                <div className={classes.title}>PARTNER PORTAL</div>
                <Version version={VERSION} style={{ fontSize: 12 }} />
            </div>
            <div className={classes.searchTabs}>
                {!isAdmin && (
                    <div style={{ marginRight: 16 }}>
                        <SearchAccounts resellerId={user?.resellerId} searchWidth={378} />
                    </div>
                )}
                <Tabs tabs={tabs} />
                <MenuButton />
            </div>
        </div>
    );
};

export default Header;
