import { makeStyles, Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStore } from '../store/StoreProvider';
import { CURRENT_PATHNAME_KEY, LAST_PATHNAME_KEY } from '../utils/constants';
import { AuthService } from './AuthService';
import * as Sentry from '@sentry/browser';
import { Colors } from 'common';
import { localizedStrings } from '../utils/strings';
import { useApiService } from './ApiServiceProvider';
import { AccessLevel } from '../types/types';
import { Snackbar } from 'common';
import NoAccountDialog from '../components/dialogs/NoAccountDialog';

const useStyles = makeStyles({
    dialogTitle: {
        fontSize: 20,
    },
    dialogText: {
        fontSize: 16,
    },
    loggingInSnackbar: {
        backgroundColor: '#44346E',
        boxShadow: '0 1.5px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.16)',
    },
    snackbarText: {
        color: Colors.white,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
    },
});

const AuthorizationProvider: FC = ({ children }) => {
    const classes = useStyles();
    const {
        user,
        setUser,
        loggingIn,
        setLoggingIn,
        showWarning,
        setShowWarning,
        setStrings,
        openSnackbar,
        setOpenSnackbar,
        snackbarData,
    } = useStore();
    const history = useHistory();
    const location = useLocation();
    const ApiService = useApiService();
    const [openNoAccountDialog, setOpenNoAccountDialog] = useState<boolean>(false);

    useEffect(() => {
        if (!loggingIn && !showWarning) {
            checkAuth();
        }

        localizedStrings.setLanguage('en-US');
        setStrings(localizedStrings);
    });

    const closeLoggingIn = () => setLoggingIn(false);

    const checkAuth = async () => {
        const account = AuthService.getAccount();

        if (account && !user && !loggingIn && !showWarning && !openNoAccountDialog) {
            setLoggingIn(true);
            const idToken = account.idToken as { oid: string; tid: string };

            const response = await ApiService.getUser(idToken.oid);
            if (!response) {
                setLoggingIn(false);
                setShowWarning(true);
                return;
            } else if (response && response.status < 0) {
                setLoggingIn(false);
                setOpenNoAccountDialog(true);
                return;
            }

            const newUser = response.data;
            newUser.oid = idToken.oid;
            newUser.sid = response.sessionId;
            setUser(newUser);

            Sentry.configureScope((scope: Sentry.Scope) => {
                scope.setUser({ id: idToken.oid, tid: idToken.tid, sid: response.sessionId });
            });

            setLoggingIn(false);
            history.push(newUser.accessLevel === AccessLevel.PlatformAdmin ? '/partners' : '/accounts');
        } else if (account && user) {
            const storedPathname = sessionStorage.getItem(CURRENT_PATHNAME_KEY);
            if (storedPathname) {
                sessionStorage.setItem(LAST_PATHNAME_KEY, storedPathname);
            }
            const currentPathname = window.location.pathname;
            sessionStorage.setItem(CURRENT_PATHNAME_KEY, currentPathname);
        } else {
            if (!['/', '/auto-login', '/login'].includes(location.pathname)) {
                sessionStorage.clear();
                history.push('/login');
            }
        }
    };

    return (
        <>
            {children}
            <MuiSnackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={loggingIn}
                onClose={closeLoggingIn}
            >
                <SnackbarContent
                    className={classes.loggingInSnackbar}
                    message={<span className={classes.snackbarText}>Logging in</span>}
                />
            </MuiSnackbar>
            {snackbarData && (
                <Snackbar
                    open={openSnackbar}
                    message={snackbarData.message}
                    onCloseSnackbar={setOpenSnackbar}
                    autoHide={snackbarData.autoHide}
                    onClick={snackbarData.onClick}
                    buttonLabel={snackbarData.buttonLabel}
                />
            )}
            <NoAccountDialog open={openNoAccountDialog} setOpen={setOpenNoAccountDialog} />
        </>
    );
};

export default AuthorizationProvider;
