import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
    monthBox: {
        width: 48,
        minWidth: 48,
        maxWidth: 48,
        height: 20,
        minHeight: 20,
        maxHeight: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginBottom: 8,
    },
    month: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.8)',
    },
    currentMonth: {
        fontWeight: 600,
    },
    inner: {
        marginRight: 1,
    },
});

interface Props {
    month: string;
    current: boolean;
    inner: boolean;
}

const MonthBox: FC<Props> = ({ month, current, inner }) => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.monthBox, inner && classes.inner)}>
            <div className={clsx(classes.month, current && classes.currentMonth)}>{month}</div>
        </div>
    );
};

export default MonthBox;
