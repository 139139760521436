import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { RemainingUsageState } from '../../../types/types';

const useStyles = makeStyles({
    remainingBox: {
        width: 48,
        minWidth: 48,
        maxWidth: 48,
        height: 24,
        minHeight: 24,
        maxHeight: 24,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    currentMonthBox: {
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
    remainingNumber: {
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 1.6,
        letterSpacing: 0.42,
        color: 'rgba(0, 0, 0, 0.48)',
    },
    pastMonth: {
        color: 'rgba(0, 0, 0, 0.64)',
    },
    inner: {
        marginRight: 1,
    },
});

interface Props {
    remainingUsageState: RemainingUsageState;
    remainingNumber: number;
    inner: boolean;
}

const RemainingBox: FC<Props> = ({ remainingUsageState, remainingNumber, inner }) => {
    const classes = useStyles();
    return (
        <div
            className={clsx(
                classes.remainingBox,
                remainingUsageState === RemainingUsageState.CurrentMonth && classes.currentMonthBox,
                inner && classes.inner
            )}
        >
            <div
                className={clsx(
                    classes.remainingNumber,
                    remainingUsageState === RemainingUsageState.PastMonth && classes.pastMonth
                )}
            >
                {remainingNumber}
            </div>
        </div>
    );
};

export default RemainingBox;
