import { ChangeEvent, FC, useState } from 'react';
import { Account, AccountType, LicenseClass, LicenseType } from '../../types/types';
import { makeStyles } from '@material-ui/core';
import { Colors, Dialog, Input } from 'common';
import { getActivities, getValidLicenseInput } from '../../utils/helper';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    addLicensesDialog: {},
    label: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
    },
});

interface Props {
    account: Account;
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
}

const RemoveLicensesDialog: FC<Props> = ({ account, open, onAccept, onClose }) => {
    const classes = useStyles();
    const { user, activities, setActivities, strings } = useStore();
    const [complete, setComplete] = useState<string>('');
    const [completeError, setCompleteError] = useState<boolean>(false);
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    if (!user) {
        return null;
    }

    const isCompleteCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Complete, LicenseClass.CompleteSync].includes(account.licenseClass);

    const onChangeComplete = (event: ChangeEvent<HTMLInputElement>) => {
        let validInput = getValidLicenseInput(event.target.value);
        event.target.value = validInput !== undefined ? validInput : '';
        setComplete(event.target.value);
        setCompleteError(false);
    };

    const removeLicenses = async () => {
        let hasCompleteError = false;
        if (complete === '' || isNaN(Number(complete))) {
            hasCompleteError = true;
        }
        setCompleteError(hasCompleteError);
        if (isCompleteCustomer && hasCompleteError) {
            return;
        }
        if (Number(complete) > 0) {
            const response = await ApiService.removeLicenses(account.id, LicenseType.Complete, Number(complete));
            if (!response || (response && response.status < 0)) {
                setCompleteError(true);
                return;
            }
            setComplete('');
        }
        onAccept();
        const resellerId = account.type === AccountType.Customer ? account.resellerId : account.parentId;
        if (resellerId) {
            await Promise.all([
                getAccountData(resellerId),
                getActivities(activities, resellerId, setActivities, ApiService),
            ]);
        }
    };

    const closeDialog = () => {
        onClose();
        setComplete('');
        setCompleteError(false);
    };

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            onAccept={removeLicenses}
            title={isCompleteCustomer ? strings?.removeLicenses : strings?.removeUpgrades}
            acceptText={isCompleteCustomer ? strings?.removeLicenses : strings?.removeUpgrades}
            cancelText={strings?.cancel}
            acceptButtonWidth={171}
            width={384}
            content={
                <div className={classes.addLicensesDialog}>
                    <div className={classes.label}>{strings?.addLicenseInputLabel}</div>
                    <Input
                        autoFocus={true}
                        value={complete}
                        onChange={onChangeComplete}
                        style={{ width: '100%' }}
                        error={completeError}
                    />
                </div>
            }
        />
    );
};

export default RemoveLicensesDialog;
