import { makeStyles, Typography } from '@material-ui/core';
import { FC, useState } from 'react';
import { useStore } from '../../store/StoreProvider';
import { ACCEPTED_COOKIES, ENABLE_ANALYTICS } from '../../utils/constants';
import { Checkbox, Colors, Dialog } from 'common';
import clsx from 'clsx';
import { AuthService } from '../../services/AuthService';

const useStyles = makeStyles({
    cookieMessage: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
    },
    cookieSnackbar: {
        backgroundColor: Colors.white,
    },
    checkbox: {
        marginTop: 8,
        marginLeft: -4,
    },
    check: {
        marginLeft: 8,
    },
});

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const CookiesDialog: FC<Props> = ({ open, setOpen }) => {
    const classes = useStyles();
    const { strings } = useStore();
    const [useAnalytics, setUseAnalytics] = useState<boolean>(false);

    const onClose = () => setOpen(false);
    const onAccept = async () => {
        setOpen(false);
        localStorage.setItem(ACCEPTED_COOKIES, 'true');
        if (useAnalytics) {
            localStorage.setItem(ENABLE_ANALYTICS, 'true');
        }
        await AuthService.login();
    };
    const onChangeCheck = () => setUseAnalytics(!useAnalytics);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onAccept={onAccept}
            cancelText={strings?.cancel}
            acceptText={strings?.acceptAndContinue}
            acceptButtonWidth={196}
            hideClose={true}
            content={
                <>
                    <Typography variant="body2">
                        {strings?.cookiesDialogText}
                        <a href="https://nulia.cloud/cookies" target="_blank" rel="noopener noreferrer">
                            {strings?.cookiesDialogLink}
                        </a>
                    </Typography>
                    <div className={classes.checkbox}>
                        <Checkbox
                            checked={useAnalytics}
                            onClick={onChangeCheck}
                            label={strings?.cookiesDialogLabel}
                            labelClass={clsx(classes.cookieMessage, classes.check)}
                        />
                    </div>
                </>
            }
        />
    );
};

export default CookiesDialog;
