import { FC, MouseEvent, useState } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Colors, MoreHorizontalIcon } from 'common';
import AddLicensesDialog from '../dialogs/AddLicensesDialog';
import RemoveLicensesDialog from '../dialogs/RemoveLicensesDialog';
import { Account, AccountType, LicenseClass } from '../../types/types';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    moreButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        minHeight: 24,
        width: 24,
        minWidth: 24,
    },
    paper: {
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        borderRadius: 4,
        backgroundColor: Colors.white,
        width: 160,
        paddingTop: 4,
        paddingBottom: 8,
        overflowY: 'inherit',
        overflowX: 'inherit',
    },
    arrowContainer: {
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
    },
    arrow: {
        width: 0,
        height: 0,
        position: 'absolute',
        top: 12,
        left: 134,
        borderColor: 'transparent white white transparent',
        transformOrigin: '0 0',
        transform: 'rotate(-135deg)',
        boxShadow: '8px 8px 16px 0 rgba(0,0,0,0.08)',
        border: '8px solid white',
    },
    button: {
        height: 40,
        color: Colors.purple,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
    },
});

interface Props {
    account: Account;
}

const AccountsMoreButton: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [openAdd, setOpenAdd] = useState<boolean>(false);
    const [openRemove, setOpenRemove] = useState<boolean>(false);
    const { user, strings } = useStore();

    if (!user) {
        return null;
    }

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => setMouseOver(false);
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);
    const onClick = (event: MouseEvent) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);

    const toggleAdd = () => {
        onClose();
        setOpenAdd(!openAdd);
    };
    const addLicenses = () => setOpenAdd(false);

    const toggleRemove = () => {
        onClose();
        setOpenRemove(!openRemove);
    };
    const removeLicenses = () => setOpenRemove(false);

    const openMore = Boolean(anchorEl);
    const id = openMore ? 'more' : undefined;
    const isStarterCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);

    return (
        <>
            <div
                className={classes.moreButton}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onClick={onClick}
                aria-describedby={id}
            >
                <MoreHorizontalIcon mouseOver={mouseOver} mouseDown={mouseDown} selected={openMore} />
            </div>
            <Popover
                id={id}
                open={openMore}
                onClose={onClose}
                anchorEl={anchorEl}
                classes={{
                    paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 30,
                    horizontal: 38,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.arrow} />
                <div className={classes.button} onClick={toggleAdd}>
                    {isStarterCustomer ? strings?.addUpgrades : strings?.addLicenses}
                </div>
                <div className={classes.button} onClick={toggleRemove}>
                    {isStarterCustomer ? strings?.removeUpgrades : strings?.removeLicenses}
                </div>
            </Popover>
            <AddLicensesDialog open={openAdd} onClose={toggleAdd} onAccept={addLicenses} account={account} />
            <RemoveLicensesDialog
                open={openRemove}
                onClose={toggleRemove}
                onAccept={removeLicenses}
                account={account}
            />
        </>
    );
};

export default AccountsMoreButton;
