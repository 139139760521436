import { FC, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SwitchToAnnualDialog from '../dialogs/SwitchToAnnualDialog';
import clsx from 'clsx';
import { Customer } from '../../types/types';
import LicenseSyncToggle from './LicenseSyncToggle';
import { useStore } from '../../store/StoreProvider';
import { isStarter } from '../../utils/helper';
import EmailToggle from './EmailToggle';

const useStyles = makeStyles({
    planDetails: {
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        width: 451,
        padding: 16,
        paddingTop: 8,
        display: 'flex',
        flexDirection: 'column',
        marginRight: 16,
    },
    planDetailsAnnual: {
        width: 325,
    },
    planDetailsContent: {
        display: 'flex',
        alignItems: 'flex-end',
        flex: 1,
    },
    container: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    changeToTermButton: {
        height: 20,
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: '#654ea3',
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        padding: 0,
        boxShadow: 'none',
        cursor: 'pointer',
        '&:focus': {
            outline: 0,
        },
        '&:hover': {
            color: '#333333',
        },
        marginRight: 8,
    },
    text: {
        lineHeight: 'normal',
    },
});

interface Props {
    annual: boolean;
    account: Customer;
}

const PlanDetails: FC<Props> = ({ annual, account }) => {
    const classes = useStyles();
    const [openAnnualDialog, setOpenAnnualDialog] = useState<boolean>(false);
    const { strings } = useStore();

    const toggleAnnualDialog = () => setOpenAnnualDialog(!openAnnualDialog);

    // TODO add strings to strings object

    return (
        <>
            <div className={clsx(classes.planDetails, annual && classes.planDetailsAnnual)}>
                <Typography variant="overline">{strings?.planDetails}</Typography>
                <EmailToggle customerId={account.id} />
                <div className={classes.planDetailsContent}>
                    {annual ? (
                        <div style={{ width: '70%' }}>
                            <div className={classes.container} style={{ marginBottom: 2 }}>
                                <Typography
                                    variant="subtitle2"
                                    component="div"
                                    className={classes.text}
                                    style={{ marginRight: 8, height: 18 }}
                                >
                                    Annual
                                </Typography>
                                <Typography variant="body2" component="div" className={classes.text}>
                                    2-year
                                </Typography>
                            </div>
                            <LicenseSyncToggle account={account} />
                            <div className={classes.container}>
                                <Typography
                                    variant="subtitle2"
                                    component="div"
                                    className={classes.text}
                                    style={{ marginRight: 8 }}
                                >
                                    Renews On
                                </Typography>
                                <Typography variant="body2" component="div" className={classes.text}>
                                    January 1, 2020
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        <div style={{ width: '70%' }}>
                            <div className={classes.container}>
                                <Typography variant="subtitle2" component="div" className={classes.text}>
                                    {strings?.mtm}
                                </Typography>
                            </div>
                            {/*TODO: Revert this when backend fixed*/}
                            {!isStarter(account.licenseClass) && <LicenseSyncToggle account={account} />}
                            {/* TODO add this back once when annual is possible */}
                            {/*<div className={classes.container} style={{ height: 24 }}>*/}
                            {/*    <button className={classes.changeToTermButton} onClick={toggleAnnualDialog}>*/}
                            {/*        Change to Annual Term*/}
                            {/*    </button>*/}
                            {/*    <Typography variant="caption" component="div">*/}
                            {/*        Save by switching!*/}
                            {/*    </Typography>*/}
                            {/*</div>*/}
                        </div>
                    )}
                </div>
            </div>
            <SwitchToAnnualDialog open={openAnnualDialog} onClose={toggleAnnualDialog} account={account} />
        </>
    );
};

export default PlanDetails;
