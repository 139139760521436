import { FC } from 'react';
import { Colors } from 'common';
import { SvgIcon } from '@material-ui/core';

type Props = {
    mouseOver?: boolean;
    mouseDown?: boolean;
};

const InfoIcon: FC<Props> = ({ mouseOver, mouseDown }) => (
    <SvgIcon viewBox="0 0 16 16" style={{ height: 16, width: 16 }}>
        <path
            fill={mouseOver && !mouseDown ? Colors.textPrimary : mouseDown ? Colors.purple : Colors.iconDefault}
            d="M7.333 4.667h1.334V6H7.333V4.667zm0 2.666h1.334v4H7.333v-4zm.667-6A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.667 6.67 6.67 0 0 0 14.667 8 6.67 6.67 0 0 0 8 1.333zm0 12A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.333z"
        />
    </SvgIcon>
);

export default InfoIcon;
