import { FC } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import commaNumber from 'comma-number';
import clsx from 'clsx';
import { useStore } from '../../store/StoreProvider';

interface StyleProps {
    usageBarWidth: number;
}

const useStyles = makeStyles({
    usageBarComplete: {
        width: (props: StyleProps) => props.usageBarWidth,
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        height: 48,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 2,
    },
    darkBar: {
        backgroundColor: '#b2a6d1',
        borderColor: 'rgba(101, 78, 163, 0.08)',
    },
    usageBarFirst: {
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        width: 0,
        height: 48,
        borderRadius: 2,
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0,
    },
    usageBarMiddle: {
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        width: 0,
        height: 48,
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 0,
        borderLeftWidth: 0,
    },
    usageBarLast: {
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        width: 0,
        height: 48,
        borderRadius: 2,
        borderStyle: 'solid',
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftWidth: 0,
    },

    barLabelLeft: {
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        textAlign: 'left',
        display: 'flex',
    },
    barLabelRight: {
        maxWidth: (props: StyleProps) => props.usageBarWidth,
        textAlign: 'right',
    },
    barLabelLeft2nd: {
        textAlign: 'left',
        display: 'flex',
        flex: 1,
    },
    barLabelRight2nd: {
        textAlign: 'right',
    },
    count: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.8)',
        marginTop: 8,
    },
    label: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
    },
});

interface Props {
    firstCount: number;
    secondCount: number;
    o365Count: number;
    isConnected: boolean;
    isComplete: boolean;
    usageBarWidth: number;
}

const UsageBar: FC<Props> = ({ firstCount, secondCount, o365Count, isConnected, isComplete, usageBarWidth }) => {
    const classes = useStyles({ usageBarWidth });
    const { strings } = useStore();

    let firstWidth = (firstCount * usageBarWidth) / o365Count;
    if (isNaN(firstWidth)) {
        firstWidth = usageBarWidth;
    }
    const secondWidth = (secondCount * usageBarWidth) / o365Count;
    const thirdCount = o365Count - firstCount - secondCount;
    const thirdWidth = (thirdCount * usageBarWidth) / o365Count;

    const firstLabel = (isComplete ? strings?.nuliaUsers : strings?.completeUsers) ?? '';
    const secondLabel = (isComplete ? strings?.usersWithoutNulia : strings?.starterUsers) ?? '';
    const thirdLabel = strings?.usersWithoutNulia;
    const secondCountBackgroundColor = isComplete ? '#f5f5f5' : '#e8e8f5';
    const secondCountBorderColor = isComplete ? 'rgba(0, 0, 0, 0.08)' : 'rgba(101, 78, 163, 0.16)';
    const thirdCountBackgroundColor = '#f5f5f5';
    const thirdCountBorderColor = 'rgba(0, 0, 0, 0.08)';

    return (
        <>
            {secondCount === 0 && firstCount > 0 && thirdCount <= 0 ? (
                <div>
                    <div className={classes.barLabelLeft}>
                        <div className={clsx(classes.usageBarComplete, classes.darkBar)} />
                    </div>
                    <div className={classes.barLabelLeft}>
                        <div>
                            <div className={classes.count}>{commaNumber(firstCount)}</div>
                            <div className={classes.label}>{firstLabel}</div>
                        </div>
                    </div>
                </div>
            ) : secondCount === 0 && firstCount === 0 && thirdCount > 0 ? (
                <div>
                    <div className={classes.barLabelLeft}>
                        <div
                            className={clsx(classes.usageBarComplete)}
                            style={{
                                backgroundColor: thirdCountBackgroundColor,
                                borderColor: thirdCountBorderColor,
                            }}
                        />
                    </div>
                    <div className={classes.barLabelLeft}>
                        <div>
                            <div className={classes.count}>{commaNumber(thirdCount)}</div>
                            <div className={classes.label}>{thirdLabel}</div>
                        </div>
                    </div>
                </div>
            ) : secondCount === 0 && firstCount === 0 && thirdCount === 0 ? (
                <div>
                    <div className={classes.barLabelLeft}>
                        <div
                            className={clsx(classes.usageBarComplete)}
                            style={{ backgroundColor: secondCountBackgroundColor, borderColor: secondCountBorderColor }}
                        />
                    </div>
                    <div className={classes.barLabelLeft}>
                        <div>
                            <div className={classes.count}>{commaNumber(firstCount)}</div>
                            <div className={classes.label}>{firstLabel}</div>
                        </div>
                    </div>
                </div>
            ) : secondCount === 0 && thirdWidth > 0 && firstCount > 0 ? (
                <div>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.barLabelLeft} style={{ width: firstWidth }}>
                            <div
                                className={clsx(classes.usageBarFirst, classes.darkBar)}
                                style={{
                                    width: firstWidth,
                                }}
                            />
                        </div>
                        <div className={classes.barLabelRight} style={{ width: thirdWidth }}>
                            <div
                                className={classes.usageBarLast}
                                style={{
                                    backgroundColor: thirdCountBackgroundColor,
                                    borderColor: thirdCountBorderColor,
                                    width: thirdWidth,
                                }}
                            />
                        </div>
                    </div>
                    <Grid container={true} direction="row">
                        <Grid item={true} xs className={classes.barLabelLeft2nd}>
                            <div>
                                <div className={classes.count}>{commaNumber(firstCount)}</div>
                                <div className={classes.label}>{firstLabel}</div>
                            </div>
                        </Grid>
                        <Grid item={true} className={classes.barLabelRight} style={{ width: thirdWidth }}>
                            <div className={classes.count}>{commaNumber(thirdCount)}</div>
                            <div className={classes.label}>{thirdLabel}</div>
                        </Grid>
                    </Grid>
                </div>
            ) : (firstCount === 0 && thirdWidth <= 0) || !isConnected ? (
                <div>
                    <div className={classes.barLabelLeft}>
                        <div
                            className={classes.usageBarComplete}
                            style={{ backgroundColor: secondCountBackgroundColor, borderColor: secondCountBorderColor }}
                        />
                    </div>
                    <div className={classes.barLabelLeft}>
                        <div>
                            <div className={classes.count}>{!isConnected ? 'NA' : commaNumber(secondCount)}</div>
                            <div className={classes.label}>{secondLabel}</div>
                        </div>
                    </div>
                </div>
            ) : firstCount === 0 && thirdWidth > 0 ? (
                <div>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.barLabelRight} style={{ width: secondWidth }}>
                            <div
                                className={classes.usageBarMiddle}
                                style={{
                                    backgroundColor: secondCountBackgroundColor,
                                    borderColor: secondCountBorderColor,
                                    width: secondWidth,
                                }}
                            />
                        </div>
                        <div className={classes.barLabelRight2nd} style={{ width: thirdWidth }}>
                            <div
                                className={classes.usageBarLast}
                                style={{
                                    backgroundColor: thirdCountBackgroundColor,
                                    borderColor: thirdCountBorderColor,
                                    width: thirdWidth,
                                }}
                            />
                        </div>
                    </div>
                    <Grid container={true} direction="row">
                        <Grid item={true} xs className={classes.barLabelRight2nd}>
                            <div className={classes.count}>{commaNumber(secondCount)}</div>
                            <div className={classes.label}>{secondLabel}</div>
                        </Grid>
                        <Grid item={true} className={classes.barLabelRight} style={{ width: thirdWidth }}>
                            <div className={classes.count}>{commaNumber(thirdCount)}</div>
                            <div className={classes.label}>{thirdLabel}</div>
                        </Grid>
                    </Grid>
                </div>
            ) : (
                <div>
                    {thirdWidth <= 0 && (
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.barLabelLeft} style={{ width: firstWidth }}>
                                    <div
                                        className={clsx(classes.usageBarFirst, classes.darkBar)}
                                        style={{
                                            width: firstWidth,
                                        }}
                                    />
                                </div>
                                <div className={classes.barLabelRight} style={{ width: secondWidth }}>
                                    <div
                                        className={thirdWidth > 0 ? classes.usageBarMiddle : classes.usageBarLast}
                                        style={{
                                            backgroundColor: secondCountBackgroundColor,
                                            borderColor: secondCountBorderColor,
                                            width: secondWidth,
                                        }}
                                    />
                                </div>
                            </div>
                            <Grid container={true} direction="row">
                                <Grid item={true} xs className={classes.barLabelLeft2nd}>
                                    <div>
                                        <div className={classes.count}>{commaNumber(firstCount)}</div>
                                        <div className={classes.label}>{firstLabel}</div>
                                    </div>
                                </Grid>
                                <Grid item={true} xs className={classes.barLabelRight2nd}>
                                    <div className={classes.count}>{commaNumber(secondCount)}</div>
                                    <div className={classes.label}>{secondLabel}</div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                    {thirdWidth > 0 && (
                        <div>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.barLabelLeft} style={{ width: firstWidth }}>
                                    <div
                                        className={clsx(classes.usageBarFirst, classes.darkBar)}
                                        style={{
                                            width: firstWidth,
                                        }}
                                    />
                                </div>
                                <div className={classes.barLabelRight2nd} style={{ width: secondWidth }}>
                                    <div
                                        className={thirdWidth > 0 ? classes.usageBarMiddle : classes.usageBarLast}
                                        style={{
                                            backgroundColor: secondCountBackgroundColor,
                                            borderColor: secondCountBorderColor,
                                            width: secondWidth,
                                        }}
                                    />
                                </div>
                                <div className={classes.barLabelRight2nd} style={{ width: thirdWidth }}>
                                    <div
                                        className={classes.usageBarLast}
                                        style={{
                                            backgroundColor: thirdCountBackgroundColor,
                                            borderColor: thirdCountBorderColor,
                                            width: thirdWidth,
                                        }}
                                    />
                                </div>
                            </div>
                            <Grid container={true} direction="row" justify="space-between">
                                <Grid item={true} className={classes.barLabelLeft2nd} style={{ width: firstWidth }}>
                                    <div>
                                        <div className={classes.count}>{commaNumber(firstCount)}</div>
                                        <div className={classes.label}>{firstLabel}</div>
                                    </div>
                                </Grid>
                                <Grid item={true} className={classes.barLabelRight2nd} style={{ width: secondWidth }}>
                                    <div className={classes.count}>{commaNumber(secondCount)}</div>
                                    <div className={classes.label}>{secondLabel}</div>
                                </Grid>

                                <Grid item={true} className={classes.barLabelRight} style={{ width: thirdWidth }}>
                                    <div className={classes.count}>{commaNumber(thirdCount)}</div>
                                    <div className={classes.label}>{thirdLabel}</div>
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default UsageBar;
