import { FC, useEffect, useState } from 'react';
import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import { AccessLevel, Customer, LicenseClass } from '../../types/types';
import InfoButton from '../buttons/InfoButton';
import { useApiService } from '../../services/ApiServiceProvider';
import { getActivities } from '../../utils/helper';
import { useStore } from '../../store/StoreProvider';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    licenseSyncToggle: ({ width }: { width?: number }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: width ? 'space-between' : undefined,
        width: width,
        minWidth: width,
        maxWidth: width,
    }),
    helpContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 8,
    },
    toggle: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
    },
});

interface Props {
    account: Customer;
    onChange?: (licenseSync: boolean) => void;
    width?: number;
}

const LicenseSyncToggle: FC<Props> = ({ account, onChange, width }) => {
    const classes = useStyles({ width });
    const [licenseSync, setLicenseSync] = useState<boolean>(false);
    const ApiService = useApiService();
    const { activities, setActivities, user, strings } = useStore();
    const getAccountData = useGetAccountData();

    useEffect(() => {
        setLicenseSync([LicenseClass.StarterCompleteSync, LicenseClass.CompleteSync].includes(account.licenseClass));
    }, [account]);

    const onChangeLicenseSync = async () => {
        if (onChange) {
            onChange(!licenseSync);
        }
        await ApiService.setLicenseSync(account.id, !licenseSync);
        setLicenseSync(!licenseSync);
        await Promise.all([
            getAccountData(account.resellerId),
            getActivities(activities, account.resellerId, setActivities, ApiService),
        ]);
    };

    return (
        <Box className={classes.licenseSyncToggle} justifyContent="space-between">
            <div className={classes.helpContainer}>
                <Typography variant={width ? 'body1' : 'subtitle2'}>License Sync</Typography>
                <InfoButton title={strings?.licenseSyncTooltip ?? ''} style={{ marginLeft: 8 }} />
            </div>
            <div className={classes.toggle}>
                <Typography variant="body2" component="div">
                    {!licenseSync ? 'Off' : 'On'}
                </Typography>
                <Switch
                    checked={licenseSync}
                    onChange={onChangeLicenseSync}
                    disabled={user?.accessLevel === AccessLevel.PlatformAdmin}
                />
            </div>
        </Box>
    );
};

export default LicenseSyncToggle;
