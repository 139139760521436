import { Account, OrderByAccounts } from '../types/types';
import { Order } from 'common-v2';
import { getStatusOrderBy } from './helper';

export const sort = (array: Account[], cmp: (a: Account, b: Account) => number): Account[] => {
    array.sort((a, b) => {
        return cmp(a, b);
    });
    return array;
};

export const getSorting = (order: Order, orderBy: OrderByAccounts): ((a: Account, b: Account) => number) => {
    return order === 'asc'
        ? (a: Account, b: Account) => desc(a, b, orderBy)
        : (a: Account, b: Account) => -desc(a, b, orderBy);
};

const desc = (a: Account, b: Account, orderBy: OrderByAccounts): number => {
    let bOfOrderBy;
    let aOfOrderBy;
    switch (orderBy) {
        case 'status': {
            bOfOrderBy = getStatusOrderBy(b);
            aOfOrderBy = getStatusOrderBy(a);
            break;
        }
        case 'name': {
            bOfOrderBy = b.name.toLowerCase();
            aOfOrderBy = a.name.toLowerCase();
            break;
        }
        case 'o365Licenses': {
            bOfOrderBy = b.o365Licenses + 100;
            aOfOrderBy = a.o365Licenses + 100;
            break;
        }
        case 'starter':
        case 'complete': {
            bOfOrderBy = (b.completeLicensesUsed ? b.completeLicensesUsed : 0) + 100;
            aOfOrderBy = (a.completeLicensesUsed ? a.completeLicensesUsed : 0) + 100;
            break;
        }
        default:
            break;
    }

    if (bOfOrderBy && aOfOrderBy) {
        if (aOfOrderBy < bOfOrderBy) {
            return -1;
        }
        if (aOfOrderBy > bOfOrderBy) {
            return 1;
        }
    }
    return 0;
};
