import { ChangeEvent, FC, useState } from 'react';
import { Box, Grid, makeStyles, Switch, Typography } from '@material-ui/core';
import { Dialog, Input } from 'common';
import { useStore } from '../../store/StoreProvider';
import InfoButton from '../buttons/InfoButton';
import { AccountType, Customer, LicenseClass } from '../../types/types';

const useStyles = makeStyles({
    addMonthCreditsDialog: {},
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bold: {
        fontWeight: 'bold',
    },
    total: {
        marginTop: 24,
    },
    helpContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
    account: Customer;
}

const AddMonthCreditsDialog: FC<Props> = ({ open, onClose, account }) => {
    const classes = useStyles();
    const [acting, setActing] = useState<boolean>(false);
    const [licenseSync, setLicenseSync] = useState<boolean>(false);
    const [users, setUsers] = useState<string>('');
    const [usersError, setUsersError] = useState<boolean>(false);
    const [months, setMonths] = useState<string>('');
    const { user } = useStore();

    // TODO get this from api
    const monthsLeftInTerm = 12;

    if (!user) {
        return null;
    }

    const onAccept = () => {
        setActing(true);
        let hasUserError = false;
        if (users === '') {
            hasUserError = true;
        }
        if (!hasUserError) {
            // TODO make api request
            handleClose();
        }
        setUsersError(hasUserError);
        setActing(false);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        setLicenseSync(false);
        setUsers('');
        setMonths('');
    };

    const onChangeLicenseSync = (event: ChangeEvent<HTMLInputElement>) => {
        setLicenseSync(!licenseSync);
        if (!licenseSync) {
            setUsers(String(account.o365Licenses - account.completeLicensesUsed));
            setMonths(String(monthsLeftInTerm));
        } else {
            setUsers('');
            setMonths('');
        }
    };
    const onChangeUsers = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            setUsersError(true);
            return;
        }
        setUsers(event.target.value);
    };

    const isStarterCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);

    return (
        <Dialog
            open={open}
            onAccept={onAccept}
            acceptText="ADD MONTH-CREDITS"
            acceptDisabled={acting}
            acceptButtonWidth={200}
            onClose={handleClose}
            cancelText="CANCEL"
            title="Add Month-Credits"
            width={384}
            content={
                <div className={classes.addMonthCreditsDialog}>
                    <Typography variant="body2">
                        {'A Month-Credit is a credit on the customer account equal to 1-month of user ' +
                            'access to Nulia Complete. If a user has complete access for 1-year they will ' +
                            'use 12 Month-Credits.'}
                    </Typography>
                    {isStarterCustomer && (
                        <Box marginTop={1} className={classes.container}>
                            <div className={classes.helpContainer}>
                                <Typography variant="body1">License Sync</Typography>
                                <InfoButton title="License Sync is ..." style={{ marginLeft: 8 }} />
                            </div>
                            <Typography variant="body2" component="div">
                                <Grid container={true} alignItems="center" spacing={1}>
                                    <Grid item={true}>{!licenseSync ? 'Off' : 'On'}</Grid>
                                    <Grid item={true}>
                                        <Switch value={licenseSync} onChange={onChangeLicenseSync} />
                                    </Grid>
                                </Grid>
                            </Typography>
                        </Box>
                    )}
                    <Box marginTop={1}>
                        <div className={classes.container}>
                            <Typography
                                variant="body1"
                                style={licenseSync ? { color: 'rgba(0, 0, 0, 0.64)' } : undefined}
                            >
                                Number of Users to Add
                            </Typography>
                            {!licenseSync ? (
                                <Input
                                    autoFocus={true}
                                    onChange={onChangeUsers}
                                    value={users}
                                    error={usersError}
                                    style={{ width: 88 }}
                                    inputStyle={{ textAlign: 'right' }}
                                />
                            ) : (
                                <Typography variant="body2">
                                    {account.o365Licenses - account.completeLicensesUsed}
                                </Typography>
                            )}
                        </div>
                        {licenseSync && (
                            <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                                Calculated based on tenant users without Complete Licenses
                            </Typography>
                        )}
                        <Box marginBottom={2} style={{ marginTop: 4 }}>
                            <div className={classes.container}>
                                <Typography variant="body1" style={{ color: 'rgba(0, 0, 0, 0.64)' }}>
                                    Months of use per user
                                </Typography>
                                <Typography variant="body2" style={{ marginLeft: 4 }}>
                                    {monthsLeftInTerm}
                                </Typography>
                            </div>
                            <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
                                Time left in your current 12-month contract
                            </Typography>
                        </Box>
                        <div className={classes.container}>
                            <Typography variant="overline">TOTAL MONTH-CREDITS</Typography>
                            <Typography variant="body1" className={classes.bold}>
                                {users && months ? Number(users) * Number(months) : 0}
                            </Typography>
                        </div>
                    </Box>
                </div>
            }
        />
    );
};

export default AddMonthCreditsDialog;
