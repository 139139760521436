import { ChangeEvent, FC, useState } from 'react';
import { Box, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Dialog, Input } from 'common';
import { useStore } from '../../store/StoreProvider';
import InfoButton from '../buttons/InfoButton';
import { Customer } from '../../types/types';
import LicenseSyncToggle from '../accounts/LicenseSyncToggle';

const useStyles = makeStyles({
    switchToAnnualDialog: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    details: {
        width: 282,
        minWidth: 282,
        maxWidth: 282,
        height: 376,
        borderRadius: 4,
        backgroundColor: 'rgba(101, 78, 163, 0.08)',
        padding: 24,
        marginLeft: 40,
    },
    form: {},
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 344,
        minWidth: 344,
        maxWidth: 344,
    },
    bold: {
        fontWeight: 'bold',
    },
    total: {
        marginTop: 24,
    },
    helpContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
    account: Customer;
}

type TermLength = '1-year' | '2-year' | '3-year';

const SwitchToAnnualDialog: FC<Props> = ({ open, onClose, account }) => {
    const classes = useStyles();
    const [licenseSync, setLicenseSync] = useState<boolean>(false);
    const [acting, setActing] = useState<boolean>(false);
    const [termLength, setTermLength] = useState<TermLength>('1-year');
    const [users, setUsers] = useState<string>('');
    const [usersError, setUsersError] = useState<boolean>(false);
    const [months, setMonths] = useState<string>('');
    const [monthsError, setMonthsError] = useState<boolean>(false);
    const { user } = useStore();

    // TODO get this from api
    const monthsLeftInTerm = 12;

    if (!user) {
        return null;
    }

    const onAccept = () => {
        setActing(true);
        let hasUserError = false;
        let hasMonthsError = false;
        if (users === '') {
            hasUserError = true;
        }
        if (months === '') {
            hasMonthsError = true;
        }
        if (!hasUserError && !hasMonthsError) {
            // TODO make api request
            handleClose();
        }
        setUsersError(hasUserError);
        setMonthsError(hasMonthsError);
        setActing(false);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        setTermLength('1-year');
        setUsers('');
        setMonths('');
    };

    const onChangeTermLength = (event: ChangeEvent<HTMLInputElement>) =>
        setTermLength(event.target.value as TermLength);
    const onChangeLicenseSync = (newLicenseSync: boolean) => {
        setLicenseSync(newLicenseSync);
        if (!newLicenseSync) {
            setUsers(String(account.o365Licenses - account.completeLicensesUsed));
            setMonths(String(monthsLeftInTerm));
        } else {
            setUsers('');
            setMonths('');
        }
    };
    const onChangeUsers = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            setUsersError(true);
            return;
        }
        setUsers(event.target.value);
    };
    const onChangeMonths = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNaN(Number(event.target.value))) {
            setMonthsError(true);
            return;
        }
        setMonths(event.target.value);
    };

    return (
        <Dialog
            open={open}
            onAccept={onAccept}
            acceptText="SWITCH TO ANNUAL TERM"
            acceptDisabled={acting}
            acceptButtonWidth={205}
            onClose={handleClose}
            cancelText="CANCEL"
            title="Switch Account to Annual Term"
            width={714}
            content={
                <div className={classes.switchToAnnualDialog}>
                    <div className={classes.form}>
                        <FormControl component="fieldset">
                            <Typography variant="h6" component="legend">
                                1. Set a Term Length
                            </Typography>
                            <RadioGroup value={termLength} onChange={onChangeTermLength}>
                                <FormControlLabel control={<Radio />} label="1-year" value="1-year" />
                                <FormControlLabel control={<Radio />} label="2-year" value="2-year" />
                                <FormControlLabel control={<Radio />} label="3-year" value="3-year" />
                            </RadioGroup>
                        </FormControl>
                        <LicenseSyncToggle account={account} onChange={onChangeLicenseSync} width={344} />
                        <Box marginTop={2}>
                            <FormControl component="fieldset">
                                <Box marginBottom={1}>
                                    <div className={classes.helpContainer}>
                                        <Typography variant="h6" component="legend">
                                            2. Add Month-Credits
                                        </Typography>
                                        <InfoButton title="Month-Credits are ..." style={{ marginLeft: 8 }} />
                                    </div>
                                </Box>
                                <Box marginBottom={2}>
                                    <div className={classes.container}>
                                        <Typography
                                            variant="body1"
                                            style={licenseSync ? { color: 'rgba(0, 0, 0, 0.64)' } : undefined}
                                        >
                                            Number of Users to Add
                                        </Typography>
                                        {!licenseSync ? (
                                            <Input
                                                autoFocus={true}
                                                onChange={onChangeUsers}
                                                value={users}
                                                error={usersError}
                                                style={{ width: 88 }}
                                                inputStyle={{ textAlign: 'right' }}
                                            />
                                        ) : (
                                            <Typography variant="body2">
                                                {account.o365Licenses - account.completeLicensesUsed}
                                            </Typography>
                                        )}
                                    </div>
                                    {licenseSync && (
                                        <Typography variant="caption">
                                            Calculated based on tenant users without Complete Licenses
                                        </Typography>
                                    )}
                                </Box>
                                <Box marginBottom={2}>
                                    <div className={classes.container}>
                                        <Typography
                                            variant="body1"
                                            style={licenseSync ? { color: 'rgba(0, 0, 0, 0.64)' } : undefined}
                                        >
                                            Months of use per user
                                        </Typography>
                                        {!licenseSync ? (
                                            <Input
                                                onChange={onChangeMonths}
                                                value={months}
                                                error={monthsError}
                                                style={{ width: 88 }}
                                                inputStyle={{ textAlign: 'right' }}
                                            />
                                        ) : (
                                            <Typography variant="body2">{monthsLeftInTerm}</Typography>
                                        )}
                                    </div>
                                    {licenseSync && (
                                        <Typography variant="caption">
                                            Time left in your current 12-month contract
                                        </Typography>
                                    )}
                                </Box>
                                <div className={classes.container}>
                                    <Typography variant="overline">TOTAL MONTH-CREDITS</Typography>
                                    <Typography variant="body1" className={classes.bold}>
                                        {users && months ? Number(users) * Number(months) : 0}
                                    </Typography>
                                </div>
                            </FormControl>
                        </Box>
                    </div>
                    <div className={classes.details}>
                        <Box marginBottom={1}>
                            <Typography variant="subtitle1">Important Details</Typography>
                        </Box>
                        <Box marginBottom={1}>
                            <Typography variant="body2">
                                The partner will be invoiced for all Month-Credits added to the annual term immediately.
                            </Typography>
                        </Box>
                        <Box marginBottom={1}>
                            <Typography variant="body2">
                                Partner will receive a discount for all Month-credits purchased on an annual term.
                            </Typography>
                        </Box>
                        <Box marginBottom={1}>
                            <Typography variant="body2">
                                Partners will pre-pay for one-year at a time, and be renewed automatically annually for
                                the length of the term.
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            You may always add Month-Credits to a customer account at a later time.
                        </Typography>
                    </div>
                </div>
            }
        />
    );
};

export default SwitchToAnnualDialog;
