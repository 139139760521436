import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Account, AccountType, LicenseClass } from '../../types/types';
import RemoveLicensesButton from '../buttons/RemoveLicensesButton';
import AddLicensesButton from '../buttons/AddLicensesButton';
import { useStore } from '../../store/StoreProvider';
import clsx from 'clsx';
import LicenseUsage from './LicenseUsage';
import { Colors, Tooltip } from 'common';
import PlanDetails from './PlanDetails';
import MonthCredits from './MonthCredits';
import AnnualUsage from './annualUsage/AnnualUsage';
import commaNumber from 'comma-number';

const useStyles = makeStyles({
    licenses: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    resellerSection: {
        width: 1088,
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 24,
    },
    customerTop: {
        display: 'flex',
        alignItems: 'center',
    },
    resellerTop: {
        display: 'flex',
        alignItems: 'center',
    },
    licenseActions: {
        padding: 16,
        borderRadius: 4,
        backgroundColor: '#fafafa',
    },
    countBox: {
        padding: 24,
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
    },
    count: {
        fontFamily: 'Ubuntu',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: -0.5,
        textAlign: 'center',
        color: 'rgba(0,0,0,0.88)',
    },
    countLabel: {
        marginTop: 16,
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        textAlign: 'center',
        color: 'rgba(0,0,0,0.64)',
    },
    countSpace: {
        marginRight: 24,
    },
});

interface Props {
    account: Account;
    isCompleteAction: (section: string) => boolean | undefined;
    getData: () => void;
}

const Licenses: FC<Props> = ({ account, isCompleteAction, getData }) => {
    const classes = useStyles();
    const { user, strings } = useStore();

    if (!user) {
        return null;
    }

    const isCustomer = account.type === AccountType.Customer;
    const annual = false;
    const isStarterCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);

    // @ts-ignore
    return (
        <div className={clsx(classes.licenses, !isCustomer && classes.resellerSection)}>
            <div className={classes.header}>
                <Typography variant="h6" className={classes.title}>
                    {account.type === AccountType.Customer && (
                        <>
                            {!isCompleteAction('addedCompleteLicenses') ? (
                                <div className={classes.completeState}>
                                    <Tooltip title={strings?.notComplete ?? ''}>
                                        <div className={classes.notComplete} />
                                    </Tooltip>
                                </div>
                            ) : (
                                <div className={classes.completeState}>
                                    <Tooltip title={strings?.complete ?? ''}>
                                        <div className={classes.complete} />
                                    </Tooltip>
                                </div>
                            )}
                        </>
                    )}
                    {strings?.licenses}
                </Typography>
            </div>
            <div className={classes.body}>
                <div className={classes.top}>
                    {account.type === AccountType.Customer ? (
                        <div className={classes.customerTop}>
                            <PlanDetails annual={annual} account={account} />
                            {annual ? (
                                <MonthCredits account={account} />
                            ) : (
                                <>
                                    {account.licenseClass === LicenseClass.Complete ||
                                    account.licenseClass === LicenseClass.CompleteSync ? (
                                        <div className={classes.licenseActions}>
                                            <AddLicensesButton
                                                account={account}
                                                title={strings?.addLicenses ?? ''}
                                                getData={getData}
                                                style={{ width: 168, marginBottom: 16 }}
                                                disabled={account.licenseClass === LicenseClass.CompleteSync}
                                            />
                                            <RemoveLicensesButton
                                                account={account}
                                                title={strings?.removeLicenses ?? ''}
                                                getData={getData}
                                                style={{ width: 168 }}
                                                disabled={account.licenseClass === LicenseClass.CompleteSync}
                                            />
                                        </div>
                                    ) : (
                                        <div className={classes.licenseActions}>
                                            <AddLicensesButton
                                                account={account}
                                                title={strings?.addUpgrades ?? ''}
                                                getData={getData}
                                                style={{ width: 168, marginBottom: 16 }}
                                                disabled={account.licenseClass === LicenseClass.StarterCompleteSync}
                                            />
                                            <RemoveLicensesButton
                                                account={account}
                                                title={strings?.removeUpgrades ?? ''}
                                                getData={getData}
                                                style={{ width: 168 }}
                                                disabled={account.licenseClass === LicenseClass.StarterCompleteSync}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={classes.resellerTop}>
                            <div className={clsx(classes.countBox, classes.countSpace)}>
                                <div className={classes.count}>{account.o365Licenses}</div>
                                <div className={classes.countLabel}>{strings?.o365Licenses}</div>
                            </div>
                            <div
                                className={clsx(classes.countBox, (isStarterCustomer || annual) && classes.countSpace)}
                            >
                                <div className={classes.count}>{commaNumber(account.completeLicensesUsed)}</div>
                                <div className={classes.countLabel}>{strings?.completeLicenses}</div>
                            </div>
                            {isStarterCustomer && (
                                <div className={classes.countBox}>
                                    {/* @ts-ignore */}
                                    <div className={classes.count}>{commaNumber(account.starterLicensesUsed)}</div>
                                    <div className={classes.countLabel}>{strings?.starterLicenses}</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {account.type === AccountType.Customer && (
                    <>
                        <LicenseUsage account={account} annual={annual} />
                        {annual && <AnnualUsage account={account} />}
                    </>
                )}
            </div>
        </div>
    );
};

export default Licenses;
