import { CSSProperties, FC, useState } from 'react';
import { AccessLevel, Account } from '../../types/types';
import { makeStyles } from '@material-ui/core';
import RemoveLicensesDialog from '../dialogs/RemoveLicensesDialog';
import { SecondaryButton } from 'common';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    removeLicensesButton: {},
});

interface Props {
    account: Account;
    title: string;
    getData: () => void;
    style?: CSSProperties;
    disabled?: boolean;
}

const RemoveLicensesButton: FC<Props> = ({ account, title, getData, style, disabled }) => {
    const classes = useStyles();
    const { user } = useStore();
    const [open, setOpen] = useState<boolean>(false);

    const toggleDialog = () => {
        setOpen(!open);
        getData();
    };

    return (
        <div className={classes.removeLicensesButton}>
            <SecondaryButton
                onClick={toggleDialog}
                style={!style ? { width: 160 } : style}
                disabled={user?.accessLevel === AccessLevel.PlatformAdmin || disabled}
            >
                {title}
            </SecondaryButton>
            <RemoveLicensesDialog open={open} onClose={toggleDialog} onAccept={toggleDialog} account={account} />
        </div>
    );
};

export default RemoveLicensesButton;
