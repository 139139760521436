import { ChangeEvent, FC, useState } from 'react';
import { Dialog, Input } from 'common';
import { Account, Customer } from '../../types/types';
import { makeStyles } from '@material-ui/core';
import { useStore } from '../../store/StoreProvider';
import isEmail from 'validator/lib/isEmail';
import { useApiService } from '../../services/ApiServiceProvider';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    sendInstructionsDialog: {},
    text: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 1.43,
        marginBottom: 32,
    },
    label: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: 2,
    },
});

interface Props {
    account: Account;
    open: boolean;
    onClose: () => void;
}

const SendConnectInstructionsDialog: FC<Props> = ({ account, open, onClose }) => {
    const classes = useStyles();
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const { user, strings } = useStore();
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    if (!user) {
        return null;
    }

    const sendInstructions = async () => {
        setEmailError(false);
        if (email === '' || !isEmail(email)) {
            setEmailError(true);
        }
        onClose();
        await ApiService.sendConnectEmail(email, account.id);
        await getAccountData((account as Customer)?.resellerId ?? '');
    };
    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onAccept={sendInstructions}
            title={strings?.sendInstructionsTitle}
            acceptText={strings?.sendInstructionsTitle}
            cancelText={strings?.cancel}
            acceptButtonWidth={168}
            width={384}
            content={
                <div className={classes.sendInstructionsDialog}>
                    <div className={classes.text}>{strings?.sendInstructionsEmail}</div>
                    <div className={classes.label}>{strings?.adminEmail}</div>
                    <Input value={email} onChange={onChangeEmail} style={{ width: '100%' }} error={emailError} />
                </div>
            }
        />
    );
};

export default SendConnectInstructionsDialog;
