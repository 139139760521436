import { makeStyles } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles({
    page: {},
});

const Page: FC = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.page}>{children}</div>;
};

export default Page;
