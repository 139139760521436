import { ChangeEvent, FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccountType, ResellerSimple } from '../../types/types';
import { Dialog, logError, Input } from 'common';
import AccountSearchInput from '../inputs/AccountSearchInput';
import { getActivities, getAllAccounts } from '../../utils/helper';
import { ResponseCode } from '../../types/responseTypes';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    addCustomer: {},
});

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
    initialParent?: ResellerSimple;
    parentResellers: ResellerSimple[];
}

const AddResellerDialog: FC<Props> = ({ open, onClose, onAccept, initialParent, parentResellers }) => {
    const classes = useStyles();
    const [parent, setParent] = useState<ResellerSimple | undefined>(undefined);
    const [resellerName, setResellerName] = useState<string>('');
    const [parentError, setParentError] = useState<boolean>(false);
    const [resellerNameError, setResellerNameError] = useState<boolean>(false);
    const { user, setShowWarning, activities, setActivities, setAllAccounts, strings } = useStore();
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    useEffect(() => {
        setParent(initialParent);
    }, [initialParent]);

    const onChangeParent = (value: string) => {
        const reseller = parentResellers.find((r) => r.name === value);
        setParent(reseller);
        setResellerNameError(false);
    };
    const onChangeCustomerName = (event: ChangeEvent<HTMLInputElement>) => {
        setResellerName(event.target.value);
        setParentError(false);
    };
    const handleAccept = async () => {
        let hasParentError = false;
        let hasResellerNameError = false;
        const parentNames = parentResellers.map((r) => r.name);
        if (parent === undefined || !parentNames.includes(parent.name)) {
            hasParentError = true;
        }
        if (resellerName === '') {
            hasResellerNameError = true;
        }
        setParentError(hasParentError);
        setResellerNameError(hasResellerNameError);
        if (user && parent && !hasParentError && !hasResellerNameError) {
            if (!parent.id) {
                logError(Error(`Tried to add a reseller with no parent id for oid ${user.oid}`));
                setShowWarning(true);
                return;
            }
            const response = await ApiService.addReseller(parent.id, resellerName);
            if (!response || (response && response.status < 0 && response.code !== ResponseCode.ResellerExists)) {
                setShowWarning(true);
                return;
            } else if (response && response.status < 0 && response.code === ResponseCode.ResellerExists) {
                setResellerNameError(true);
                return;
            }
            onAccept();
            await Promise.all([
                getAccountData(parent.id),
                getActivities(activities, parent.id, setActivities, ApiService),
                getAllAccounts(setAllAccounts, ApiService),
            ]);
            resetForm();
            return;
        }
    };
    const handleClose = () => {
        resetForm();
        onClose();
    };
    const resetForm = () => {
        setParent(initialParent ? initialParent : undefined);
        setResellerName('');
        setParentError(false);
        setResellerNameError(false);
    };

    const resellerData = parentResellers.map((r) => ({ name: r.name, type: AccountType.Reseller }));

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={strings?.create}
            onClose={handleClose}
            cancelText={strings?.cancel}
            title={strings?.addReseller}
            content={
                <div className={classes.addCustomer}>
                    <AccountSearchInput
                        onChange={onChangeParent}
                        value={parent ? parent.name : ''}
                        placeholder={strings?.parentReseller}
                        accounts={resellerData}
                        style={{ width: 336, marginBottom: 8 }}
                        error={parentError}
                    />
                    <Input
                        autoFocus={true}
                        onChange={onChangeCustomerName}
                        value={resellerName}
                        placeholder={strings?.resellerName}
                        style={{ width: 336 }}
                        error={resellerNameError}
                    />
                </div>
            }
        />
    );
};

export default AddResellerDialog;
