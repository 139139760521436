import { FC } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { useStore } from '../../store/StoreProvider';
import { ResellerType } from '../../types/types';
import UsageBar from './UsageBar';
import clsx from 'clsx';
import commaNumber from 'comma-number';
import ViewAccountDetailsButton from './ViewAccountDetailsButton';

const useStyles = makeStyles({
    usageSummary: {
        width: 1088,
        borderRadius: 4,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        padding: 24,
        paddingBottom: 16,
        marginBottom: 24,
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 2,
        color: 'rgba(0,0,0,0.88)',
        marginRight: 8,
    },
    line: {
        height: 2,
        width: 682,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    subtitleLine: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    title: {
        marginBottom: 24,
    },
    usageBarCount: {
        display: 'flex',
    },
    largeCountContainer: {
        marginLeft: 16,
    },
    count: {
        color: 'rgba(0,0,0,0.8)',
    },
    largeCount: {
        marginTop: 4,
        width: 152,
        fontFamily: 'Ubuntu',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        textAlign: 'center',
    },
    largeCountLabel: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
        marginTop: 12,
        textAlign: 'center',
    },
});

interface Props {}

const UsageSummary: FC<Props> = () => {
    const classes = useStyles();
    const { user, accountInfo } = useStore();

    const isComplete = user?.resellerType === ResellerType.Default;
    const annual = false;

    return (
        <div className={classes.usageSummary}>
            <Typography variant="h6" className={classes.title}>
                Current Usage Summary
            </Typography>
            <div className={classes.subtitleLine}>
                <div className={classes.subtitle}>TOTAL OFFICE 365 USERS</div>
                <div className={classes.line} />
            </div>
            <Grid container={true} direction="row">
                <Grid item={true} xs={10}>
                    <UsageBar
                        firstCount={accountInfo?.completeLicensesUsed ?? 0}
                        secondCount={
                            isComplete
                                ? (accountInfo?.o365Licenses ?? 0) - (accountInfo?.completeLicensesUsed ?? 0)
                                : accountInfo?.starterLicensesUsed
                                ? accountInfo?.starterLicensesUsed
                                : 0
                        }
                        o365Count={accountInfo?.o365Licenses ?? 0}
                        isConnected={true}
                        isComplete={isComplete}
                        usageBarWidth={872}
                    />
                </Grid>
                <Grid item={true} xs={2}>
                    <div className={classes.largeCountContainer}>
                        <div className={clsx(classes.largeCount, classes.count)}>
                            {commaNumber(accountInfo?.completeLicensesAvailable ?? 0)}
                        </div>
                        <div className={classes.largeCountLabel}>
                            {annual
                                ? 'Unused Month-Credits'
                                : isComplete
                                ? 'Available Nulia Licenses'
                                : 'Available Complete Licenses'}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Box marginTop={1}>
                <ViewAccountDetailsButton />
            </Box>
        </div>
    );
};

export default UsageSummary;
