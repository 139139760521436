import { ChangeEvent, FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Account } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import { Colors, SecondaryButton, usePrevious } from 'common';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    notes: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
    },
    notesTitle: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontSize: 12,
        letterSpacing: 2,
        lineHeight: '16px',
        marginBottom: 16,
    },
    notesTextArea: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        border: 0,
        height: 120,
        width: '100%',
        resize: 'none',
        outline: 'none',
        caretColor: 'rgba(101,78,163,0.88)',
    },
});

interface Props {
    account: Account;
}

const Notes: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const { user, setShowWarning, setWarningData, strings } = useStore();
    const [notes, setNotes] = useState<string>('');
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const previousNotes = usePrevious<string>(notes);
    const ApiService = useApiService();

    useEffect(() => {
        if (!user) {
            return;
        }
        const getNotes = async () => {
            const response = await ApiService.getNotes(account.id);
            if (!response) {
                return;
            }
            setNotes(response.data.notes ? response.data.notes : '');
        };
        if (!retrievedData) {
            setRetrievedData(true);
            getNotes();
        }
    }, [ApiService, account, retrievedData, notes, previousNotes, setShowWarning, setWarningData, user]);

    if (!user) {
        return null;
    }

    const onChangeNotes = (event: ChangeEvent<HTMLTextAreaElement>) => setNotes(event.target.value);
    const onSave = async () => {
        setSaving(true);
        await ApiService.updateNotes(account.id, notes);
        setSaving(false);
    };

    return (
        <div className={classes.notes}>
            <div className={classes.notesTitle}>{strings?.notes}</div>
            <textarea
                className={classes.notesTextArea}
                placeholder={strings?.notesPlaceholder}
                onChange={onChangeNotes}
                value={notes}
            />
            <SecondaryButton onClick={onSave} disabled={saving || previousNotes === notes}>
                {strings?.save}
            </SecondaryButton>
        </div>
    );
};

export default Notes;
