import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import { format } from 'date-fns';

export interface Strings extends LocalizedStringsMethods {
    // Common Strings
    view: string;
    starter: string;
    loading: string;
    submit: string;
    goBack: string;
    partnerPortal: string;
    login: string;
    cancel: string;
    create: string;
    addCustomer: string;
    addReseller: string;
    reseller: string;
    customerName: string;
    addLicenses: string;
    addUpgrades: string;
    upgradeLicenses: string;
    removeLicenses: string;
    removeUpgrades: string;
    parentReseller: string;
    resellerName: string;
    connectToTenant: string;
    copyUrl: string;
    acceptAndContinue: string;
    associate: string;
    associateExistingAccount: string;
    account: string;
    accounts: string;
    upgrade: string;
    nuliaComplete: string;
    close: string;
    viewPreferences: string;
    logout: string;
    complete: string;
    notComplete: string;
    confirm: string;
    okay: string;
    add: string;
    addAnother: string;
    name: string;
    email: string;
    completeLicenses: string;
    starterLicenses: string;
    report: string;

    // Contact
    contactPlaceholder: string;
    contactCheckboxLabel: string;
    contactSubmitted: string;

    // Apocalypse Warning Dialog
    apocalypseTitle: string;
    apocalypseText: string;

    // Header
    resources: string;
    contact: string;

    // Preferences
    preferences: string;
    enableAnalytics: string;

    // Add Customer Dialog
    addCustomerGDPRTooltip: string;
    addCustomerGDPRLabel: string;

    // Add License Dialog
    addLicenseInputLabel: string;

    // Connect Tenant Dialog
    connectTenantRegister: string;
    connectTenantRegisterAfter: string;

    // Cookies Dialog
    cookiesDialogText: string;
    cookiesDialogLink: string;
    cookiesDialogLabel: string;

    // Send Instructions Dialog
    sendInstructionsTitle: string;
    sendInstructionsEmail: string;
    adminEmail: string;

    // Upgrade Licenses Dialog
    licensesAssigned: (used: number, available: number) => string;

    // Add Button
    addButtonCustomer: string;
    addButtonReseller: string;
    addButtonMoveAccount: string;

    // Account Opportunities
    accountOpportunitiesTitle: string;
    accountOpportunitiesText: string;
    accountOpportunitiesTitleRequestComplete: string;
    accountOpportunitiesTextRequestComplete: string;
    accountOpportunitiesTitleCompleteStarter: string;
    accountOpportunitiesTextCompleteStarter: string;

    // Account Setup
    accountSetupProgress: string;
    accountSetupInitial: string;
    accountSetupStarterOutcome: string;
    accountSetupStarterOutcomeDate: (date: string) => string;
    accountSetupConnection: string;
    accountSetupConnectionDate: (date: string) => string;
    accountSetupCompleteLicenses: string;
    accountSetupCompleteLicensesDate: (date: string) => string;
    accountSetupAdmin: string;
    accountSetupAdminDate: (date: string) => string;

    // Account Table
    status: string;
    companyName: string;
    o365Licenses: string;
    licenses: string;
    notConnected: string;
    registrationStarter: string;
    connected: string;
    withinTwoWeeks: string;
    needsAttention: string;

    // Activity
    date: string;
    time: string;
    message: string;
    formattedDateP: (date: string) => string;
    formattedDateHM: (date: string) => string;

    // Administrators
    nuliaAdmins: string;
    adminsNotConnected: string;
    adminsBulkAssign: string;
    adminsUserNotFoundDialogTitle: string;
    adminsUserNotFoundDialogText: string;
    removeAdminDialogTitle: string;
    removeAdminDialogText: (name: string) => string;

    // Connection
    connection: string;
    beforeConnectionText: string;
    connect: string;
    amAdminForTenant: string;
    notAdminForTenant: string;
    sendInstructions: string;
    successfullyConnected: string;
    initiatedConnection: string;
    reconnect: string;

    // Licenses
    planDetails: string;
    mtm: string;

    // License Usage
    usage: string;
    totalO365Tenant: string;
    nuliaUsers: string;
    completeUsers: string;
    usersWithoutNulia: string;
    starterUsers: string;
    availableNuliaLicenses: string;
    availableCompleteLicenses: string;
    o365UserWithoutNulia: string;
    nuliaCompleteUsers: string;
    nuliaStarterUsers: string;
    connectUsageData: string;

    // Notes
    save: string;
    notes: string;
    notesPlaceholder: string;

    // Outcome Picker
    starterOutcomes: string;
    starterOutcomesAccess: string;
    additionalOutcome: string;
    setOutcome: string;

    // Search Accounts
    searchPlaceholder: string;

    // Starter Outcomes
    includedStarterOutcomes: string;

    //Invoices Download
    byCustomerAll: string;
    byDirectAccounts: string;
    customerList: string;

    // No account dialog
    noAccountDialogTitle: string;
    noAccountDialogMessage: string;

    licenseSyncTooltip: string;
    monthCreditsTooltip: string;

    noCsvFound: string;
}

export const languages = {
    'en-us': {
        // Common Strings
        view: 'VIEW',
        starter: 'Starter',
        loading: 'Loading...',
        submit: 'Submit',
        goBack: 'Go Back',
        partnerPortal: 'Partner Portal',
        login: 'Login',
        cancel: 'CANCEL',
        create: 'CREATE',
        addCustomer: 'Add Customer',
        addReseller: 'Add Reseller',
        reseller: 'Reseller',
        customerName: 'Customer Name',
        addLicenses: 'Add Licenses',
        addUpgrades: 'Add Upgrades',
        upgradeLicenses: 'Upgrade Licenses',
        removeLicenses: 'Remove Licenses',
        removeUpgrades: 'Remove Upgrades',
        parentReseller: 'Parent Reseller',
        resellerName: 'Reseller Name',
        connectToTenant: 'Connect to Tenant',
        copyUrl: 'Copy Url',
        acceptAndContinue: 'ACCEPT AND CONTINUE',
        associate: 'ASSOCIATE',
        associateExistingAccount: 'Associate Existing Account',
        account: 'Account',
        accounts: 'Accounts',
        upgrade: 'Upgrade',
        nuliaComplete: 'Nulia Complete',
        close: 'Close',
        viewPreferences: 'VIEW PREFERENCES',
        logout: 'LOG OUT',
        complete: 'Complete',
        notComplete: 'Not Complete',
        confirm: 'Confirm',
        okay: 'OK',
        add: 'ADD',
        addAnother: 'ADD ANOTHER',
        name: 'Name',
        email: 'Email',
        completeLicenses: 'Complete Licenses',
        starterLicenses: 'Starter Licenses',
        report: 'Report',

        // Contact
        contactPlaceholder: 'Add feedback here...',
        contactCheckboxLabel: '(Optional) Nulia has permission to send session data with this message.',
        contactSubmitted: 'Thanks! Your feedback has been received.',

        // Apocalypse Warning Dialog
        apocalypseTitle: 'Could not complete that action',
        apocalypseText:
            'Uh oh, something went wrong. If you have enabled analytics we are already on ' +
            'the case! We recommend you try again in the meantime. If the problem persists ' +
            'or you need immediate assistance contact us with the error information below.',

        // Header
        customer: 'ACCOUNTS',
        resources: 'RESOURCES',
        contact: 'CONTACT',

        // Preferences
        preferences: 'Preferences',
        enableAnalytics: 'Enable Analytics',

        // Add Customer Dialog
        addCustomerGDPRTooltip:
            'Checking this box lets us know that this customer falls under the general data protection regulation (GDPR)',
        addCustomerGDPRLabel: 'Customer Falls Under GDPR?',

        // Add License Dialog
        addLicenseInputLabel: 'Number of Licenses',

        // Connect Tenant Dialog
        connectTenantRegister:
            'If you have access to global administrator credentials for your customer’s tenant ' +
            'and a fresh browser window (a new Chrome incognito or Edge InPrivate window) you can ' +
            'register that customer yourself right now! ',
        connectTenantRegisterAfter:
            'Using your global administrator credentials and the unique registration link below ' +
            'connect your customer’s tenant to Nulia. Double check the customer’s name after ' +
            'logging in to ensure you are connecting the correct customer.',

        // Cookies Dialog
        cookiesDialogText:
            'Nulia uses cookies to support user identification and prevent authentication abuses. ' +
            'In order to use Nulia Works you must agree to our cookies policy by ' +
            'clicking "ACCEPT AND CONTINUE" before you can log in. We encourage you to ' +
            'check "Allow Nulia to collect analytics" to get the most personalized experience. ',
        cookiesDialogLink: 'More Information',
        cookiesDialogLabel: 'Allow Nulia to collect analytics',

        // Send Instructions Dialog
        sendInstructionsTitle: 'Send Instructions',
        sendInstructionsEmail:
            'Enter the email of your customer’s tenant administrator to send an email ' +
            'with instructions for connecting Nulia with their Office 365 tenant.',
        adminEmail: 'Admin Email',

        // Upgrade Licenses Dialog
        licensesAssigned: (used: number, available: number) => `${used}/${available} Licenses Assigned`,

        // Add Button
        addButtonCustomer: '+ CUSTOMER',
        addButtonReseller: '+ RESELLER',
        addButtonMoveAccount: 'Move Account Here',

        // Account Opportunities
        accountOpportunitiesTitle: 'Opportunities',
        accountOpportunitiesText: 'Customer usage of Nulia Starter that may lead to sales opportunities.',
        accountOpportunitiesTitleRequestComplete: 'Users Requesting Upgrades',
        accountOpportunitiesTextRequestComplete: 'Users requesting Nulia Complete upgrades from their Nulia Admin.',
        accountOpportunitiesTitleCompleteStarter: 'Completed Nulia Starter',
        accountOpportunitiesTextCompleteStarter:
            'The number of users who have completed the two Starter outcomes and may be ready for Nulia Complete.',

        // Account Setup
        accountSetupProgress: 'Account Setup Progress',
        accountSetupInitial: 'INITIAL SETUP',
        accountSetupStarterOutcome: 'Select starter outcome',
        accountSetupStarterOutcomeDate: (date: string) => `Selected on ${format(new Date(date), 'PP')}`,
        accountSetupConnection: 'Establish Nulia connection',
        accountSetupConnectionDate: (date: string) => `Connected on ${format(new Date(date), 'PP')}`,
        accountSetupCompleteLicenses: 'Add Complete licenses',
        accountSetupCompleteLicensesDate: (date: string) => `Added on ${format(new Date(date), 'PP')}`,
        accountSetupAdmin: 'Add Nulia administrators',
        accountSetupAdminDate: (date: string) => `Added on ${format(new Date(date), 'PP')}`,

        // Account Table
        status: 'Status',
        companyName: 'Company Name',
        o365Licenses: 'Office 365 Licenses',
        licenses: 'Licenses',
        notConnected: 'Not Connected',
        registrationStarter: 'Registration Started',
        connected: 'Connected',
        withinTwoWeeks: 'Within first two weeks',
        needsAttention: 'Needs Attention',

        // Activity
        date: 'Date',
        time: 'Time',
        message: 'Message',
        formattedDateP: (date: string) => `${format(new Date(date), 'PP')}`,
        formattedDateHM: (date: string) => `${format(new Date(date), 'h:mm a')}`,

        // Administrators
        nuliaAdmins: 'Nulia Administrators',
        adminsNotConnected: 'Connect your Office 365 Tenant to Fully Add Administrators',
        adminsBulkAssign: 'These people can bulk assign outcomes across the tenant.',
        adminsUserNotFoundDialogTitle: 'User Not Found',
        adminsUserNotFoundDialogText:
            "Sorry, we couldn't find that user. Please check their email address or contact us if you need help.",
        removeAdminDialogTitle: 'Remove Admin',
        removeAdminDialogText: (name: string) => `Do you want to remove ${name}?`,

        // Connection
        connection: 'Connection',
        beforeConnectionText:
            'Before connecting the Tenant, you must first define the Outcomes available in the Starter Account.',
        connect: 'CONNECT',
        amAdminForTenant: 'I am the administrator for this tenant',
        notAdminForTenant: 'I am NOT the administrator for this tenant',
        sendInstructions: 'SEND INSTRUCTIONS',
        successfullyConnected: 'Successfully connected on ',
        initiatedConnection: 'Initiated connection on ',
        reconnect: 'RECONNECT',

        // Licenses
        planDetails: 'PLAN DETAILS',
        mtm: 'Month-to-Month',

        // License Usage
        usage: 'Usage',
        totalO365Tenant: 'TOTAL OFFICE 365 TENANT',
        nuliaUsers: 'Nulia Users',
        completeUsers: 'Complete Users',
        usersWithoutNulia: 'Users without Nulia',
        starterUsers: 'Starter Users',
        availableNuliaLicenses: 'Available Nulia Licenses',
        availableCompleteLicenses: 'Available Complete Licenses',
        o365UserWithoutNulia: 'Office 365 Users without Nulia',
        nuliaCompleteUsers: 'Nulia Complete Users',
        nuliaStarterUsers: 'Nulia Starter Users',
        connectUsageData: 'Connect your Office 365 Tenant to See Usage Data',

        // Notes
        save: 'SAVE',
        notes: 'NOTES',
        notesPlaceholder: 'Add notes here...',

        // Outcome Picker
        starterOutcomes: 'Starter Outcomes',
        starterOutcomesAccess: 'All Users Have Access to:',
        additionalOutcome: 'Choose one additional Outcome to add:',
        setOutcome: 'Set Outcome',

        // Search Accounts
        searchPlaceholder: 'Search for a customer or reseller',

        // Starter Outcomes
        includedStarterOutcomes: 'Included Starter Outcomes',

        // Invoice Downloads
        byCustomerAll: 'Summary License Counts (.csv)',
        byDirectAccounts: 'By Direct Accounts (.csv)',
        customerList: 'Detailed License Counts (.csv)',

        noAccountDialogTitle: 'You have no account',
        noAccountDialogMessage: 'Sorry, you do not currently have an account.',

        licenseSyncTooltip:
            'When on, License Sync checks nightly to ensure all Microsoft 365 licensed users have a Nulia license',
        monthCreditsTooltip:
            'A user with a Nulia license will use one month-credit each month they have a license assigned.',

        noCsvFound:
            'Sorry, the .csv you are requesting is not currently available. ' +
            'Please check back after the first of the month. ',
    },
};

export const localizedStrings: Strings = new LocalizedStrings(languages);
