import { Account, AccountStatus, AccountType, Activity, LicenseClass } from '../types/types';
import { IApiService } from '../services/ApiServiceProvider';

export const getStatusOrderBy = (account: Account) => {
    if (account.type === AccountType.Reseller) {
        return 6;
    }
    switch (account.status) {
        case AccountStatus.NeedsAttention:
            return 1;
        case AccountStatus.New:
            return 2;
        case AccountStatus.Initiated:
            return 3;
        case AccountStatus.WithinTwoWeeks:
            return 4;
        case AccountStatus.Good:
            return 5;
    }
};

export const getAllAccounts = async (setAllAccounts: (accounts: Account[]) => void, apiService: IApiService) => {
    const response = await apiService.getAllAccounts();
    if (!response) {
        return;
    }
    setAllAccounts(response.data);
};

export const getActivities = async (
    activities: Map<string, Activity[]>,
    resellerId: string,
    setActivities: (activities: Map<string, Activity[]>) => void,
    apiService: IApiService
) => {
    const response = await apiService.getActivities(resellerId, AccountType.Reseller, resellerId);
    if (!response) {
        return;
    }
    const newActivities = new Map(activities);
    newActivities.set(resellerId, response.data);
    setActivities(newActivities);
};

export const isFloat = (n: number) => {
    return Number(n) === n && n % 1 !== 0;
};

export const getValidLicenseInput = (n: string): string | undefined => {
    let output = '';
    if (isNaN(Number(n))) {
        return;
    } else if (Number(n) > 500000) {
        output = String(500000);
    } else if (Number(n) < 1) {
        output = '';
    } else if (isFloat(Number(n))) {
        output = String(Math.floor(Number(n)));
    } else {
        output = n;
    }
    return output;
};

export const updateBreadcrumbs = (account: Account, breadcrumbs?: Account[]): Account[] => {
    const parentId = account.type === AccountType.Reseller ? account.parentId : account.resellerId;
    if (!parentId) {
        return [account];
    }

    if (breadcrumbs) {
        for (let index = 0; index < breadcrumbs.length; ++index) {
            if (breadcrumbs[index].id === account.id) {
                return breadcrumbs.slice(0, index + 1);
            }
        }

        breadcrumbs.push(account);
        return breadcrumbs;
    }

    return [account];
};

export const isStarter = (licenseClass?: LicenseClass) =>
    licenseClass === LicenseClass.Starter || licenseClass === LicenseClass.StarterCompleteSync;

export const getDiffOrZero = (a: number, b: number) => {
    return a - b > 0 ? a - b : 0;
};
