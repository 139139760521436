import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { Tab } from '../../types/types';
import { Colors } from 'common';
import clsx from 'clsx';

const useStyles = makeStyles({
    tabs: {
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    tab: {
        fontFamily: 'Ubuntu',
        width: 'fit-content',
        height: 64,
        color: Colors.textTertiary,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 1,
        lineHeight: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '2px solid transparent',
        borderBottom: '2px solid transparent',
        '&:hover': {
            color: Colors.textPrimary,
        },
        '&:focus': {
            color: Colors.textPrimary,
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
        cursor: 'pointer',
        marginLeft: 16,
    },
    innerTab: {
        marginRight: 16,
    },
    selectedText: {
        color: Colors.purple,
    },
    selectedBar: {
        borderBottom: '2px solid #654EA3',
    },
});

interface Props {
    tabs: Tab[];
}

const Tabs: FC<Props> = ({ tabs }) => {
    const classes = useStyles();
    return (
        <div className={classes.tabs}>
            {tabs.map((tab, index) => (
                <div
                    key={tab.name + index}
                    className={clsx(
                        classes.tab,
                        tab.selected ? classes.selectedBar : undefined,
                        index < tabs.length - 1 ? classes.innerTab : undefined
                    )}
                    onClick={tab.onClick}
                >
                    <span className={tab.selected ? classes.selectedText : undefined}>{tab.name}</span>
                </div>
            ))}
        </div>
    );
};

export default Tabs;
