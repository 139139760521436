import { CSSProperties, FC } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell as MuiTableCell, TableRow, Typography } from '@material-ui/core';
import { useStore } from '../../store/StoreProvider';
import { AccountLicenseUsageDTO, ResellerType } from '../../types/types';
import UsageBar from '../accounts/UsageBar';
import clsx from 'clsx';
import commaNumber from 'comma-number';
import { Colors } from 'common';
import { Padding } from '@material-ui/core/TableCell';
import { getDiffOrZero } from '../../utils/helper';

const useStyles = makeStyles({
    accountInfo: {
        display: 'flex',
    },
    currentUsage: {
        width: 715,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 24,
        marginRight: 32,
    },
    title: {
        height: 72,
        display: 'flex',
        alignItems: 'center',
    },
    largeCountContainer: {
        marginLeft: 16,
    },
    count: {
        color: 'rgba(0,0,0,0.8)',
    },
    largeCount: {
        marginTop: 4,
        width: 152,
        fontFamily: 'Ubuntu',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        textAlign: 'center',
    },
    largeCountLabel: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
        marginTop: 12,
        textAlign: 'center',
    },
    line: {
        height: 2,
        width: 310,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    subtitleLine: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 2,
        color: 'rgba(0,0,0,0.88)',
        marginRight: 8,
    },
    usageBarCount: {
        display: 'flex',
        marginBottom: 24,
    },
    key: {
        flex: 1,
    },
    keyItem: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    keyBoxLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    keyNumber: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    keyDarkBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.08)',
        backgroundColor: '#b2a6d1',
        marginRight: 16,
    },
    keyLightBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.16)',
        backgroundColor: '#e8e8f5',
        marginRight: 16,
    },
    keyGreyBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#f5f5f5',
        marginRight: 16,
    },
    keyLabel: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    keyToggle: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 24,
    },
    skuToggle: {
        width: 24,
        marginLeft: 24,
        marginTop: 6,
    },
    skus: {
        marginLeft: 40,
        marginBottom: 8,
    },
    sku: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    skuNumber: {
        color: 'rgba(0,0,0,0.88)',
    },
    keyLine: {
        marginLeft: 40,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    table: {
        marginBottom: 24,
    },
    preBuysAccounts: {},
    preBuys: {
        width: 341,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        padding: 24,
        marginBottom: 24,
    },
    caption: {
        color: 'rgba(0, 0, 0, 0.8)',
    },
    darkText: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.8)',
    },
    accounts: {
        width: 341,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        padding: 24,
    },
});

const AccountInfo: FC = () => {
    const classes = useStyles();
    const { user, accountInfo } = useStore();

    if (!user) {
        return null;
    }

    const TableCell: FC<{ style?: CSSProperties; padding?: Padding }> = ({ children, style, padding }) => (
        <MuiTableCell padding={padding} style={{ ...style, height: 36 }}>
            {children}
        </MuiTableCell>
    );

    const HeaderCell: FC = ({ children }) => (
        <TableCell>
            <Typography variant="caption">{children}</Typography>
        </TableCell>
    );

    const BodyTotal: FC = ({ children }) => (
        <TableCell>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
                {children}
            </Typography>
        </TableCell>
    );

    const BodyTitle: FC = ({ children }) => (
        <TableCell>
            <Typography variant="body1">{children}</Typography>
        </TableCell>
    );

    const BodyCell: FC<{ color?: string }> = ({ children, color }) => (
        <TableCell>
            <Typography variant="body2" style={{ color }}>
                {children}
            </Typography>
        </TableCell>
    );

    const NoLineCell: FC = ({ children }) => (
        <TableCell padding="none" style={{ border: 'none', width: 24 }}>
            {children}
        </TableCell>
    );

    const SideCell: FC<{ last?: boolean }> = ({ children, last }) => (
        <TableCell style={{ borderBottom: last ? '2px solid #d6d6d6' : undefined }}>
            <Typography variant="caption" className={classes.caption}>
                {children}
            </Typography>
        </TableCell>
    );

    const DarkSideCell: FC = ({ children }) => (
        <TableCell>
            <div className={classes.darkText}>{children}</div>
        </TableCell>
    );

    const LicenseRow: FC<{ licenseInfo: AccountLicenseUsageDTO }> = ({ licenseInfo }) => (
        <TableRow>
            <NoLineCell>{''}</NoLineCell>
            <BodyCell>{licenseInfo.sku}</BodyCell>
            <BodyCell color="rgba(0,0,0,0.88)">{commaNumber(licenseInfo.current)}</BodyCell>
        </TableRow>
    );

    const isComplete = user.resellerType === ResellerType.Default;
    const annual = false;

    return (
        <div className={classes.accountInfo}>
            <div className={classes.currentUsage}>
                <Typography variant="h6" className={classes.title}>
                    Current Usage
                </Typography>
                <div className={classes.subtitleLine}>
                    <div className={classes.subtitle}>TOTAL OFFICE 365 USERS</div>
                    <div className={classes.line} />
                </div>
                <div className={classes.usageBarCount}>
                    {accountInfo && (
                        <UsageBar
                            firstCount={accountInfo?.completeLicensesUsed}
                            secondCount={
                                isComplete
                                    ? getDiffOrZero(accountInfo?.o365Licenses, accountInfo?.completeLicensesUsed)
                                    : accountInfo?.starterLicensesUsed
                                    ? accountInfo?.starterLicensesUsed
                                    : 0
                            }
                            o365Count={accountInfo?.o365Licenses}
                            isConnected={true}
                            isComplete={isComplete}
                            usageBarWidth={500}
                        />
                    )}
                    <div className={classes.largeCountContainer}>
                        <div className={clsx(classes.largeCount, classes.count)}>
                            {commaNumber(accountInfo?.completeLicensesAvailable)}
                        </div>
                        <div className={classes.largeCountLabel}>
                            {annual
                                ? 'Unused Month-Credits'
                                : isComplete
                                ? 'Available Nulia Licenses'
                                : 'Available Complete Licenses'}
                        </div>
                    </div>
                </div>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow>
                            <NoLineCell>{''}</NoLineCell>
                            <HeaderCell>Type</HeaderCell>
                            <HeaderCell>Current Quantity</HeaderCell>
                        </TableRow>
                        {accountInfo && (
                            <>
                                <TableRow>
                                    <NoLineCell>
                                        <div className={classes.keyDarkBox} />
                                    </NoLineCell>
                                    <BodyTitle>{isComplete ? 'Nulia Users' : 'Nulia Complete Users'}</BodyTitle>
                                    <BodyTotal>
                                        {commaNumber(
                                            accountInfo?.completeLicensesUsed + accountInfo?.completeLicensesAvailable
                                        )}
                                    </BodyTotal>
                                </TableRow>
                                {accountInfo?.directCompleteInternal.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directCompleteInternal} />
                                )}
                                {accountInfo?.directCompleteMonthToMonth.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directCompleteMonthToMonth} />
                                )}
                                {accountInfo?.directCompleteNotForResale.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directCompleteNotForResale} />
                                )}
                                {accountInfo?.directCompleteTerm.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directCompleteTerm} />
                                )}
                                {accountInfo?.directCompleteLegacy.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directCompleteLegacy} />
                                )}
                                {accountInfo?.indirectCompleteInternal.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectCompleteInternal} />
                                )}
                                {accountInfo?.indirectCompleteMonthToMonth.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectCompleteMonthToMonth} />
                                )}
                                {accountInfo?.indirectCompleteNotForResale.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectCompleteNotForResale} />
                                )}
                                {accountInfo?.indirectCompleteTerm.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectCompleteTerm} />
                                )}
                                {accountInfo?.indirectCompleteLegacy.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectCompleteLegacy} />
                                )}
                                <TableRow>
                                    <NoLineCell>
                                        {isComplete ? (
                                            <div className={classes.keyGreyBox} />
                                        ) : (
                                            <div className={classes.keyLightBox} />
                                        )}
                                    </NoLineCell>
                                    <BodyTitle>
                                        {isComplete ? 'Office 365 Users without Nulia' : 'Nulia Starter Users'}
                                    </BodyTitle>
                                    <BodyTotal>
                                        {commaNumber(
                                            isComplete
                                                ? getDiffOrZero(
                                                      accountInfo?.o365Licenses,
                                                      accountInfo?.completeLicensesUsed
                                                  )
                                                : accountInfo?.starterLicensesUsed
                                        )}
                                    </BodyTotal>
                                </TableRow>
                                {accountInfo?.directStarterMonthToMonth.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.directStarterMonthToMonth} />
                                )}
                                {accountInfo?.indirectStarterMonthToMonth.quantity > 0 && (
                                    <LicenseRow licenseInfo={accountInfo?.indirectStarterMonthToMonth} />
                                )}
                                {!isComplete && (
                                    <TableRow>
                                        <NoLineCell>
                                            <div className={classes.keyGreyBox} />
                                        </NoLineCell>
                                        <BodyTitle>Office 365 Users without Nulia</BodyTitle>
                                        <BodyTotal>
                                            {commaNumber(
                                                getDiffOrZero(
                                                    getDiffOrZero(
                                                        accountInfo?.o365Licenses,
                                                        accountInfo?.completeLicensesUsed
                                                    ),
                                                    accountInfo?.starterLicensesUsed
                                                )
                                            )}
                                        </BodyTotal>
                                        <BodyTitle>—</BodyTitle>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </div>
            {accountInfo && (
                <div className={classes.preBuysAccounts}>
                    <div className={classes.accounts}>
                        <Box marginBottom={3}>
                            <Typography variant="overline">ACCOUNTS</Typography>
                        </Box>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <DarkSideCell>Resellers</DarkSideCell>
                                    <SideCell>{accountInfo?.numResellers ?? 0}</SideCell>
                                </TableRow>
                                <TableRow>
                                    <DarkSideCell>Direct Customers</DarkSideCell>
                                    <SideCell>{accountInfo?.numDirectCustomers ?? 0}</SideCell>
                                </TableRow>
                                <TableRow>
                                    <DarkSideCell>Indirect Customers</DarkSideCell>
                                    <SideCell>{accountInfo?.numIndirectCustomers ?? 0}</SideCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountInfo;
