import { FC, useEffect, useState } from 'react';
import { Checkbox, Dialog } from 'common';
import { useStore } from '../../store/StoreProvider';
import { ENABLE_ANALYTICS } from '../../utils/constants';

interface Props {
    open: boolean;
    onClose: () => void;
}

const Preferences: FC<Props> = ({ open, onClose }) => {
    const { user, strings } = useStore();
    const [enableAnalytics, setEnableAnalytics] = useState<boolean>(false);

    useEffect(() => {
        setEnableAnalytics(localStorage.getItem(ENABLE_ANALYTICS) !== null);
    }, [user]);

    if (!user) {
        return null;
    }

    const onAccept = async () => {
        onClose();
        if (enableAnalytics) {
            localStorage.setItem(ENABLE_ANALYTICS, 'true');
        } else {
            localStorage.removeItem(ENABLE_ANALYTICS);
        }
    };
    const toggleEnableAnalytics = () => setEnableAnalytics(!enableAnalytics);
    const onCancel = () => {
        setEnableAnalytics(localStorage.getItem(ENABLE_ANALYTICS) !== null);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onAccept={onAccept}
            cancelText={strings?.cancel}
            acceptText={strings?.submit}
            title={strings?.preferences}
            content={
                <div style={{ width: 250 }}>
                    <Checkbox
                        checked={enableAnalytics}
                        onClick={toggleEnableAnalytics}
                        label={strings?.enableAnalytics}
                    />
                </div>
            }
        />
    );
};

export default Preferences;
