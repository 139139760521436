import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors } from 'common';
import Page from '../Page';
import PageContent from '../PageContent';
import Header from '../header/Header';
import { useStore } from '../../store/StoreProvider';
import clsx from 'clsx';
import Invoices from './Invoices';
import AccountInfo from './AccountInfo';
import { Route, useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
    yourAccount: {
        marginTop: 16,
    },
    titleUpdated: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
    },
    updated: {
        color: 'rgba(0,0,0,0.4)',
    },
    tabs: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
        marginTop: 16,
    },
    tab: {
        height: 48,
        fontFamily: 'Ubuntu',
        fontSize: 12,
        fontWeight: 600,
        letterSpacing: 1,
        color: 'rgba(0, 0, 0, 0.4)',
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'uppercase',
        '&:hover': {
            color: 'rgba(101, 78, 163, 0.88)',
        },
    },
    innerTab: {
        marginRight: 32,
    },
    selectedTab: {
        color: 'rgba(101, 78, 163, 0.88)',
    },
    selectedBar: {
        position: 'absolute',
        top: 46.5,
        height: 4,
        width: 32,
        minWidth: 32,
        backgroundColor: '#654ea3',
        borderRadius: 2,
    },
});

type TabType = 'statements' | 'account information';
const BASE_PATH = '/partner-details';
const tabLabelToPath = new Map([
    ['statements', `${BASE_PATH}/statements`],
    ['account information', `${BASE_PATH}/account-info`],
]);

const PartnerDetails: FC = () => {
    const classes = useStyles();
    const { user } = useStore();
    const history = useHistory();
    const location = useLocation();

    if (!user) {
        return null;
    }

    const selectTab = (value: TabType) => (event: unknown) => {
        switch (value) {
            case 'statements':
                history.push(`${BASE_PATH}/statements`);
                break;
            case 'account information':
                history.push(`${BASE_PATH}/account-info`);
                break;
            default:
                break;
        }
    };

    const Tab: FC<{ type: TabType; inner?: boolean }> = ({ type, inner }) => (
        <div
            className={clsx(
                classes.tab,
                inner && classes.innerTab,
                location.pathname === tabLabelToPath.get(type) && classes.selectedTab
            )}
            onClick={selectTab(type)}
        >
            {type}
            {location.pathname === tabLabelToPath.get(type) && <div className={classes.selectedBar} />}
        </div>
    );

    // TODO get last updated date

    return (
        <Page>
            <Header />
            <PageContent>
                <div className={classes.yourAccount}>
                    <div className={classes.titleUpdated}>
                        <div className={classes.title}>{user.resellerName.toUpperCase()}</div>
                    </div>
                    <div className={classes.tabs}>
                        <Tab type="statements" inner={true} />
                        <Tab type="account information" />
                    </div>
                    <Route path="/partner-details/statements">
                        <Invoices />
                    </Route>
                    <Route path="/partner-details/account-info">
                        <AccountInfo />
                    </Route>
                </div>
            </PageContent>
        </Page>
    );
};

export default PartnerDetails;
