import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import './styles/index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import StoreProvider from './store/StoreProvider';
import ErrorBoundary from './components/ErrorBoundary';
import AuthorizationProvider from './services/AuthorizationProvider';
import ApocalypseWarning from './components/ApocalypseWarning';
import App from './components/App';
import { NuliaTheme } from 'common';
import { CssBaseline } from '@material-ui/core';
import ApiServiceProvider from './services/ApiServiceProvider';

ReactDOM.render(
    <StoreProvider>
        <ApiServiceProvider>
            <BrowserRouter>
                <ErrorBoundary>
                    <AuthorizationProvider>
                        <CssBaseline />
                        <NuliaTheme>
                            <ApocalypseWarning />
                            <App />
                        </NuliaTheme>
                    </AuthorizationProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </ApiServiceProvider>
    </StoreProvider>,
    document.getElementById('root')
);

// If you want your client to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
