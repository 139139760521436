import { FC, useEffect, useState } from 'react';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Account, AccountInfo, AccountLicenseUsageDTO, ResellerType } from '../../types/types';
import UsageBar from './UsageBar';
import clsx from 'clsx';
import commaNumber from 'comma-number';
import { useStore } from '../../store/StoreProvider';
import { Colors, ChevronDownIcon, ChevronUpIcon } from 'common';
import ProjectedChargesButton from './ProjectedChargesButton';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    currentUsage: {
        width: 1088,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 16,
    },
    largeCountContainer: {
        marginLeft: 16,
    },
    count: {
        color: 'rgba(0,0,0,0.8)',
    },
    largeCount: {
        marginTop: 4,
        width: 152,
        fontFamily: 'Ubuntu',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        textAlign: 'center',
    },
    largeCountLabel: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
        marginTop: 12,
        textAlign: 'center',
    },
    line: {
        height: 2,
        width: 680,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    subtitleLine: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 2,
        color: 'rgba(0,0,0,0.88)',
        marginRight: 8,
    },
    usageBarCount: {
        display: 'flex',
    },
    key: {
        flex: 1,
    },
    keyItem: {
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    keyBoxLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    keyNumber: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    keyDarkBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.08)',
        backgroundColor: '#b2a6d1',
        marginRight: 16,
    },
    keyLightBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.16)',
        backgroundColor: '#e8e8f5',
        marginRight: 16,
    },
    keyGreyBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#f5f5f5',
        marginRight: 16,
    },
    keyLabel: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    keyToggle: {
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 24,
    },
    skuToggle: {
        width: 24,
        marginLeft: 24,
        marginTop: 6,
    },
    skus: {
        marginLeft: 40,
        marginBottom: 8,
    },
    sku: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    skuNumber: {
        color: 'rgba(0,0,0,0.88)',
    },
    keyLine: {
        marginLeft: 40,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 16,
    },
});

interface Props {
    account: Account;
    initiallyClosed: boolean;
}

const CurrentUsage: FC<Props> = ({ account, initiallyClosed }) => {
    const classes = useStyles();
    const [closed, setClosed] = useState<boolean>(true);
    const [closedSkus, setClosedSkus] = useState<boolean>(true);
    const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const { user } = useStore();
    const ApiService = useApiService();

    useEffect(() => {
        const getAccountInfo = async () => {
            const response = await ApiService.getAccountInfo(account.id);
            if (!response) {
                return;
            }
            setAccountInfo(response.data);
        };
        if (!retrievedData) {
            setRetrievedData(true);
            getAccountInfo();
        }
    }, [ApiService, account, retrievedData]);

    useEffect(() => {
        setClosed(initiallyClosed);
    }, [initiallyClosed]);

    const toggleSkus = () => setClosedSkus(!closedSkus);
    const toggleClosed = () => setClosed(!closed);

    const Sku: FC<{ skuData: AccountLicenseUsageDTO }> = ({ skuData }) => (
        <div className={classes.sku}>
            <Typography variant="body2">{skuData.sku}</Typography>
            <Typography variant="body2" className={classes.skuNumber}>
                {commaNumber(skuData.quantity)}
            </Typography>
        </div>
    );

    const isComplete = user?.resellerType === ResellerType.Default;
    const annual = false;
    const hasSkus =
        accountInfo &&
        (accountInfo?.directStarterMonthToMonth.quantity > 0 ||
            accountInfo?.indirectStarterMonthToMonth.quantity > 0 ||
            accountInfo?.directCompleteInternal.quantity > 0 ||
            accountInfo?.directCompleteMonthToMonth.quantity > 0 ||
            accountInfo?.directCompleteNotForResale.quantity > 0 ||
            accountInfo?.directCompleteTerm.quantity > 0 ||
            accountInfo?.indirectCompleteInternal.quantity > 0 ||
            accountInfo?.indirectCompleteMonthToMonth.quantity > 0 ||
            accountInfo?.indirectCompleteNotForResale.quantity > 0 ||
            accountInfo?.indirectCompleteTerm.quantity > 0);

    return (
        <div className={classes.currentUsage}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    Current Usage
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <div className={classes.subtitleLine}>
                        <div className={classes.subtitle}>TOTAL OFFICE 365 USERS</div>
                        <div className={classes.line} />
                    </div>
                    <div className={classes.usageBarCount}>
                        <UsageBar
                            firstCount={accountInfo?.completeLicensesUsed ?? 0}
                            secondCount={
                                isComplete
                                    ? (accountInfo?.o365Licenses ?? 0) - (accountInfo?.completeLicensesUsed ?? 0)
                                    : accountInfo?.starterLicensesUsed
                                    ? accountInfo?.starterLicensesUsed
                                    : 0
                            }
                            o365Count={accountInfo?.o365Licenses ?? 0}
                            isConnected={true}
                            isComplete={isComplete}
                            usageBarWidth={872}
                        />
                        <div className={classes.largeCountContainer}>
                            <div className={clsx(classes.largeCount, classes.count)}>
                                {commaNumber(account.completeLicensesAvailable)}
                            </div>
                            <div className={classes.largeCountLabel}>
                                {annual
                                    ? 'Unused Month-Credits'
                                    : isComplete
                                    ? 'Available Nulia Licenses'
                                    : 'Available Complete Licenses'}
                            </div>
                        </div>
                    </div>
                    <div className={classes.keyToggle}>
                        <div className={classes.key}>
                            {isComplete ? (
                                <div className={classes.keyItem}>
                                    <div className={classes.keyBoxLabel}>
                                        <div className={classes.keyDarkBox} />
                                        <div className={classes.keyLabel}>Nulia Users</div>
                                    </div>
                                    <div className={classes.keyNumber}>{commaNumber(account.completeLicensesUsed)}</div>
                                </div>
                            ) : (
                                <div className={classes.keyItem}>
                                    <div className={classes.keyBoxLabel}>
                                        <div className={classes.keyDarkBox} />
                                        <div className={classes.keyLabel}>Nulia Complete Users</div>
                                    </div>
                                    <div className={classes.keyNumber}>{commaNumber(account.completeLicensesUsed)}</div>
                                </div>
                            )}
                            <div className={classes.keyLine} />
                            {accountInfo && hasSkus && (
                                <Collapse in={closedSkus}>
                                    <div className={classes.skus}>
                                        {accountInfo?.directStarterMonthToMonth.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directStarterMonthToMonth} />
                                        )}
                                        {accountInfo?.indirectStarterMonthToMonth.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directStarterMonthToMonth} />
                                        )}
                                        {accountInfo?.directCompleteInternal.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directCompleteInternal} />
                                        )}
                                        {accountInfo?.directCompleteMonthToMonth.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directCompleteMonthToMonth} />
                                        )}
                                        {accountInfo?.directCompleteNotForResale.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directCompleteNotForResale} />
                                        )}
                                        {accountInfo?.directCompleteTerm.quantity > 0 && (
                                            <Sku skuData={accountInfo?.directCompleteTerm} />
                                        )}
                                        {accountInfo?.indirectCompleteInternal.quantity > 0 && (
                                            <Sku skuData={accountInfo?.indirectCompleteInternal} />
                                        )}
                                        {accountInfo?.indirectCompleteMonthToMonth.quantity > 0 && (
                                            <Sku skuData={accountInfo?.indirectCompleteMonthToMonth} />
                                        )}
                                        {accountInfo?.indirectCompleteNotForResale.quantity > 0 && (
                                            <Sku skuData={accountInfo?.indirectCompleteNotForResale} />
                                        )}
                                        {accountInfo?.indirectCompleteTerm.quantity > 0 && (
                                            <Sku skuData={accountInfo?.indirectCompleteTerm} />
                                        )}
                                    </div>
                                </Collapse>
                            )}
                            {isComplete ? (
                                <div className={classes.keyItem}>
                                    <div className={classes.keyBoxLabel}>
                                        <div className={classes.keyGreyBox} />
                                        <div className={classes.keyLabel}>Office 365 Users without Nulia</div>
                                    </div>
                                    <div className={classes.keyNumber}>
                                        {commaNumber(
                                            (accountInfo?.o365Licenses ?? 0) - (accountInfo?.completeLicensesUsed ?? 0)
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className={classes.keyItem}>
                                    <div className={classes.keyBoxLabel}>
                                        <div className={classes.keyLightBox} />
                                        <div className={classes.keyLabel}>Nulia Starter Licenses</div>
                                    </div>
                                    <div className={classes.keyNumber}>
                                        {commaNumber(accountInfo?.starterLicensesUsed ?? 0)}
                                    </div>
                                </div>
                            )}
                        </div>
                        {hasSkus && (
                            <div className={classes.skuToggle} onClick={toggleSkus}>
                                {closedSkus ? <ChevronDownIcon /> : <ChevronUpIcon />}
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>
            <div className={classes.button}>
                <ProjectedChargesButton resellerId={account.id} />
            </div>
        </div>
    );
};

export default CurrentUsage;
