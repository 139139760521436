import { FC, useState } from 'react';
import { useStore } from '../../store/StoreProvider';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Colors, ChevronDownIcon, ChevronUpIcon } from 'common';
import Customers from './Customers';
import { Account } from '../../types/types';

const useStyles = makeStyles({
    accountCustomers: {
        width: 1088,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    header: {
        height: 64,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    account: Account;
}

const AccountCustomers: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const { strings } = useStore();
    const [closed, setClosed] = useState<boolean>(true);

    const toggleClosed = () => setClosed(!closed);

    return (
        <div className={classes.accountCustomers}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    {strings?.accounts}
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Customers account={account} />
                </div>
            </Collapse>
        </div>
    );
};

export default AccountCustomers;
