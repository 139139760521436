import { ChangeEvent, FC, useEffect, useState } from 'react';
import AccountTable from './AccountTable';
import { makeStyles, Typography } from '@material-ui/core';
import { Colors, Select, SelectItem } from 'common';
import AddButton from '../buttons/AddButton';
import Page from '../Page';
import PageContent from '../PageContent';
import Header from '../header/Header';
import { useStore } from '../../store/StoreProvider';
import { AccountType, ResellerType } from '../../types/types';
import { useApiService } from '../../services/ApiServiceProvider';
import UsageSummary from './UsageSummary';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    customers: {
        marginTop: 16,
    },
    titleButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 24,
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
    },
    card: {
        width: 1088,
        borderRadius: 4,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        paddingTop: 24,
    },
    filterContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    filterLabel: {
        color: 'rgba(0,0,0,0.64)',
        marginRight: 16,
        marginLeft: 24,
    },
});

type AccountsFilter = 'All Accounts' | 'Customers' | 'Resellers';

const Accounts: FC = () => {
    const classes = useStyles();
    const { user, accounts, setAccounts } = useStore();
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [accountsFilter, setAccountsFilter] = useState<AccountsFilter>('All Accounts');
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    useEffect(() => {
        let stop = false;
        if (user && !retrievedData && !stop) {
            setRetrievedData(true);
            getAccountData(user.resellerId);
        }
        return () => {
            stop = true;
        };
    }, [ApiService, accounts, retrievedData, getAccountData, setAccounts, user]);

    const onChangeAccountsFilter = (event: ChangeEvent<{ value: unknown }>) =>
        setAccountsFilter(event.target.value as AccountsFilter);

    let partnerAccounts = user ? [...(accounts.has(user.resellerId) ? accounts.get(user.resellerId)! : [])] : [];
    if (accountsFilter === 'Customers') {
        partnerAccounts = partnerAccounts.filter((account) => account.type === AccountType.Customer);
    } else if (accountsFilter === 'Resellers') {
        partnerAccounts = partnerAccounts.filter((account) => account.type === AccountType.Reseller);
    }

    return (
        <Page>
            <Header />
            <PageContent>
                <div className={classes.customers}>
                    <div className={classes.titleButton}>
                        <div className={classes.title}>Accounts</div>
                        {user && <AddButton resellerId={user.resellerId} resellerName={user.resellerName} />}
                    </div>
                    <UsageSummary />
                    <div className={classes.card}>
                        <div className={classes.filterContainer}>
                            <Typography variant="overline" className={classes.filterLabel}>
                                FILTER BY
                            </Typography>
                            <Select value={accountsFilter} onChange={onChangeAccountsFilter}>
                                <SelectItem value="All Accounts">All Accounts</SelectItem>
                                <SelectItem value="Customers">Customers</SelectItem>
                                <SelectItem value="Resellers">Resellers</SelectItem>
                            </Select>
                        </div>
                        <AccountTable
                            key={user?.resellerId}
                            accounts={partnerAccounts}
                            completeOnly={user?.resellerType === ResellerType.Default}
                        />
                    </div>
                </div>
            </PageContent>
        </Page>
    );
};

export default Accounts;
