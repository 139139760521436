import { FC, MouseEvent, useEffect } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { LOGOS_PATH } from '../../utils/constants';
import AccountsMoreButton from '../buttons/AccountsMoreButton';
import { Account, AccountStatus, AccountType, LicenseClass, OrderByAccounts } from '../../types/types';
import clsx from 'clsx';
import { useStore } from '../../store/StoreProvider';
import { useHistory } from 'react-router-dom';
import ResellerIcon from '../icons/ResellerIcon';
import { Colors, TablePagination, TableSortLabel, Tooltip } from 'common';
import { useUpdateBreadcrumbs } from '../../utils/hooks';
// import { Order } from 'common-v2';
import { trimCompanyNames } from '../../utils/trimCompanyNames';
import { sort, getSorting } from '../../utils/sortAccounts';

const useStyles = makeStyles({
    customerTable: {},
    statusConnected: {
        height: 16,
        minHeight: 16,
        width: 16,
        minWidth: 16,
        maxWidth: 16,
        borderRadius: '50%',
        backgroundColor: Colors.green,
        border: '2px solid #ffffff',
    },
    statusNew: {
        height: 16,
        minHeight: 16,
        width: 16,
        minWidth: 16,
        maxWidth: 16,
        borderRadius: '50%',
        backgroundColor: Colors.white,
        border: `2px solid ${Colors.grey}`,
    },
    statusInitiated: {
        height: 16,
        minHeight: 16,
        width: 16,
        minWidth: 16,
        maxWidth: 16,
        borderRadius: '50%',
        backgroundColor: Colors.grey,
        border: '2px solid #ffffff',
    },
    statusNeedsAttention: {
        height: 16,
        minHeight: 16,
        width: 16,
        minWidth: 16,
        maxWidth: 16,
        borderRadius: '50%',
        backgroundColor: Colors.red,
        border: '2px solid #ffffff',
    },
    statusWithinTwoWeeks: {
        height: 16,
        minHeight: 16,
        width: 16,
        minWidth: 16,
        maxWidth: 16,
        borderRadius: '50%',
        backgroundColor: Colors.yellow,
        border: '2px solid #ffffff',
    },
    statusContainer: {
        width: 16,
    },
    licensesCell: {
        backgroundColor: 'rgba(101,78,163,0.04)',
    },
    headerCell: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: '20px',
    },
    customerName: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 16,
        letterSpacing: 0.15,
        lineHeight: '24px',
    },
    count: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
    },
    row: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.02)',
        },
    },
    resellerRow: {
        backgroundColor: 'rgba(0,0,0,0.02)',
    },
    nameHeader: {
        width: 360,
        maxWidth: 360,
        minWidth: 360,
    },
    completeCell: {
        width: 121,
        minWidth: 121,
        paddingLeft: 0,
        paddingRight: 0,
    },
    starterCell: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    starter: {
        paddingLeft: 55,
        width: 171,
        minWidth: 171,
    },
    moreHeader: {
        width: 100,
        maxWidth: 100,
        minWidth: 100,
    },
    center: {
        width: 'fit-content',
        margin: 'auto',
    },
    logoLicenses: {
        width: 292,
        maxWidth: 292,
        minWidth: 292,
        height: 38,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        backgroundColor: 'rgba(101,78,163,0.04)',
        marginLeft: 696,
        paddingTop: 8,
        borderRadius: '8px 8px 0 0',
    },
    customerLogoLicenses: {
        marginLeft: 648,
    },
    innerLogoLicenses: {
        display: 'flex',
        alignItems: 'center',
    },
    licensesTitle: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontSize: 10,
        letterSpacing: 1.43,
        lineHeight: '24px',
        marginTop: 2,
    },
    logo: {
        marginRight: 8,
        height: 14.53,
    },
    status: {
        paddingLeft: 24,
    },
});

type Props = {
    accounts: Account[];
    completeOnly: boolean;
    reseller?: boolean;
    partner?: boolean;
};

const AccountTable: FC<Props> = ({ accounts, completeOnly, reseller, partner }) => {
    const classes = useStyles();
    const {
        setAccount,
        strings,
        accountsTablePage,
        setAccountsTablePage,
        accountsRowsPerPage,
        setAccountsRowsPerPage,
        orderByAccounts,
        setOrderByAccounts,
        orderAccounts,
        setOrderAccounts,
    } = useStore();
    const history = useHistory();
    const updateBreadcrumbs = useUpdateBreadcrumbs();

    const onSortRequest = (property: OrderByAccounts) => (event: MouseEvent) => {
        const isAsc = orderByAccounts === property && orderAccounts === 'asc';
        setOrderAccounts(isAsc ? 'desc' : 'asc');
        setOrderByAccounts(property);
    };

    const onChangePage = (event: unknown, page: number) => {
        setAccountsTablePage(page);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    };

    useEffect(() => {
        return () => {
            try {
                setAccountsTablePage(0);
            } catch (e) {
                console.error(e);
            }
        };
    }, [setAccountsTablePage]);

    const onRowsPerPageChange = (event: any) => {
        if (accounts.length < event.target.value && accountsTablePage !== 0) {
            setAccountsTablePage(0);
        }
        setAccountsRowsPerPage(event.target.value);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1000);
    };

    const openAccountDetail = (account: Account) => (event: MouseEvent) => {
        updateBreadcrumbs(account);
        setAccount(account);
        history.push(`/account-detail/${account.id}`);
    };

    return (
        <>
            {!completeOnly && (
                <div className={clsx(classes.logoLicenses, reseller ? classes.customerLogoLicenses : undefined)}>
                    <div className={classes.innerLogoLicenses}>
                        <img alt="logo" src={`${LOGOS_PATH}logoInlinePurple.svg`} className={classes.logo} />
                        <div className={classes.licensesTitle}>{strings?.licenses}</div>
                    </div>
                </div>
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={clsx(classes.headerCell, classes.status)}>
                            <TableSortLabel
                                active={orderByAccounts === 'status'}
                                direction={orderAccounts}
                                property="status"
                                onClick={onSortRequest}
                            >
                                {strings?.status}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={clsx(classes.headerCell, classes.nameHeader)}>
                            <TableSortLabel
                                active={orderByAccounts === 'name'}
                                property="name"
                                direction={orderAccounts}
                                onClick={onSortRequest}
                            >
                                {strings?.companyName}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.headerCell}>
                            <TableSortLabel
                                active={orderByAccounts === 'o365Licenses'}
                                property="o365Licenses"
                                direction={orderAccounts}
                                onClick={onSortRequest}
                            >
                                {strings?.o365Licenses}
                            </TableSortLabel>
                        </TableCell>
                        {completeOnly ? (
                            <TableCell className={classes.headerCell}>
                                <TableSortLabel
                                    active={orderByAccounts === 'complete'}
                                    property="complete"
                                    direction={orderAccounts}
                                    onClick={onSortRequest}
                                >
                                    <div className={classes.innerLogoLicenses}>
                                        <img
                                            alt="logo"
                                            src={`${LOGOS_PATH}logoInlinePurple.svg`}
                                            className={classes.logo}
                                        />
                                        <div className={classes.licensesTitle}>{strings?.licenses}</div>
                                    </div>
                                </TableSortLabel>
                            </TableCell>
                        ) : (
                            <>
                                <TableCell
                                    className={clsx(
                                        classes.headerCell,
                                        classes.licensesCell,
                                        classes.starterCell,
                                        classes.starter
                                    )}
                                >
                                    <TableSortLabel
                                        active={orderByAccounts === 'starter'}
                                        property="starter"
                                        direction={orderAccounts}
                                        onClick={onSortRequest}
                                    >
                                        {strings?.starter}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    className={clsx(classes.headerCell, classes.licensesCell, classes.completeCell)}
                                >
                                    <TableSortLabel
                                        active={orderByAccounts === 'complete'}
                                        property="complete"
                                        direction={orderAccounts}
                                        onClick={onSortRequest}
                                    >
                                        {strings?.complete}
                                    </TableSortLabel>
                                </TableCell>
                            </>
                        )}
                        <TableCell className={classes.moreHeader}>{''}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sort(trimCompanyNames(accounts), getSorting(orderAccounts, orderByAccounts))
                        .slice(
                            accountsTablePage * accountsRowsPerPage,
                            accountsTablePage * accountsRowsPerPage + accountsRowsPerPage
                        )
                        .map((account) => {
                            const status =
                                account.type === AccountType.Reseller ? (
                                    <Tooltip title={strings?.reseller ?? ''} style={{ width: 24, height: 24 }}>
                                        <ResellerIcon />
                                    </Tooltip>
                                ) : account.status === AccountStatus.New ? (
                                    <Tooltip title={strings?.notConnected ?? ''}>
                                        <div className={classes.statusNew} />
                                    </Tooltip>
                                ) : account.status === AccountStatus.Initiated ? (
                                    <Tooltip title={strings?.registrationStarter ?? ''}>
                                        <div className={classes.statusInitiated} />
                                    </Tooltip>
                                ) : account.status === AccountStatus.Good ? (
                                    <Tooltip title={strings?.connected ?? ''}>
                                        <div className={classes.statusConnected} />
                                    </Tooltip>
                                ) : account.status === AccountStatus.WithinTwoWeeks ? (
                                    <Tooltip title={strings?.withinTwoWeeks ?? ''}>
                                        <div className={classes.statusWithinTwoWeeks} />
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={strings?.needsAttention ?? ''}>
                                        <div className={classes.statusNeedsAttention} />
                                    </Tooltip>
                                );
                            const isStarterCustomer =
                                account.type === AccountType.Customer &&
                                [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);
                            return (
                                <TableRow
                                    className={clsx(
                                        classes.row,
                                        account.type === AccountType.Reseller && classes.resellerRow
                                    )}
                                    key={account.id}
                                >
                                    <TableCell className={classes.status} onClick={openAccountDetail(account)}>
                                        <div className={classes.statusContainer}>{status}</div>
                                    </TableCell>
                                    <TableCell className={classes.customerName} onClick={openAccountDetail(account)}>
                                        {account.name}
                                    </TableCell>
                                    <TableCell className={classes.count} onClick={openAccountDetail(account)}>
                                        {account.o365Licenses}
                                    </TableCell>
                                    {!completeOnly && (
                                        <TableCell
                                            onClick={openAccountDetail(account)}
                                            className={clsx(classes.count, classes.licensesCell, classes.starter)}
                                        >
                                            {isStarterCustomer || account.type === AccountType.Reseller
                                                ? account.starterLicensesUsed
                                                : '--'}
                                        </TableCell>
                                    )}
                                    <TableCell
                                        onClick={openAccountDetail(account)}
                                        className={clsx(
                                            classes.count,
                                            classes.completeCell,
                                            !completeOnly && classes.licensesCell
                                        )}
                                    >
                                        {account.completeLicensesUsed}
                                    </TableCell>
                                    <TableCell>
                                        {account.type !== AccountType.Reseller && (
                                            <div className={classes.center}>
                                                <AccountsMoreButton account={account} />
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[20, 30, 40]}
                count={accounts.length}
                rowsPerPage={accountsRowsPerPage}
                page={accountsTablePage}
                onChangePage={onChangePage}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </>
    );
};

export default AccountTable;
