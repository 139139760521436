import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from '../Page';
import Header from '../header/Header';
import PageContent from '../PageContent';
import AccountTable from './AccountTable';
import { useStore } from '../../store/StoreProvider';
import { Colors, Loading } from 'common';
import { useApiService } from '../../services/ApiServiceProvider';
import AddPartner from './AddPartner';
import AddPartnerUser from './AddPartnerUser';

const useStyles = makeStyles({
    partners: {
        marginTop: 16,
    },
    titleButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 24,
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
    },
    card: {
        width: 1088,
        borderRadius: 4,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        paddingTop: 24,
    },
});

const Partners: FC = () => {
    const classes = useStyles();
    const { user, partners, setPartners } = useStore();
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        const getPartners = async () => {
            const response = await ApiService.getPartners();
            if (!response) {
                return;
            }
            setPartners(response.data);
        };
        if (user && !retrievedData) {
            setRetrievedData(true);
            getPartners();
        }
    }, [retrievedData, ApiService, setPartners, user]);

    if (!user || !partners) {
        return (
            <Page>
                <Header />
                <PageContent>
                    <Loading />
                </PageContent>
            </Page>
        );
    }

    let key = 'partners';
    if (partners.length > 0) {
        key += partners[0].id + partners[partners.length - 1].id;
    }

    return (
        <Page>
            <Header />
            <PageContent>
                <div className={classes.partners}>
                    <div className={classes.titleButton}>
                        <div className={classes.title}>Partners</div>
                    </div>
                    <AddPartner />
                    <AddPartnerUser />
                    <div className={classes.card}>
                        <AccountTable key={key} accounts={partners} completeOnly={false} partner={true} />
                    </div>
                </div>
            </PageContent>
        </Page>
    );
};

export default Partners;
