import { Dialog, DialogActions, DialogContent, useTheme, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { FC } from 'react';
import { useStore } from '../../store/StoreProvider';
import { Button } from 'common';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
            width: 720,
        },
        overflow: 'hidden',
    },
    video: {
        width: '100%',
    },
}));

interface Props {
    open: boolean;
    onClose: () => void;
    src: string;
}

const VideoDialog: FC<Props> = ({ open, onClose, src }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { strings } = useStore();
    const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullScreen={isSmallDown}>
            <DialogContent className={classes.content}>
                <video controls={true} src={src} autoPlay={true} className={classes.video}>
                    Your browser does not support the
                    <code>video</code> element.
                </video>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{strings?.close}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default VideoDialog;
