import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccountStatus, AccountType, Customer, LicenseClass } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import clsx from 'clsx';
import UsageBar from './UsageBar';
import commaNumber from 'comma-number';
import { Colors } from 'common';
import { getDiffOrZero } from '../../utils/helper';

const useStyles = makeStyles({
    licenseUsage: {},
    title: {
        marginLeft: 24,
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.8)',
        marginBottom: 12,
    },
    content: {
        padding: 24,
        paddingTop: 16,
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 2,
        color: 'rgba(0,0,0,0.88)',
        marginRight: 8,
    },
    line: {
        height: 2,
        width: 310,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    subtitleLine: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    users: {
        width: 500,
        height: 48,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 2,
    },
    noNuliaUsers: {
        backgroundColor: '#f5f5f5',
        borderColor: 'rgba(0, 0, 0, 0.08)',
    },
    completeUsers: {
        backgroundColor: '#f5f5f5',
        borderColor: 'rgba(101, 78, 163, 0.08)',
    },
    starterUsers: {
        backgroundColor: '#e8e8f5',
        borderColor: 'rgba(101, 78, 163, 0.16)',
    },
    usageBar: {},
    usageBarCount: {
        display: 'flex',
    },
    largeCountContainer: {
        marginLeft: 16,
    },
    count: {
        color: 'rgba(0,0,0,0.8)',
    },
    largeCount: {
        marginTop: 4,
        width: 152,
        fontFamily: 'Ubuntu',
        fontSize: 32,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        textAlign: 'center',
    },
    largeCountLabel: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
        marginTop: 12,
        textAlign: 'center',
    },
    key: {
        marginTop: 24,
    },
    keyItem: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    keyBoxLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    keyNumber: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    keyDarkBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.08)',
        backgroundColor: '#b2a6d1',
        marginRight: 16,
    },
    keyLightBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(101, 78, 163, 0.16)',
        backgroundColor: '#e8e8f5',
        marginRight: 16,
    },
    keyGreyBox: {
        height: 24,
        width: 24,
        borderRadius: 2,
        border: 'solid 1px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#f5f5f5',
        marginRight: 16,
    },
    keyLabel: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    notConnected: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.4)',
        textAlign: 'center',
        marginTop: 16,
    },
    button: {
        height: 36,
        minHeight: 36,
        maxHeight: 36,
        borderRadius: 4,
        fontSize: 10,
        letterSpacing: 1.25,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.4)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        border: 0,
        outline: 0,
        padding: 0,
        '&:hover': {
            color: Colors.textSecondary,
        },
        '&:focus': {
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface Props {
    account: Customer;
    annual: boolean;
}

const LicenseUsage: FC<Props> = ({ account, annual }) => {
    const classes = useStyles();
    const { user, strings } = useStore();

    if (!user) {
        return null;
    }

    const isCompleteCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Complete, LicenseClass.CompleteSync].includes(account.licenseClass);
    const isConnected =
        account.type === AccountType.Customer && ![AccountStatus.Initiated, AccountStatus.New].includes(account.status);

    return (
        <div className={classes.licenseUsage}>
            <div className={classes.title}>{!annual ? 'Usage' : 'Monthly Usage'}</div>
            <div className={classes.content}>
                <div className={classes.subtitleLine}>
                    <div className={classes.subtitle}>TOTAL OFFICE 365 USERS</div>
                    <div className={classes.line} />
                </div>
                <div className={classes.usageBarCount}>
                    <UsageBar
                        firstCount={account.completeLicensesUsed}
                        secondCount={
                            isCompleteCustomer
                                ? getDiffOrZero(account.o365Licenses, account.completeLicensesUsed)
                                : account.starterLicensesUsed
                                ? account.starterLicensesUsed
                                : 0
                        }
                        o365Count={account.o365Licenses}
                        isComplete={isCompleteCustomer}
                        isConnected={isConnected}
                        usageBarWidth={500}
                    />
                    <div className={classes.largeCountContainer}>
                        <div className={clsx(classes.largeCount, classes.count)}>
                            {commaNumber(account.completeLicensesAvailable)}
                        </div>
                        <div className={classes.largeCountLabel}>
                            {annual
                                ? 'Unused Month-Credits'
                                : isCompleteCustomer
                                ? strings?.availableNuliaLicenses
                                : strings?.availableCompleteLicenses}
                        </div>
                    </div>
                </div>
                <div className={classes.key}>
                    {!isConnected ? (
                        <div className={classes.notConnected}>{strings?.connectUsageData}</div>
                    ) : isCompleteCustomer ? (
                        <>
                            <div className={classes.keyItem}>
                                <div className={classes.keyBoxLabel}>
                                    <div className={classes.keyDarkBox} />
                                    <div className={classes.keyLabel}>{strings?.nuliaUsers}</div>
                                </div>
                                <div className={classes.keyNumber}>{commaNumber(account.completeLicensesUsed)}</div>
                            </div>
                            <div className={classes.keyItem}>
                                <div className={classes.keyBoxLabel}>
                                    <div className={classes.keyGreyBox} />
                                    <div className={classes.keyLabel}>{strings?.o365UserWithoutNulia}</div>
                                </div>
                                <div className={classes.keyNumber}>
                                    {commaNumber(getDiffOrZero(account.o365Licenses, account.completeLicensesUsed))}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={classes.keyItem}>
                                <div className={classes.keyBoxLabel}>
                                    <div className={classes.keyDarkBox} />
                                    <div className={classes.keyLabel}>{strings?.nuliaCompleteUsers}</div>
                                </div>
                                <div className={classes.keyNumber}>{commaNumber(account.completeLicensesUsed)}</div>
                            </div>
                            <div className={classes.keyItem}>
                                <div className={classes.keyBoxLabel}>
                                    <div className={classes.keyLightBox} />
                                    <div className={classes.keyLabel}>{strings?.nuliaStarterUsers}</div>
                                </div>
                                <div className={classes.keyNumber}>{commaNumber(account.starterLicensesUsed)}</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LicenseUsage;
