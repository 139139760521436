import { ChangeEvent, FC, useState } from 'react';
import { Dialog, Input } from 'common';
import { Account, AccountType, LicenseClass, LicenseType } from '../../types/types';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { getActivities, getValidLicenseInput } from '../../utils/helper';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
import LicenseSyncToggle from '../accounts/LicenseSyncToggle';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    addLicensesDialog: {},
    label: {
        color: 'rgba(0,0,0,0.88)',
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 1.5,
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    helpContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    account: Account;
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
}

const AddLicensesDialog: FC<Props> = ({ account, open, onAccept, onClose }) => {
    const classes = useStyles();
    const { user, activities, setActivities, strings } = useStore();
    const [complete, setComplete] = useState<string>('');
    const [completeError, setCompleteError] = useState<boolean>(false);
    const [licenseSync, setLicenseSync] = useState<boolean>(false);
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    if (!user) {
        return null;
    }

    const isCompleteCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Complete, LicenseClass.CompleteSync].includes(account.licenseClass);

    const onChangeLicenseSync = (newLicenseSync: boolean) => {
        setLicenseSync(newLicenseSync);
        if (!licenseSync) {
            setComplete(String(account.o365Licenses - account.completeLicensesUsed));
        } else {
            setComplete('');
        }
    };

    const onChangeComplete = (event: ChangeEvent<HTMLInputElement>) => {
        let validInput = getValidLicenseInput(event.target.value);
        event.target.value = validInput !== undefined ? validInput : '';
        setComplete(event.target.value);
        setCompleteError(false);
    };

    const addLicenses = async () => {
        let hasCompleteError = false;
        if (complete === '' || isNaN(Number(complete)) || (!isNaN(Number(complete)) && Number(complete) <= 0)) {
            hasCompleteError = true;
        }
        setCompleteError(hasCompleteError);
        if (isCompleteCustomer && hasCompleteError) {
            return;
        }
        const response = await ApiService.addLicenses(account.id, LicenseType.Complete, Number(complete));
        if (!response || (response && response.status < 0)) {
            setCompleteError(true);
        }
        setComplete('');
        onAccept();
        const resellerId = account.type === AccountType.Customer ? account.resellerId : account.parentId;
        if (resellerId) {
            await Promise.all([
                getAccountData(resellerId),
                getActivities(activities, resellerId, setActivities, ApiService),
            ]);
        }
    };

    const closeDialog = () => {
        onClose();
        setComplete('');
        setCompleteError(false);
    };

    // TODO add text here to strings

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            onAccept={addLicenses}
            title={isCompleteCustomer ? strings?.addLicenses : strings?.upgradeLicenses}
            acceptText={isCompleteCustomer ? strings?.addLicenses : strings?.upgradeLicenses}
            cancelText={strings?.cancel}
            acceptButtonWidth={171}
            width={384}
            content={
                <div className={classes.addLicensesDialog}>
                    {account.type === AccountType.Customer && (
                        <Box marginBottom={2}>
                            <LicenseSyncToggle account={account} onChange={onChangeLicenseSync} />
                        </Box>
                    )}
                    <div className={classes.label}>{strings?.addLicenseInputLabel}</div>
                    {!licenseSync ? (
                        <Input
                            autoFocus={true}
                            value={complete}
                            onChange={onChangeComplete}
                            style={{ width: '100%' }}
                            error={completeError}
                        />
                    ) : (
                        <>
                            <Typography variant="body2" style={{ marginBottom: 4, marginLeft: 4 }}>
                                {account.o365Licenses - account.completeLicensesUsed}
                            </Typography>
                            <Typography variant="caption">
                                Calculated based on tenant users without Complete Licenses
                            </Typography>
                        </>
                    )}
                </div>
            }
        />
    );
};

export default AddLicensesDialog;
