import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import MonthBox from './MonthBox';
import UsageBox from './UsageBox';
import { AnnualUsageState, RemainingUsageState } from '../../../types/types';
import RemainingBox from './RemainingBox';
import clsx from 'clsx';

const useStyles = makeStyles({
    usageBoxes: {
        marginBottom: 24,
    },
    textBox: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.8)',
        height: 24,
        minHeight: 24,
        maxHeight: 24,
        width: 80,
        minWidth: 80,
        maxWidth: 80,
        display: 'flex',
        alignItems: 'center',
    },
    blankBox: {
        height: 20,
        minHeight: 20,
        maxHeight: 20,
        width: 80,
        minWidth: 80,
        maxWidth: 80,
    },
    topRow: {
        height: 20,
        minHeight: 20,
        maxHeight: 20,
        display: 'flex',
        marginBottom: 8,
    },
    row: {
        height: 24,
        minHeight: 24,
        maxHeight: 24,
        display: 'flex',
    },
    middleRow: {
        marginBottom: 2,
    },
});

interface Props {}

const UsageBoxes: FC<Props> = () => {
    const classes = useStyles();
    return (
        <div className={classes.usageBoxes}>
            <div className={classes.topRow}>
                <div className={classes.blankBox} />
                <MonthBox month="Feb" current={false} inner={true} />
                <MonthBox month="Mar" current={false} inner={true} />
                <MonthBox month="Apr" current={false} inner={true} />
                <MonthBox month="May" current={false} inner={true} />
                <MonthBox month="Jun" current={false} inner={true} />
                <MonthBox month="Jul" current={false} inner={true} />
                <MonthBox month="Aug" current={false} inner={true} />
                <MonthBox month="Sep" current={false} inner={true} />
                <MonthBox month="Oct" current={true} inner={true} />
                <MonthBox month="Nov" current={false} inner={true} />
                <MonthBox month="Dec" current={false} inner={true} />
                <MonthBox month="Jan" current={false} inner={false} />
            </div>
            <div className={clsx(classes.row, classes.middleRow)}>
                <div className={classes.textBox}>Usage</div>
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.PastMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.CurrentMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.RemainingMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.RunOutMonth} usageNumber={250} inner={true} />
                <UsageBox annualUsageState={AnnualUsageState.NoCreditsMonth} usageNumber={250} inner={false} />
            </div>
            <div className={classes.row}>
                <div className={classes.textBox}>Remaining</div>
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={2500} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={2250} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={2000} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={1750} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={1500} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={1250} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={1000} inner={true} />
                <RemainingBox remainingUsageState={RemainingUsageState.PastMonth} remainingNumber={750} inner={true} />
                <RemainingBox
                    remainingUsageState={RemainingUsageState.CurrentMonth}
                    remainingNumber={500}
                    inner={true}
                />
                <RemainingBox
                    remainingUsageState={RemainingUsageState.FutureMonth}
                    remainingNumber={250}
                    inner={true}
                />
                <RemainingBox remainingUsageState={RemainingUsageState.FutureMonth} remainingNumber={0} inner={true} />
                <RemainingBox
                    remainingUsageState={RemainingUsageState.FutureMonth}
                    remainingNumber={-250}
                    inner={false}
                />
            </div>
        </div>
    );
};

export default UsageBoxes;
