import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties, FC, useState } from 'react';
import { Colors, Tooltip } from 'common';
import InfoIcon from '../icons/InfoIcon';

const useStyles = makeStyles({
    infoButton: {
        height: 16,
        width: 16,
        '&:hover': {
            color: Colors.textSecondary,
        },
        '&:focus': {
            color: Colors.textSecondary,
            outline: 0,
            backgroundColor: Colors.lightPurple,
        },
        '&:active': {
            color: Colors.purple,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface Props {
    title: string;
    style?: CSSProperties;
}

const HelpButton: FC<Props> = ({ title, style }) => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => {
        setMouseOver(false);
        setMouseDown(false);
    };
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);

    return (
        <Tooltip title={title} style={style}>
            <div
                className={classes.infoButton}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                <InfoIcon mouseOver={mouseOver} mouseDown={mouseDown} />
            </div>
        </Tooltip>
    );
};

export default HelpButton;
