import { FC } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Colors } from 'common';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
    searchInput: {
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        '&:focus': {
            borderBottom: '1px solid #654ea3',
            outline: 'none',
        },
        '&:hover': {
            borderBottom: '1px solid #654ea3',
        },
    },
    input: {
        height: 22.5,
        width: '100%',
        border: 0,
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        backgroundColor: 'transparent',
        color: Colors.textPrimary,
        caretColor: 'rgba(101,78,163,0.88)',
        paddingLeft: 4,
        paddingTop: 8,
        paddingBottom: 9.5,
        '&::placeholder': {
            color: Colors.textTertiary,
        },
    },
    focused: {
        backgroundColor: 'rgba(101,78,163,0.04)',
    },
    option: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 8,
        paddingRight: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
        '&[aria-selected="true"]': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
        '&[data-focus="true"]': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
    },
    paper: {
        paddingTop: 4,
        paddingBottom: 8,
        backgroundColor: Colors.white,
        borderRadius: 4,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        marginTop: 4,
    },
    endAdornment: {
        '&:focus': {
            backgroundColor: 'rgba(101,78,163,0.04)',
        },
    },
    error: {
        borderBottom: '1px solid #FE6C6C',
    },
    label: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: '20px',
        height: 20,
    },
});

interface Props {
    options: any[];
    onChange: (event: unknown, value: any) => void;
    value?: any;
    error: boolean;
    placeholder?: string;
    getOptionLabel?: (option: any) => string;
}

const SearchInput: FC<Props> = ({ options, onChange, value, error, placeholder, getOptionLabel }) => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.label}>{value ? placeholder : ''}</div>
            <Autocomplete
                onChange={onChange}
                options={options}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                    <TextField {...params} autoFocus={true} style={{ width: 336 }} placeholder={placeholder} />
                )}
                style={{ width: 336 }}
                classes={{
                    root: !error ? classes.searchInput : classes.error,
                    input: classes.input,
                    focused: classes.focused,
                    option: classes.option,
                    paper: classes.paper,
                    endAdornment: classes.endAdornment,
                }}
            />
        </>
    );
};

export default SearchInput;
