import { createContext, FC, useContext, useState } from 'react';
import { AccountType, CsvInvoiceType, EndpointCode, LicenseType, ResellerType, Resource } from '../types/types';
import {
    AccountInfoResponse,
    AccountSetupProgressResponse,
    AccountsResponse,
    ActivitiesResponse,
    AdministratorsResponse,
    EmailSettingsResponse,
    InvoicesResponse,
    NotesResponse,
    OpportunitiesResponse,
    ProjectedChargesResponse,
    ResponseBase,
    ResponseCode,
    SkusResponse,
    StarterOutcomesResponse,
    UserResponse,
} from '../types/responseTypes';
import config from '../partner-config.json';
import axios, { AxiosRequestConfig } from 'axios';
import { AuthService } from './AuthService';
import { useStore } from '../store/StoreProvider';
import { TokenExpiredDialog, logError } from 'common';
import { getMonth, getYear } from 'date-fns';

export interface IApiService {
    getUser: (oid: string) => Promise<UserResponse | undefined>;
    getAccounts: (resellerId: string) => Promise<AccountsResponse | undefined>;
    getAdministrators: (id: string, resellerId: string) => Promise<AdministratorsResponse | undefined>;
    addAdministrator: (id: string, name: string, email: string) => Promise<ResponseBase | undefined>;
    removeAdministrator: (id: string, name: string, email: string) => Promise<ResponseBase | undefined>;
    getActivities: (
        id: string,
        modelAccountType: AccountType,
        resellerId: string
    ) => Promise<ActivitiesResponse | undefined>;
    getNotes: (id: string) => Promise<NotesResponse | undefined>;
    updateNotes: (id: string, notes: string) => Promise<ResponseBase | undefined>;
    addCustomer: (
        resellerId: string,
        name: string,
        licenses: number,
        gdpr: boolean
    ) => Promise<ResponseBase | undefined>;
    addReseller: (parentId: string, name: string) => Promise<ResponseBase | undefined>;
    moveAccount: (
        accountId: string,
        accountParentId: string,
        modelAccountType: AccountType,
        resellerAccountId: string,
        resellerParentId?: string
    ) => Promise<ResponseBase | undefined>;
    getAllAccounts: () => Promise<AccountsResponse | undefined>;
    addLicenses: (
        customerId: string,
        licenseType: LicenseType,
        licenseCount: number
    ) => Promise<ResponseBase | undefined>;
    removeLicenses: (
        customerId: string,
        licenseType: LicenseType,
        licenseCount: number
    ) => Promise<ResponseBase | undefined>;
    sendContactEmail: (message: string) => void;
    sendConnectEmail: (email: string, customerId: string) => void;
    getResources: (starter: boolean) => Promise<Resource[] | undefined>;
    getStarterOutcomes: () => Promise<StarterOutcomesResponse | undefined>;
    setStarterOutcome: (customerId: string, outcomeId: number) => Promise<ResponseBase | undefined>;
    getAccountSetupProgress: (
        customerId: string,
        resellerId: string
    ) => Promise<AccountSetupProgressResponse | undefined>;
    getPartners: () => Promise<AccountsResponse | undefined>;
    getSkus: () => Promise<SkusResponse | undefined>;
    addPartner: (
        name: string,
        skipCustomerCreation: boolean,
        msTid: string,
        type: ResellerType
    ) => Promise<ResponseBase | undefined>;
    addPartnerUser: (resellerId: string, oid: string, name: string, email: string) => Promise<ResponseBase | undefined>;
    setLicenseSync: (customerId: string, enableLicenseSync: boolean) => Promise<ResponseBase | undefined>;
    getOpportunities: (customerId: string) => Promise<OpportunitiesResponse | undefined>;
    getInvoices: (resellerId: string) => Promise<InvoicesResponse | undefined>;
    getProjectedCharges: (id: string) => Promise<ProjectedChargesResponse | undefined>;
    getAccountInfo: (id: string) => Promise<AccountInfoResponse | undefined>;
    getCsvInvoice: (
        resellerId: string,
        invoiceNumber: string,
        year: number,
        month: number,
        invoiceType: CsvInvoiceType,
        noCsvFound: string
    ) => Promise<string | undefined>;
    updateCustomerName: (name: string, accountId: string) => Promise<ResponseBase | undefined>;
    updateResellerName: (name: string, accountId: string) => Promise<ResponseBase | undefined>;
    reportBug: (
        endpointCode?: number,
        errorCode?: number,
        statusCode?: number,
        page?: string
    ) => Promise<ResponseBase | undefined>;
    getEmailSettings: (customerId: string) => Promise<EmailSettingsResponse | undefined>;
    setEmailSettings: (customerId: string, disableOnboarding: boolean, disableWeekly: boolean) => Promise<undefined>;
}

export const ApiServiceContext = createContext<IApiService>({} as IApiService);

export const useApiService = () => useContext<IApiService>(ApiServiceContext);

const ApiServiceProvider: FC = ({ children }) => {
    const { user, setShowWarning, setWarningData, setOpenSnackbar, setSnackbarData } = useStore();
    const [openTokenExpiredDialog, setOpenTokenExpiredDialog] = useState<boolean>(false);

    const showErrorResponseWarning = (endpointCode: EndpointCode, statusCode: number) => {
        setWarningData({
            sid: user ? user.sid : '',
            endpointCode,
            responseCode: 0,
            statusCode: statusCode,
        });
        setShowWarning(true);
    };

    const showWarning = (
        endpointCode: EndpointCode,
        responseCode: ResponseCode,
        statusCode: number,
        warningTitle?: string,
        warningMessage?: string
    ) => {
        setWarningData({
            sid: user ? user.sid : '',
            endpointCode,
            responseCode,
            statusCode,
            warningTitle,
            warningMessage,
        });
        setShowWarning(true);
    };

    const get = async (
        url: string,
        code: EndpointCode,
        errorMessage: string,
        headers?: AxiosRequestConfig
    ): Promise<any | undefined> => {
        try {
            const response = await axios.get(url, headers);
            if (!response) {
                showErrorResponseWarning(code, 0);
                return;
            } else if (response && response.data.status < 0) {
                showWarning(code, response.data.code, response.status);
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - ${errorMessage}`));
            showErrorResponseWarning(code, error.response.status);
            return;
        }
    };

    const post = async (
        url: string,
        body: object,
        code: EndpointCode,
        errorMessage: string,
        headers?: AxiosRequestConfig
    ): Promise<any | undefined> => {
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                showErrorResponseWarning(code, 0);
                return;
            } else if (response && response.data.status < 0) {
                showWarning(code, response.data.code, response.status);
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - ${errorMessage}`));
            showErrorResponseWarning(code, error.response.status);
            return;
        }
    };

    const getUser = async (oid: string): Promise<UserResponse | undefined> => {
        try {
            const headers = await getHeadersBasic(oid, '');
            if (!headers) {
                return;
            }
            const response = await axios.get(`${config.apiUrl}/GetUserLogin`, headers);
            if (!response) {
                showErrorResponseWarning(EndpointCode.GetUserLogin, 0);
                return;
            } else if (response && response.data.status < 0) {
                return response.data;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to get user for oid ${oid}.`));
            showErrorResponseWarning(EndpointCode.GetUserLogin, error.response.status);
            return;
        }
    };

    const getAccounts = async (resellerId: string): Promise<AccountsResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetAccounts`;
        const body = {
            resellerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.GetAccounts, `Failed to get accounts for oid ${user.oid}.`, headers);
    };

    const getAdministrators = async (
        customerId: string,
        resellerId: string
    ): Promise<AdministratorsResponse | undefined> => {
        const url = `${config.apiUrl}/GetNuliaAdmins`;
        const body = {
            customerId,
            resellerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.GetNuliaAdmins,
            `Failed to get administrators for account ${customerId}.`,
            await getHeaders()
        );
    };

    const addAdministrator = async (id: string, name: string, email: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddNuliaAdmin`;
        const body = {
            customerId: id,
            name,
            email,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to add administrator for account ${id}.`));
            return;
        }
    };

    const removeAdministrator = async (id: string, name: string, email: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/RemoveNuliaAdmin`;
        const body = {
            customerId: id,
            name,
            email,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.RemoveNuliaAdmin,
            `Failed to remove administrator for account ${id}.`,
            headers
        );
    };

    const getActivities = async (
        id: string,
        modelAccountType: AccountType,
        resellerId: string
    ): Promise<ActivitiesResponse | undefined> => {
        const url = `${config.apiUrl}/GetActivities`;
        const body = {
            id,
            modelAccountType,
            resellerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.GetActivities,
            `Failed to get activities for account ${id}.`,
            headers
        );
    };

    const getNotes = async (id: string): Promise<NotesResponse | undefined> => {
        const url = `${config.apiUrl}/GetCustomerNotes`;
        const body = {
            customerId: id,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.GetCustomerNotes, `Failed to get notes for account ${id}.`, headers);
    };

    const updateNotes = async (id: string, notes: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/UpdateCustomerNotes`;
        const body = {
            customerId: id,
            notes,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.UpdateCustomerNotes,
            `Failed to update notes for account ${id}.`,
            headers
        );
    };

    const addCustomer = async (
        resellerId: string,
        name: string,
        licenses: number,
        gdpr: boolean
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddNewCustomer`;
        const body = {
            resellerId,
            name,
            licenses,
            gdpr,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to add customer for account ${resellerId}.`));
            return;
        }
    };

    const addReseller = async (parentId: string, name: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddNewReseller`;
        const body = {
            parentId,
            name,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to add reseller for account ${parentId}.`));
            return;
        }
    };

    const moveAccount = async (
        accountId: string,
        accountParentId: string,
        modelAccountType: AccountType,
        resellerAccountId: string,
        resellerParentId?: string
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AssociateExistingAccount`;
        const body = {
            accountId,
            accountParentId,
            modelAccountType,
            resellerAccountId,
            resellerParentId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.AssociateExistingAccount,
            `Failed to move account ${accountId} to ${resellerAccountId}.`,
            headers
        );
    };

    const getAllAccounts = async (): Promise<AccountsResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetAllAccounts`;
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await get(url, EndpointCode.GetAllAccounts, `Failed to get accounts for oid ${user.oid}.`, headers);
    };

    const addLicenses = async (
        customerId: string,
        licenseType: LicenseType,
        licenseCount: number
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddLicenses`;
        const body = {
            customerId,
            licenseType,
            licenseCount,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to add licenses for account ${customerId}.`));
            return;
        }
    };

    const removeLicenses = async (
        customerId: string,
        licenseType: LicenseType,
        licenseCount: number
    ): Promise<ResponseBase | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/RemoveLicenses`;
        const body = {
            customerId,
            licenseType,
            licenseCount,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to remove licenses for account ${customerId}.`));
            return;
        }
    };

    const sendContactEmail = async (message: string) => {
        const url = `${config.apiUrl}/SendContactEmail`;
        const body = {
            message,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            await axios.post(url, body, headers);
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to send contact email.`));
            return;
        }
    };

    const sendConnectEmail = async (email: string, customerId: string) => {
        const url = `${config.apiUrl}/SendConnectEmail`;
        const body = {
            email,
            customerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            await axios.post(url, body, headers);
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to send connect email.`));
            return;
        }
    };

    const getResources = async (starter: boolean): Promise<Resource[] | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetResources`;
        const body = {
            starter,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        const response = await post(
            url,
            body,
            EndpointCode.GetResources,
            `Failed to get resources for ${user.oid}.`,
            headers
        );
        if (response) {
            return response.data;
        }
        return;
    };

    const getStarterOutcomes = async (): Promise<StarterOutcomesResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetStarterOutcomes`;
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await get(
            url,
            EndpointCode.GetStarterOutcomes,
            `Failed to get starter outcomes for ${user.oid}.`,
            headers
        );
    };

    const setStarterOutcome = async (customerId: string, outcomeId: number): Promise<ResponseBase | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/SetStarterOutcome`;
        const body = {
            customerId,
            outcomeId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.SetStarterOutcome,
            `Failed to set starter outcome for ${user.oid}.`,
            headers
        );
    };

    const getAccountSetupProgress = async (
        customerId: string,
        resellerId: string
    ): Promise<AccountSetupProgressResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetCustomerStatus`;
        const body = {
            customerId,
            resellerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.GetCustomerStatus,
            `Failed to get customer status for ${user.oid}.`,
            headers
        );
    };

    const getOpportunities = async (customerId: string): Promise<OpportunitiesResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetOpportunities`;
        const body = {
            customerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.GetOpportunities,
            `Failed to get opportunities for ${user.oid}.`,
            headers
        );
    };

    const getPartners = async (): Promise<AccountsResponse | undefined> => {
        const url = `${config.apiUrl}/NuliaGetAllAccounts`;
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await get(url, EndpointCode.NuliaGetAllAccounts, 'Failed to get all accounts for admin.', headers);
    };

    const getSkus = async (): Promise<SkusResponse | undefined> => {
        const url = `${config.apiUrl}/GetAllSkus`;
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await get(url, EndpointCode.GetAllSkus, 'Failed to get all SKUs.', headers);
    };

    const addPartner = async (
        name: string,
        skipCustomerCreation: boolean,
        msTid: string,
        resellerType: ResellerType
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddRootReseller`;
        const body = {
            name,
            description: '',
            skipCustomerCreation,
            msTid,
            resellerType,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.AddRootReseller, 'Failed to add partner.', headers);
    };

    const addPartnerUser = async (
        resellerId: string,
        oid: string,
        name: string,
        email: string
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/AddResellerUser`;
        const body = {
            resellerId,
            oid,
            name,
            email,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                showErrorResponseWarning(EndpointCode.AddPartnerUser, 0);
                return;
            } else if (response && response.data.status < 0) {
                if (response.data.code === ResponseCode.RecordExists) {
                    setSnackbarData({
                        message: 'The user you are attempting to add already exists.',
                    });
                    setOpenSnackbar(true);
                    return;
                }
                showWarning(EndpointCode.AddResellerUser, response.data.code, response.status);
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to add partner user.`));
            showErrorResponseWarning(EndpointCode.AddPartnerUser, error.response.status);
            return;
        }
    };

    const setLicenseSync = async (
        customerId: string,
        enableLicenseSync: boolean
    ): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/SetCompleteLicenseSync`;
        const body = {
            customerId,
            enableLicenseSync,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.SetCompleteLicenseSync,
            `Failed to set license sync for customer ${customerId}`,
            headers
        );
    };

    const getInvoices = async (resellerId: string): Promise<InvoicesResponse | undefined> => {
        const url = `${config.apiUrl}/GetInvoices`;
        const body = {
            id: resellerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.GetInvoices, `Failed to get invoices for ${resellerId}.`, headers);
    };

    const getProjectedCharges = async (id: string): Promise<ProjectedChargesResponse | undefined> => {
        const url = `${config.apiUrl}/GetProjectedCharges`;
        const body = {
            id,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.GetProjectedCharges,
            `Failed to get projected charges for ${id}`,
            headers
        );
    };

    const getAccountInfo = async (id: string): Promise<AccountInfoResponse | undefined> => {
        const url = `${config.apiUrl}/GetAccountInfo`;
        const body = {
            id,
            year: getYear(new Date()),
            month: getMonth(new Date()) + 1,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.GetAccountInfo, `Failed to get account info for ${id}`, headers);
    };

    const getCsvInvoice = async (
        resellerId: string,
        invoiceNumber: string,
        year: number,
        month: number,
        invoiceType: CsvInvoiceType,
        noCsvFound: string
    ): Promise<string | undefined> => {
        const url = `${config.apiUrl}/GetCsvInvoice`;
        const body = {
            resellerId,
            invoiceNumber,
            year,
            month,
            invoiceType,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        try {
            const response = await axios.post(url, body, headers);
            if (!response) {
                showErrorResponseWarning(EndpointCode.GetCsvInvoice, 0);
                return;
            } else if (response && response.data.status < 0 && response.data.code === 409) {
                setSnackbarData({ message: noCsvFound });
                setOpenSnackbar(true);
                return;
            } else if (response && response.data.status < 0) {
                showWarning(EndpointCode.GetCsvInvoice, response.data.code, response.status);
                return;
            }
            return response.data;
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to get csv invoice ${resellerId} ${invoiceNumber}.`));
            showErrorResponseWarning(EndpointCode.GetCsvInvoice, error.response.status);
            return;
        }
    };

    const updateCustomerName = async (name: string, accountId: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/UpdateCustomerName`;
        const body = {
            name,
            accountId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.UpdateCustomerName,
            `Failed to update customer name for acccount ${accountId}`,
            headers
        );
    };

    const updateResellerName = async (name: string, accountId: string): Promise<ResponseBase | undefined> => {
        const url = `${config.apiUrl}/UpdateResellerName`;
        const body = {
            name,
            accountId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(
            url,
            body,
            EndpointCode.UpdateResellerName,
            `Failed to update reseller name for account ${accountId}`,
            headers
        );
    };

    const reportBug = async (
        endpointCode?: number,
        errorCode?: number,
        statusCode?: number,
        page?: string
    ): Promise<ResponseBase | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/ReportBug`;
        const body = {
            endpointCode,
            errorCode,
            statusCode,
            page,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.ReportBug, `Failed to send bug report for oid ${user.oid}.`, headers);
    };

    const getEmailSettings = async (customerId: string): Promise<EmailSettingsResponse | undefined> => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/GetEmailSettings`;
        const body = {
            customerId,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.ReportBug, `Failed to send bug report for oid ${user.oid}.`, headers);
    };

    const setEmailSettings = async (customerId: string, disableOnboarding: boolean, disableWeekly: boolean) => {
        if (!user) {
            return;
        }
        const url = `${config.apiUrl}/SetEmailSettings`;
        const body = {
            customerId,
            disableOnboarding,
            disableWeekly,
        };
        const headers = await getHeaders();
        if (!headers) {
            return;
        }
        return await post(url, body, EndpointCode.ReportBug, `Failed to send bug report for oid ${user.oid}.`, headers);
    };

    const getHeaders = async (): Promise<AxiosRequestConfig | undefined> => {
        if (!user) {
            return;
        }
        return await getHeadersBasic(user.oid, user.sid);
    };

    const getHeadersBasic = async (oid: string, sid: string): Promise<AxiosRequestConfig | undefined> => {
        try {
            const response = await AuthService.acquireToken();
            if (
                !response ||
                !response.idToken ||
                (response && response.idToken && AuthService.isTokenExpired(response.idToken))
            ) {
                setOpenTokenExpiredDialog(true);
                return;
            }
            return {
                headers: {
                    Authorization: `Bearer ${response.idToken.rawIdToken}`,
                    'NULIA-OID': oid,
                    'NULIA-SID': sid,
                },
            };
        } catch (error: any) {
            logError(Error(`${error.message} - Failed to get access token.`));
            return;
        }
    };

    const apiService = {
        getUser,
        getAccounts,
        getAdministrators,
        addAdministrator,
        removeAdministrator,
        getActivities,
        getNotes,
        updateNotes,
        addCustomer,
        addReseller,
        moveAccount,
        getAllAccounts,
        addLicenses,
        removeLicenses,
        sendContactEmail,
        sendConnectEmail,
        getResources,
        getStarterOutcomes,
        setStarterOutcome,
        getAccountSetupProgress,
        getOpportunities,
        getPartners,
        getSkus,
        addPartner,
        addPartnerUser,
        setLicenseSync,
        getInvoices,
        getProjectedCharges,
        getAccountInfo,
        getCsvInvoice,
        updateCustomerName,
        updateResellerName,
        reportBug,
        getEmailSettings,
        setEmailSettings,
    };

    return (
        <ApiServiceContext.Provider value={apiService}>
            {children}
            <TokenExpiredDialog open={openTokenExpiredDialog} setOpen={setOpenTokenExpiredDialog} />
        </ApiServiceContext.Provider>
    );
};

export default ApiServiceProvider;
