import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { AnnualUsageState } from '../../../types/types';
import clsx from 'clsx';

const useStyles = makeStyles({
    usageBox: {
        width: 48,
        minWidth: 48,
        maxWidth: 48,
        height: 24,
        minHeight: 24,
        maxHeight: 24,
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 'normal',
        lineHeight: 1.6,
        letterSpacing: 0.42,
        color: 'rgba(0, 0, 0, 0.8)',
    },
    pastMonth: {
        backgroundColor: 'rgba(176, 203, 62, 0.72)',
    },
    currentMonth: {
        backgroundColor: '#b0cb3e',
        border: 'solid 1px rgba(0, 0, 0, 0.16)',
    },
    remainingMonth: {
        backgroundColor: 'rgba(176, 203, 62, 0.24)',
        color: 'rgba(0, 0, 0, 0.64)',
    },
    runOutMonth: {
        background: 'linear-gradient(25deg, rgba(176, 203, 62, 0.24) 50%, rgba(0, 0, 0, 0.12) 50%)',
        color: 'rgba(0, 0, 0, 0.64)',
    },
    noCreditsMonth: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.64)',
    },
    inner: {
        marginRight: 1,
    },
});

interface Props {
    annualUsageState: AnnualUsageState;
    usageNumber: number;
    inner: boolean;
}

const UsageBox: FC<Props> = ({ annualUsageState, usageNumber, inner }) => {
    const classes = useStyles();
    let boxClass = '';
    switch (annualUsageState) {
        case AnnualUsageState.PastMonth:
            boxClass = classes.pastMonth;
            break;
        case AnnualUsageState.CurrentMonth:
            boxClass = classes.currentMonth;
            break;
        case AnnualUsageState.RemainingMonth:
            boxClass = classes.remainingMonth;
            break;
        case AnnualUsageState.RunOutMonth:
            boxClass = classes.runOutMonth;
            break;
        case AnnualUsageState.NoCreditsMonth:
            boxClass = classes.noCreditsMonth;
            break;
    }
    return <div className={clsx(classes.usageBox, boxClass, inner && classes.inner)}>{usageNumber}</div>;
};

export default UsageBox;
