import { FC, useState, MouseEvent } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Colors, ChevronDownIcon, ChevronUpIcon, MenuTextButton } from 'common';
import Preferences from '../header/Preferences';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    menuButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        marginLeft: 8,
    },
    popover: {
        width: 225,
        backgroundColor: Colors.white,
        padding: 16,
        borderRadius: 8,
        boxShadow: '0 12.5px 128px 4px rgba(0,0,0,0.08), 0 24px 32px 2px rgba(0,0,0,0.08)',
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        width: 'fit-content',
        height: 64,
        color: Colors.textTertiary,
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 1,
        lineHeight: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: '2px solid transparent',
        borderBottom: '2px solid transparent',
        '&:hover': {
            color: Colors.textPrimary,
        },
        '&:focus': {
            color: Colors.textPrimary,
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
    },
});

const MenuButton: FC = () => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [openPreferences, setOpenPreferences] = useState<boolean>(false);
    const { strings } = useStore();

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => {
        setMouseOver(false);
        setMouseDown(false);
    };
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);
    const toggleOpenPopover = () => setOpenPopover(!openPopover);
    const onOpenPopover = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget);
        toggleOpenPopover();
    };
    const toggleOpenPreferences = () => {
        setOpenPopover(false);
        setOpenPreferences(!openPreferences);
    };
    const logout = () => {
        sessionStorage.clear();
        document.location.reload();
    };

    return (
        <>
            <div
                onClick={onOpenPopover}
                className={classes.menuButton}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >
                {openPopover ? (
                    <ChevronUpIcon
                        color={
                            mouseOver && !mouseDown
                                ? Colors.textPrimary
                                : mouseDown
                                ? Colors.purple
                                : Colors.iconDefault
                        }
                        small={true}
                    />
                ) : (
                    <ChevronDownIcon
                        color={
                            mouseOver && !mouseDown
                                ? Colors.textPrimary
                                : mouseDown
                                ? Colors.purple
                                : Colors.iconDefault
                        }
                        small={true}
                    />
                )}
            </div>
            <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={toggleOpenPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.popover,
                }}
            >
                <MenuTextButton onClick={toggleOpenPreferences} style={{ width: '100%', marginBottom: 16 }}>
                    {strings?.viewPreferences}
                </MenuTextButton>
                <MenuTextButton onClick={logout} style={{ width: '100%' }}>
                    {strings?.logout}
                </MenuTextButton>
            </Popover>
            <Preferences open={openPreferences} onClose={toggleOpenPreferences} />
        </>
    );
};

export default MenuButton;
