import { useApiService } from '../services/ApiServiceProvider';
import { Account, CsvInvoiceType } from '../types/types';
import { useStore } from '../store/StoreProvider';
import { getStatusOrderBy, updateBreadcrumbs } from './helper';

export const useGetAccountData = () => {
    const ApiService = useApiService();
    const { accounts, user, setAccounts, account, setAccount, setAccountInfo } = useStore();

    return async (resellerId: string) => {
        if (!user) {
            return;
        }
        const response = await ApiService.getAccounts(resellerId);
        if (!response) {
            return;
        }
        const newAccounts = new Map(accounts);
        response.data.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            } else if (a.name > b.name) {
                return 1;
            } else {
                return 0;
            }
        });
        response.data.sort((a, b) => {
            const aOrderBy = getStatusOrderBy(a);
            const bOrderBy = getStatusOrderBy(b);
            if (aOrderBy < bOrderBy) {
                return -1;
            } else if (aOrderBy > bOrderBy) {
                return 1;
            } else {
                return 0;
            }
        });
        newAccounts.set(resellerId, response.data);
        setAccounts(newAccounts);
        if (account) {
            newAccounts.forEach((as) => {
                const newAccount = as.find((a) => a.id === account.id);
                if (newAccount) {
                    setAccount(newAccount);
                }
            });
        }
        const accountInfoResponse = await ApiService.getAccountInfo(user?.resellerId);
        if (!accountInfoResponse) {
            return;
        }
        setAccountInfo(accountInfoResponse.data);
    };
};

export const useUpdateBreadcrumbs = () => {
    const { breadcrumbs, setBreadcrumbs } = useStore();
    return (account: Account) => {
        const newBreadcrumbs = updateBreadcrumbs(account, breadcrumbs);
        setBreadcrumbs(newBreadcrumbs);
    };
};

export const useDownloadCsv = () => {
    const ApiService = useApiService();
    const { strings } = useStore();
    return (resellerId: string, invoiceNumber: string, year: number, month: number, invoiceType: CsvInvoiceType) =>
        async () => {
            const typeToFileName = new Map([
                [CsvInvoiceType.CustomerList, `Customer_List_Invoice_${invoiceNumber}`],
                [CsvInvoiceType.DirectAccounts, `By_Direct_Accounts_Invoice_${invoiceNumber}`],
                [CsvInvoiceType.Summary, `By_Customer_All_Invoice_${invoiceNumber}`],
            ]);
            const response = await ApiService.getCsvInvoice(
                resellerId,
                invoiceNumber,
                year,
                month,
                invoiceType,
                strings?.noCsvFound ?? ''
            );
            if (!response) {
                return;
            }
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response]));
            link.setAttribute('download', `${typeToFileName.get(invoiceType)}.csv`);
            document.body.appendChild(link);
            link.click();
            if (!link.parentNode) {
                return;
            }
            link.parentNode.removeChild(link);
        };
};
