import { FC } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip } from '@material-ui/core';
import InfoIcon from './icons/InfoIcon';
import commaNumber from 'comma-number';
import clsx from 'clsx';
import { LicenseUsage, ProjectedCharges } from '../types/types';
import { format, setDate } from 'date-fns';
import { useStore } from '../store/StoreProvider';

const useStyles = makeStyles({
    projectedChargesTable: {},
    text: {
        height: 16,
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
    totalLine: {
        height: 4,
        backgroundColor: '#d6d6d6',
    },
    totalCellText: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.88)',
    },
    quantity: {
        display: 'flex',
        alignItems: 'center',
        height: 17,
        lineHeight: 'normal',
    },
    padded: {
        paddingLeft: 24,
    },
});

interface Props {
    padded?: boolean;
    projectedCharges: ProjectedCharges;
}

const ProjectedChargesTable: FC<Props> = ({ padded, projectedCharges }) => {
    const classes = useStyles();
    const { user } = useStore();

    const BodyCell: FC<{ first?: boolean }> = ({ children, first }) => (
        <TableCell className={first ? classes.padded : undefined} style={{ borderBottom: 'none' }}>
            {children}
        </TableCell>
    );

    const BodyCellText: FC = ({ children }) => (
        <Typography variant="body2" component="div" style={{ color: 'rgba(0,0,0,0.88)' }}>
            {children}
        </Typography>
    );

    const TotalCellText: FC = ({ children }) => <div className={classes.totalCellText}>{children}</div>;

    const LicenseUsageCharges: FC<{ usage: LicenseUsage }> = ({ usage }) => (
        <TableRow>
            <BodyCell first={padded}>
                <BodyCellText>{usage.displayName}</BodyCellText>
            </BodyCell>
            <BodyCell>
                <BodyCellText>{commaNumber(usage.quantity)}</BodyCellText>
            </BodyCell>
            <BodyCell>
                <BodyCellText>{`${user?.currencySymbol}${usage.costPerUnit}`}</BodyCellText>
            </BodyCell>
            <BodyCell>
                <BodyCellText>{`(${commaNumber(usage.preBuysApplied)})`}</BodyCellText>
            </BodyCell>
            <BodyCell>
                <BodyCellText>{`${user?.currencySymbol}${usage.total}`}</BodyCellText>
            </BodyCell>
        </TableRow>
    );

    return (
        <div className={classes.projectedChargesTable}>
            <div className={classes.title}>
                <Typography variant="h6" className={padded ? classes.padded : undefined}>
                    Projected Charges
                </Typography>
                <Typography variant="body2" style={{ color: 'rgba(0,0,0,0.4)', marginLeft: 8 }}>
                    {`${format(setDate(new Date(), 1), 'PP')} - Current`}
                </Typography>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={padded ? classes.padded : undefined}>
                            <Typography variant="caption">SKU Description</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption" className={classes.quantity}>
                                Quantity
                                <Tooltip
                                    title="Equal to month-credits purchased."
                                    style={{ height: 16, marginLeft: 2 }}
                                >
                                    <InfoIcon />
                                </Tooltip>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">Cost per Unit</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">Pre-Buys Applied</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="caption">Total</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projectedCharges.directCompleteInternal.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directCompleteInternal} />
                    )}
                    {projectedCharges.directCompleteMonthToMonth.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directCompleteMonthToMonth} />
                    )}
                    {projectedCharges.directCompleteNotForResale.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directCompleteNotForResale} />
                    )}
                    {projectedCharges.directCompleteTerm.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directCompleteTerm} />
                    )}
                    {projectedCharges.directStarterMonthToMonth.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directStarterMonthToMonth} />
                    )}
                    {projectedCharges.indirectCompleteInternal.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectCompleteInternal} />
                    )}
                    {projectedCharges.indirectCompleteMonthToMonth.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectCompleteMonthToMonth} />
                    )}
                    {projectedCharges.indirectCompleteNotForResale.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectCompleteNotForResale} />
                    )}
                    {projectedCharges.indirectCompleteTerm.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectCompleteTerm} />
                    )}
                    {projectedCharges.indirectStarterMonthToMonth.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectStarterMonthToMonth} />
                    )}
                    {projectedCharges.directCompleteLegacy.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.directCompleteLegacy} />
                    )}
                    {projectedCharges.indirectCompleteLegacy.quantity > 0 && (
                        <LicenseUsageCharges usage={projectedCharges.indirectCompleteLegacy} />
                    )}
                    <TableRow>
                        <TableCell className={clsx(classes.totalLine, padded && classes.padded)} />
                        <TableCell className={classes.totalLine} />
                        <TableCell className={classes.totalLine} />
                        <TableCell className={classes.totalLine} />
                        <TableCell className={classes.totalLine} />
                    </TableRow>
                    <TableRow>
                        <BodyCell first={padded}>
                            <TotalCellText>TOTAL</TotalCellText>
                        </BodyCell>
                        <BodyCell>
                            <TotalCellText>{commaNumber(projectedCharges.totalQuantity)}</TotalCellText>
                        </BodyCell>
                        <BodyCell>
                            <BodyCellText>--</BodyCellText>
                        </BodyCell>
                        <BodyCell>
                            <TotalCellText>{`(${commaNumber(projectedCharges.totalPreBuysApplied)})`}</TotalCellText>
                        </BodyCell>
                        <BodyCell>
                            <TotalCellText>{`${user?.currencySymbol}${projectedCharges.totalBill}`}</TotalCellText>
                        </BodyCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default ProjectedChargesTable;
