import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors, Dialog } from 'common';
import { useStore } from '../store/StoreProvider';
import { useApiService } from '../services/ApiServiceProvider';

const useStyles = makeStyles({
    message: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
    },
    error: {
        borderTop: '1px solid rgba(0,0,0,0.08)',
        marginTop: 24,
        paddingTop: 8,
    },
    errorText: {
        color: Colors.textTertiary,
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.25,
        lineHeight: '14px',
    },
});

const ApocalypseWarning: FC = () => {
    const classes = useStyles();
    const { showWarning, warningData, strings } = useStore();
    const ApiService = useApiService();

    const onClose = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    const reportBug = async () => {
        await ApiService.reportBug(
            warningData?.endpointCode,
            warningData?.responseCode,
            warningData?.statusCode,
            window.location.pathname
        );
        onClose();
    };

    return (
        <Dialog
            open={showWarning}
            onClose={onClose}
            onAccept={reportBug}
            title={warningData && warningData.warningTitle ? warningData.warningTitle : strings?.apocalypseTitle}
            content={
                <div>
                    <div className={classes.message}>
                        {warningData && warningData.warningMessage
                            ? warningData.warningMessage
                            : strings?.apocalypseText}
                    </div>
                    {warningData && (
                        <div className={classes.error}>
                            <div className={classes.errorText}>Error Code</div>
                            <div className={classes.errorText}>{warningData.sid}</div>
                            <div className={classes.errorText}>
                                c{warningData.responseCode + 100000}-e{warningData.endpointCode + 100000}-s
                                {warningData.statusCode + 100000}
                            </div>
                        </div>
                    )}
                </div>
            }
            cancelText={strings?.okay}
            acceptText={strings?.report}
        />
    );
};

export default ApocalypseWarning;
