import { makeStyles } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles({
    pageContent: {
        height: '100vh',
        paddingTop: 80,
        paddingLeft: 48,
        paddingRight: 48,
    },
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 1088,
        paddingBottom: 24,
    },
});

const PageContent: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.pageContent}>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default PageContent;
