import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors, Dialog } from 'common';
import ProjectedChargesTable from '../ProjectedChargesTable';
import { useApiService } from '../../services/ApiServiceProvider';
import { ProjectedCharges as IProjectedCharges } from '../../types/types';

const useStyles = makeStyles({
    button: {
        height: 36,
        minHeight: 36,
        maxHeight: 36,
        borderRadius: 4,
        fontSize: 12,
        letterSpacing: 1,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.64)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        border: 0,
        outline: 0,
        padding: 0,
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        '&:focus': {
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogContent: {
        paddingBottom: 16,
    },
});

interface Props {
    resellerId: string;
}

const ProjectedChargesButton: FC<Props> = ({ resellerId }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [projectedCharges] = useState<IProjectedCharges | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        const getData = async () => {
            // TODO get this data from the account info endpoint
            // const response = await ApiService.getProjectedCharges(resellerId)
            // if (!response) {
            //     return
            // }
            // setProjectedCharges(response.data)
        };
        if (!retrievedData) {
            setRetrievedData(true);
            getData();
        }
    }, [ApiService, retrievedData, resellerId]);

    const onOpen = () => setOpenDialog(true);
    const onClose = () => setOpenDialog(false);

    if (!projectedCharges) {
        return null;
    }

    return (
        <>
            <button onClick={onOpen} className={classes.button}>
                View Projected Billing Charges
            </button>
            <Dialog
                width={715}
                open={openDialog}
                onClose={onClose}
                content={
                    <div className={classes.dialogContent}>
                        <ProjectedChargesTable projectedCharges={projectedCharges} />
                    </div>
                }
            />
        </>
    );
};

export default ProjectedChargesButton;
