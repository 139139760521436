import * as Sentry from '@sentry/browser';
import { Component, Context, ContextType } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Store, StoreContext } from '../store/StoreProvider';
import ApocalypseWarning from './ApocalypseWarning';

type Props = RouteComponentProps;

interface State {
    showFallback: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    static contextType: Context<Store> = StoreContext;

    static getDerivedStateFromError() {
        return { showFallback: true };
    }

    context!: ContextType<typeof StoreContext>;

    state: Readonly<State> = {
        showFallback: false,
    };

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.showFallback) {
            this.context.setShowWarning(true);
            return <ApocalypseWarning />;
        }
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
