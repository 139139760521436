import { FC, useEffect, useState } from 'react';
import { Collapse, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { Account, AccountType } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import { Colors, ChevronDownIcon, ChevronUpIcon } from 'common';
import clsx from 'clsx';
import { isAfter, isBefore } from 'date-fns';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    activity: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    resellerSection: {
        width: 1088,
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    tableHead: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: '20px',
    },
    tableBody: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
    },
    date: {
        paddingLeft: 0,
        width: 150,
        maxWidth: 150,
        minWidth: 150,
    },
    time: {
        width: 150,
        maxWidth: 150,
        minWidth: 150,
    },
});

interface Props {
    account: Account;
}

const Activity: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const { user, setShowWarning, activities, setActivities, setWarningData, strings } = useStore();
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [closed, setClosed] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        const getActivities = async () => {
            if (!user) {
                return;
            }
            const resellerId = account.type === AccountType.Reseller ? account.parentId : account.resellerId;
            if (!resellerId) {
                return;
            }
            const response = await ApiService.getActivities(account.id, account.type, resellerId);
            if (!response) {
                return;
            }
            const newActivities = new Map(activities);
            response.data.sort((a, b) => {
                if (isBefore(new Date(a.timestamp), new Date(b.timestamp))) {
                    return 1;
                } else if (isAfter(new Date(a.timestamp), new Date(b.timestamp))) {
                    return -1;
                } else {
                    return 0;
                }
            });
            newActivities.set(account.id, response.data);
            setActivities(newActivities);
        };
        if (!retrievedData) {
            setRetrievedData(true);
            getActivities();
        }
    }, [
        retrievedData,
        user,
        account.id,
        setShowWarning,
        activities,
        setActivities,
        account.type,
        setWarningData,
        ApiService,
        account,
    ]);

    const toggleClosed = () => setClosed(!closed);

    const accountActivities = activities.get(account.id);

    return (
        <div className={clsx(classes.activity, account.type === AccountType.Reseller && classes.resellerSection)}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    Activity
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={clsx(classes.tableHead, classes.date)}>{strings?.date}</TableCell>
                                <TableCell className={clsx(classes.tableHead, classes.time)}>{strings?.time}</TableCell>
                                <TableCell className={classes.tableHead}>{strings?.message}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountActivities &&
                                accountActivities.map((activity, index) => (
                                    <TableRow key={`${activity.timestamp}${index}`}>
                                        <TableCell className={clsx(classes.tableBody, classes.date)}>
                                            {strings?.formattedDateP(activity.timestamp)}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>
                                            {strings?.formattedDateHM(activity.timestamp)}
                                        </TableCell>
                                        <TableCell className={classes.tableBody}>{activity.message}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            </Collapse>
        </div>
    );
};

export default Activity;
