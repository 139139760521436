import { ChangeEvent, CSSProperties, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Colors, SearchIcon } from 'common';
import { AccountType } from '../../types/types';

const useStyles = makeStyles({
    input: {
        height: 59,
        width: 184,
    },
    label: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 10,
        letterSpacing: 0.42,
        lineHeight: '20px',
        height: 20,
    },
    disabledLabel: {
        color: 'rgba(0,0,0,0.3)',
    },
    text: {
        width: '100%',
        border: 0,
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        backgroundColor: 'transparent',
        color: Colors.textPrimary,
        caretColor: 'rgba(101,78,163,0.88)',
        paddingLeft: 4,
        paddingTop: 8,
        paddingBottom: 9.5,
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        '&:hover': {
            borderBottom: '1px solid #654ea3',
        },
        '&:active': {
            borderBottom: '1px solid #654ea3',
        },
        '&:focus': {
            borderBottom: '1px solid #654ea3',
            outline: 'none',
        },
        '&:disabled': {
            borderBottom: '1px solid rgba(0,0,0,0.08)',
        },
        '&:disabled::placeholder': {
            color: 'rgba(0,0,0,0.3)',
        },
        '&::placeholder': {
            color: Colors.textTertiary,
        },
    },
    searchInput: {
        display: 'flex',
        alignItems: 'flex-end',
        height: 40,
        minHeight: 40,
        borderRadius: '4px 4px 1px 1px',
    },
    focusInput: {
        backgroundColor: 'rgba(101,78,163,0.04)',
    },
    icon: {
        paddingLeft: 8,
        paddingBottom: 9.5,
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        paddingRight: 4,
    },
    focusIcon: {
        borderBottom: '1px solid #654ea3',
    },
    disabledIcon: {
        borderBottom: '1px solid rgba(0,0,0,0.08)',
    },
    searchResults: {
        paddingTop: 4,
        paddingBottom: 8,
        backgroundColor: Colors.white,
        borderRadius: 4,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        marginTop: 4,
        position: 'relative',
        zIndex: 1,
    },
    searchResult: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 8,
        paddingRight: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
    },
    error: {
        borderBottom: '1px solid #FE6C6C',
    },
    reseller: {
        height: 24,
        width: 103,
        borderRadius: 17.5,
        backgroundColor: 'rgba(101,78,163,0.08)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontSize: 12,
        letterSpacing: 2,
        lineHeight: '16px',
    },
});

interface AccountBasic {
    name: string;
    type: AccountType;
}

interface Props {
    onChange: (value: string) => void;
    value: string | number;
    accounts: AccountBasic[];
    placeholder?: string;
    style?: CSSProperties;
    disabled?: boolean;
    error?: boolean;
    autoFocus?: boolean;
}

const AccountSearchInput: FC<Props> = ({
    value,
    placeholder,
    style,
    disabled,
    onChange,
    accounts,
    error,
    autoFocus,
}) => {
    const classes = useStyles();
    const searchInputRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [results, setResults] = useState<AccountBasic[]>([]);

    useEffect(() => {
        let input: HTMLInputElement;
        if (inputRef.current) {
            input = inputRef.current;
            inputRef.current.addEventListener('focus', () => setHasFocus(true));
            inputRef.current.addEventListener('blur', () => setHasFocus(false));
            inputRef.current.addEventListener('mouseover', () => setMouseOver(true));
            inputRef.current.addEventListener('mouseout', () => setMouseOver(false));
        }
        let searchInput: HTMLDivElement;
        if (searchInputRef.current) {
            document.addEventListener('click', (event: any) => {
                if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
                    setShowResults(false);
                }
            });
        }
        return () => {
            if (input) {
                input.removeEventListener('focus', () => setHasFocus(true));
                input.removeEventListener('blur', () => setHasFocus(false));
                input.removeEventListener('mouseover', () => setMouseOver(true));
                input.removeEventListener('mouseout', () => setMouseOver(false));
            }
            if (searchInput) {
                document.removeEventListener('click', (event: any) => {
                    if (searchInput && !searchInput.contains(event.target)) {
                        setShowResults(false);
                    }
                });
            }
        };
    });

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase().trim();
        const newResults = accounts.filter((account) => account.name.toLowerCase().includes(value));
        setShowResults(value !== '');
        setResults(value !== '' ? newResults : []);
        onChange(event.target.value);
    };

    const onSelectOption = (value: string) => (event: MouseEvent) => {
        if (inputRef.current) {
            inputRef.current.value = value;
        }
        setResults([]);
        onChange(value);
    };

    return (
        <div className={classes.input} style={style ? style : undefined} ref={searchInputRef}>
            <div className={clsx(classes.label, disabled ? classes.disabledLabel : undefined)}>
                {value !== '' ? placeholder : ''}
            </div>
            <div className={clsx(classes.searchInput, hasFocus ? classes.focusInput : undefined)}>
                <input
                    autoFocus={autoFocus}
                    onChange={onChangeSearch}
                    className={clsx(classes.text, error ? classes.error : undefined)}
                    defaultValue={value as string}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={inputRef}
                />
                <div
                    className={clsx(
                        classes.icon,
                        disabled ? classes.disabledIcon : hasFocus || mouseOver ? classes.focusIcon : undefined,
                        error && !(hasFocus || mouseOver) ? classes.error : undefined
                    )}
                >
                    <SearchIcon
                        color={
                            disabled ? 'rgba(0,0,0,0.08)' : !(hasFocus || mouseOver) ? 'rgba(0,0,0,0.4)' : Colors.purple
                        }
                    />
                </div>
            </div>
            {((results.length > 0 && hasFocus) ||
                (results.length > 0 && inputRef.current && inputRef.current.value !== '' && showResults)) && (
                <div className={classes.searchResults} key={inputRef.current?.value ?? ''}>
                    {results.map((result) => (
                        <div
                            className={classes.searchResult}
                            key={result.name + result.type}
                            onClick={onSelectOption(result.name)}
                        >
                            {result.name}
                            {result.type === AccountType.Reseller && <div className={classes.reseller}>RESELLER</div>}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AccountSearchInput;
