import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Colors, Loading } from 'common';
import { useStore } from '../../store/StoreProvider';
import Page from '../Page';
import PageContent from '../PageContent';
import Header from '../header/Header';
import { useHistory, useParams } from 'react-router-dom';
import Licenses from './Licenses';
import Connection from './Connection';
import Administrators from './Administrators';
import Activity from './Activity';
import {
    AccessLevel,
    AccountSetupProgress,
    AccountType,
    ActionStatusType,
    Customer,
    LicenseClass,
} from '../../types/types';
import Breadcrumbs from './Breadcrumbs';
import Notes from './Notes';
import AccountSetup from './AccountSetup';
import AccountOpportunities from './AccountOpportunities';
import OutcomePicker from './OutcomePicker';
import StarterOutcomes from './StarterOutcomes';
import AccountCustomers from './AccountCustomers';
import { useApiService } from '../../services/ApiServiceProvider';
import CurrentUsage from './CurrentUsage';
import { useGetAccountData, useUpdateBreadcrumbs } from '../../utils/hooks';
import EditIcon from '../icons/EditIcon';
import CheckIcon from '../icons/CheckIcon';
import Input from 'common/dist/inputs/Input';
import { usePrevious } from 'common';

const useStyles = makeStyles({
    customerDetail: {
        display: 'flex',
    },
    setupSteps: {
        marginRight: 32,
    },
    title: {
        color: Colors.textPrimary,
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '28px',
    },
    reseller: {
        height: 16,
        width: 66,
        borderRadius: 16,
        backgroundColor: 'rgba(101,78,163,0.08)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Colors.purple,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontSize: 10,
        lineHeight: '12px',
        letterSpacing: 0.5,
        textTransform: 'uppercase',
        marginTop: 4,
    },
    titleReseller: {
        marginBottom: 32,
    },
    sideBar: {},
    editContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    button: {
        cursor: 'pointer',
    },
});

interface Steps {
    selectedStarterOutcomeComplete: boolean;
    establishedConnectionComplete: boolean;
    addedCompleteLicensesComplete: boolean;
    addedNuliaAdminsComplete: boolean;
}

const AccountDetail: FC = () => {
    const classes = useStyles();
    const { account, user, setupData, setSetupData, setAccount, allAccounts, setAllAccounts } = useStore();
    const [accountSetupData, setAccountSetupData] = useState<AccountSetupProgress | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [steps, setSteps] = useState<Steps>({
        selectedStarterOutcomeComplete: false,
        establishedConnectionComplete: false,
        addedCompleteLicensesComplete: false,
        addedNuliaAdminsComplete: false,
    });
    const [editName, setEditName] = useState<{
        name: string;
        open: boolean;
        error: boolean;
    }>({
        name: '',
        open: false,
        error: false,
    });
    const history = useHistory();
    const params = useParams<{ id?: string }>();
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();
    const updateBreadcrumbs = useUpdateBreadcrumbs();
    const previousAccountId = usePrevious<string>(account?.id ?? '');

    useEffect(() => {
        if (account?.id !== previousAccountId) {
            setEditName({ name: '', open: false, error: false });
        }
    }, [account, previousAccountId]);

    useEffect(() => {
        if (account && params.id && account.id !== params.id) {
            const newAccount = allAccounts.find((a) => a.id === params.id);
            if (!newAccount) {
                return;
            }
            setAccount(newAccount);
            updateBreadcrumbs(newAccount);
        }
    });

    const getSetupProgress = useCallback(async () => {
        if (!user || !account) {
            return;
        }
        const resellerId = account.type === AccountType.Reseller ? account.parentId : account.resellerId;
        if (!resellerId) {
            return;
        }
        const response = await ApiService.getAccountSetupProgress(account.id, resellerId);
        if (!response) {
            return;
        }
        const newSetupData = setupData
            ? new Map<string, AccountSetupProgress>(setupData)
            : new Map<string, AccountSetupProgress>();
        newSetupData.set(account.id, response.data);
        setSetupData(newSetupData);
    }, [ApiService, account, setSetupData, setupData, user]);

    useEffect(() => {
        if (
            account &&
            account.type === AccountType.Customer &&
            (!setupData || (setupData && !setupData.get(account.id)))
        ) {
            getSetupProgress();
        }
    }, [account, getSetupProgress, setupData]);

    useEffect(() => {
        if (account && setupData && setupData.has(account.id) && setupData.get(account.id)) {
            setAccountSetupData(setupData.get(account.id));
        }
    }, [account, setupData]);

    const isCompleteAction = useCallback(
        (key: string): boolean => {
            return Boolean(
                account &&
                    setupData &&
                    setupData.has(account.id) &&
                    setupData.get(account.id)![key] &&
                    setupData.get(account.id)![key].status === ActionStatusType.Completed
            );
        },
        [account, setupData]
    );

    useEffect(() => {
        if (!setupData) {
            return;
        }
        setSteps({
            selectedStarterOutcomeComplete: isCompleteAction('selectedStarterOutcome'),
            establishedConnectionComplete: isCompleteAction('establishedConnection'),
            addedCompleteLicensesComplete: isCompleteAction('addedCompleteLicenses'),
            addedNuliaAdminsComplete: isCompleteAction('addedNuliaAdmins'),
        });
    }, [setupData, isCompleteAction]);

    if (!user) {
        history.push('/login');
        return null;
    }

    if (!account || (!setupData && account.type === AccountType.Customer)) {
        return (
            <Page>
                <Header />
                <PageContent>
                    <Loading />
                </PageContent>
            </Page>
        );
    }

    const getData = async () => {
        if (account.type !== AccountType.Customer || retrievedData) {
            return;
        }
        setRetrievedData(true);
        getAccountData(account.resellerId);
        const response = await ApiService.getAccountSetupProgress(account.id, account.resellerId);
        if (!response) {
            return;
        }
        const newSetupData = setupData
            ? new Map<string, AccountSetupProgress>(setupData)
            : new Map<string, AccountSetupProgress>();
        newSetupData.set(account.id, response.data);
        setSetupData(newSetupData);
    };

    const openEditName = () => setEditName({ error: false, open: true, name: account.name });

    const onChangeEditName = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === ' ' && editName.name === '') {
            setEditName({ ...editName, name: event.target.value.trim() });
        } else {
            setEditName({ ...editName, name: event.target.value });
        }
    };

    const submitName = async () => {
        if (editName.name === '' || editName.name.trim() === '') {
            setEditName({ ...editName, error: true });
            return;
        }

        if (account.type === AccountType.Customer) {
            await ApiService.updateCustomerName(editName.name, account.id);
        } else {
            await ApiService.updateResellerName(editName.name, account.id);
        }

        const resellerId = account.type === AccountType.Reseller ? account.parentId : account.resellerId;
        if (!resellerId) {
            return;
        }
        await getAccountData(resellerId);
        updateBreadcrumbs({ ...account, name: editName.name });
        const response = await ApiService.getAllAccounts();
        if (!response) {
            return;
        }
        setAllAccounts(response.data);

        setEditName({ ...editName, error: false, open: false });
    };

    const isStarterCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);
    const isCompleteCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Complete, LicenseClass.CompleteSync].includes(account.licenseClass);

    const O = (
        <OutcomePicker
            key="outcome picker"
            account={account as Customer}
            isCompleteAction={isCompleteAction}
            initiallyClosed={!steps.selectedStarterOutcomeComplete}
            getData={getData}
        />
    );
    const C = (
        <Connection
            key="connection"
            account={account}
            isCompleteAction={isCompleteAction}
            initiallyClosed={!steps.establishedConnectionComplete}
            setupProgress={accountSetupData}
        />
    );
    const L = <Licenses key="licenses" account={account} isCompleteAction={isCompleteAction} getData={getData} />;
    const A = (
        <Administrators
            key="admins"
            account={account as Customer}
            isCompleteAction={isCompleteAction}
            initiallyClosed={!steps.addedNuliaAdminsComplete}
        />
    );
    const S = <StarterOutcomes key="outcomes" account={account as Customer} isComplete={isCompleteAction} />;

    const starterCards = !steps.selectedStarterOutcomeComplete
        ? [O, C, L, A]
        : !steps.establishedConnectionComplete
        ? [C, L, A, S]
        : !steps.addedCompleteLicensesComplete
        ? [L, A, C, S]
        : !steps.addedNuliaAdminsComplete
        ? [A, L, C, S]
        : [L, C, A, S];

    const defaultCards = !steps.establishedConnectionComplete
        ? [C, L, A]
        : !steps.addedCompleteLicensesComplete
        ? [L, A, C]
        : !steps.addedNuliaAdminsComplete
        ? [A, L, C]
        : [L, C, A];

    return (
        <Page>
            <Header />
            <PageContent>
                <Breadcrumbs />
                <div className={classes.titleReseller}>
                    <div className={classes.editContainer}>
                        {editName.open ? (
                            <>
                                <Input
                                    style={{ height: 44, width: 500 }}
                                    inputStyle={{
                                        fontFamily: 'Ubuntu',
                                        fontWeight: 600,
                                        fontSize: 24,
                                        lineHeight: '28px',
                                        color: 'rgba(0, 0, 0, 0.8)',
                                    }}
                                    value={editName.name}
                                    error={editName.error}
                                    onChange={onChangeEditName}
                                />
                                <Box className={classes.button} marginLeft={2} onClick={submitName}>
                                    <CheckIcon />
                                </Box>
                            </>
                        ) : (
                            <>
                                <div className={classes.title}>{account.name}</div>
                                <Box className={classes.button} marginLeft={2} onClick={openEditName}>
                                    <EditIcon />
                                </Box>
                            </>
                        )}
                    </div>
                    {account.type === AccountType.Reseller && <div className={classes.reseller}>RESELLER</div>}
                </div>
                <div className={classes.customerDetail}>
                    <div className={account.type === AccountType.Customer ? classes.setupSteps : undefined}>
                        {isStarterCustomer && starterCards.map((card) => card)}
                        {isCompleteCustomer && defaultCards.map((card) => card)}
                        {account.type === AccountType.Reseller && (
                            <>
                                <Box marginBottom={4}>
                                    <CurrentUsage account={account} initiallyClosed={true} />
                                </Box>
                                <AccountCustomers account={account} />
                            </>
                        )}
                        <Activity account={account} />
                        {account.type === AccountType.Customer && user?.accessLevel !== AccessLevel.PlatformAdmin && (
                            <Notes account={account} />
                        )}
                    </div>
                    {account.type === AccountType.Customer && (
                        <div className={classes.sideBar}>
                            <AccountOpportunities account={account} />
                            {accountSetupData && <AccountSetup data={accountSetupData} account={account} />}
                        </div>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default AccountDetail;
