import { FC, useEffect, useState } from 'react';
import Routes from './Routes';
import { ENABLE_ANALYTICS } from '../utils/constants';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import config from '../partner-config.json';

const App: FC = () => {
    const [startedAnalytics, setStartedAnalytics] = useState<boolean>(false);

    useEffect(() => {
        if (
            !startedAnalytics &&
            localStorage.getItem(ENABLE_ANALYTICS) !== null &&
            window.location.hostname !== 'localhost'
        ) {
            setStartedAnalytics(true);
            ReactGA.initialize('UA-127438582-3');
            ReactGA.set(['anonymizeIp', true]);
            ReactGA.pageview(window.location.pathname + window.location.search);
            Sentry.init({
                dsn: 'https://eba7f622428346c29f41debe576b2ea3@o221410.ingest.sentry.io/1518632',
                environment: config.environment,
            });
        }
    }, [startedAnalytics]);

    return <Routes />;
};

export default App;
