import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccountSetupProgress, AccountType, ActionStatusType, Customer, LicenseClass } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import { Tooltip } from 'common';

const useStyles = makeStyles({
    accountSetup: {
        height: 'fit-content',
        width: 342,
        borderRadius: 4,
        backgroundColor: 'white',
        padding: 32,
        paddingTop: 24,
        paddingBottom: 24,
    },
    title: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 32,
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 2,
        color: 'rgba(0, 0, 0, 0.88)',
        marginBottom: 24,
    },
    entry: {
        marginBottom: 16,
    },
    iconLabel: {
        display: 'flex',
        alignItems: 'top',
    },
    label: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.88)',
    },
    date: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.64)',
        marginLeft: 24,
    },
    completeState: {
        marginRight: 12,
        marginTop: 6,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    line: {
        height: 1,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        marginBottom: 32,
        marginTop: 32,
    },
});

interface Props {
    data: AccountSetupProgress;
    account: Customer;
}

const AccountSetup: FC<Props> = ({ data, account }) => {
    const classes = useStyles();
    const { strings } = useStore();

    const isStarterCustomer =
        account.type === AccountType.Customer &&
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass);

    const Icon: FC<{ status: ActionStatusType }> = ({ status }) => (
        <div className={classes.completeState}>
            <Tooltip title={(status === ActionStatusType.Completed ? strings?.complete : strings?.notComplete) ?? ''}>
                <div className={status === ActionStatusType.Completed ? classes.complete : classes.notComplete} />
            </Tooltip>
        </div>
    );

    return (
        <div className={classes.accountSetup}>
            <div className={classes.title}>{strings?.accountSetupProgress}</div>
            <div className={classes.subtitle}>{strings?.accountSetupInitial}</div>

            {isStarterCustomer && (
                <div className={classes.entry}>
                    <div className={classes.iconLabel}>
                        <Icon status={data.selectedStarterOutcome.status} />
                        <div className={classes.label}>{strings?.accountSetupStarterOutcome}</div>
                    </div>
                    {data.selectedStarterOutcome.date && (
                        <div className={classes.date}>
                            {strings?.accountSetupStarterOutcomeDate(data.selectedStarterOutcome.date)}
                        </div>
                    )}
                </div>
            )}

            <div className={classes.entry}>
                <div className={classes.iconLabel}>
                    <Icon status={data.establishedConnection.status} />
                    <div className={classes.label}>{strings?.accountSetupConnection}</div>
                </div>
                {data.establishedConnection.date && (
                    <div className={classes.date}>
                        {strings?.accountSetupConnectionDate(data.establishedConnection.date)}
                    </div>
                )}
            </div>

            <div className={classes.entry}>
                <div className={classes.iconLabel}>
                    <Icon status={data.addedCompleteLicenses.status} />
                    <div className={classes.label}>{strings?.accountSetupCompleteLicenses}</div>
                </div>
                {data.addedCompleteLicenses.date && (
                    <div className={classes.date}>
                        {strings?.accountSetupCompleteLicensesDate(data.addedCompleteLicenses.date)}
                    </div>
                )}
            </div>

            <div className={classes.entry}>
                <div className={classes.iconLabel}>
                    <Icon status={data.addedNuliaAdmins.status} />
                    <div className={classes.label}>{strings?.accountSetupAdmin}</div>
                </div>
                {data.addedNuliaAdmins.date && (
                    <div className={classes.date}>{strings?.accountSetupAdminDate(data.addedNuliaAdmins.date)}</div>
                )}
            </div>
            {/*TODO: Add this back in after release*/}
            {/*<div className={classes.line}/>*/}

            {/*<div className={classes.subtitle}>AUTOMATED ACTIONS</div>*/}
            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.globalAdminEmailSent.status}/>*/}
            {/*        <div className={classes.label}>Global admin email sent</div>*/}
            {/*    </div>*/}
            {/*    {data.globalAdminEmailSent.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Sent on {format(new Date(fixTimeZone(data.globalAdminEmailSent.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.nuliaAdminEmailSent.status}/>*/}
            {/*        <div className={classes.label}>Nulia admin emails sent</div>*/}
            {/*    </div>*/}
            {/*    {data.nuliaAdminEmailSent.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Sent on {format(new Date(fixTimeZone(data.nuliaAdminEmailSent.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.nuliaAdminLoggedIn.status}/>*/}
            {/*        <div className={classes.label}>Nulia admin logged in for the first time</div>*/}
            {/*    </div>*/}
            {/*    {data.nuliaAdminLoggedIn.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Logged in on {format(new Date(fixTimeZone(data.nuliaAdminLoggedIn.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.firstLicenseAssigned.status}/>*/}
            {/*        <div className={classes.label}>First licenses assigned by Nulia admin</div>*/}
            {/*    </div>*/}
            {/*    {data.firstLicenseAssigned.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Assigned on {format(new Date(fixTimeZone(data.firstLicenseAssigned.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.firstOutcomeAssigned.status}/>*/}
            {/*        <div className={classes.label}>First outcome assigned by Nulia admin</div>*/}
            {/*    </div>*/}
            {/*    {data.firstOutcomeAssigned.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Assigned on {format(new Date(fixTimeZone(data.firstOutcomeAssigned.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.twoWeekWaitCompleted.status}/>*/}
            {/*        <div className={classes.label}>Two week waiting period completed</div>*/}
            {/*    </div>*/}
            {/*    {data.twoWeekWaitCompleted.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Completed on {format(new Date(fixTimeZone(data.twoWeekWaitCompleted.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}

            {/*<div className={classes.entry}>*/}
            {/*    <div className={classes.iconLabel}>*/}
            {/*        <Icon status={data.firstUserLogin.status}/>*/}
            {/*        <div className={classes.label}>First users login!</div>*/}
            {/*    </div>*/}
            {/*    {data.firstUserLogin.date && (*/}
            {/*        <div className={classes.date}>*/}
            {/*            Logged in on {format(new Date(fixTimeZone(data.firstUserLogin.date)), "P")}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
        </div>
    );
};

export default AccountSetup;
