import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Dialog } from 'common';
import AccountSearchInput from '../inputs/AccountSearchInput';
import { Account, AccountType, ResellerSimple } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import { getActivities, updateBreadcrumbs } from '../../utils/helper';
import { useApiService } from '../../services/ApiServiceProvider';
import SearchInput from '../inputs/SearchInput';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    addCustomer: {},
});

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
    initialParent: ResellerSimple;
    parentResellers: ResellerSimple[];
    exitingAccounts: Account[];
    resellerId: string;
}

const MoveAccountDialog: FC<Props> = ({
    open,
    onClose,
    onAccept,
    initialParent,
    parentResellers,
    exitingAccounts,
    resellerId,
}) => {
    const classes = useStyles();
    const [existingAccount, setExistingAccount] = useState<Account | undefined>(undefined);
    const [existingAccountError, setExitingAccountError] = useState<boolean>(false);
    const [parent, setParent] = useState<ResellerSimple | undefined>(undefined);
    const [parentError, setParentError] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const { activities, setActivities, strings, account, setBreadcrumbs } = useStore();
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    useEffect(() => {
        setParent(initialParent);
    }, [initialParent]);

    const onChangeParent = (value: string) => {
        const newParent = parentResellers.find((r) => r.name === value);
        setParent(newParent);
        setParentError(false);
    };

    const onChangeExistingAccount = (event: unknown, account: Account | null) => {
        setExistingAccount(account ? account : undefined);
        setExitingAccountError(false);
    };

    const handleAccept = async () => {
        setDisableButton(true);
        let hasParentError = false;
        let hasExistingAccountError = false;
        if (!existingAccount || (existingAccount && parent && existingAccount.id === parent.id)) {
            hasExistingAccountError = true;
        }
        setExitingAccountError(hasExistingAccountError);
        if (existingAccount && parent && parent.id && !hasParentError && !hasExistingAccountError) {
            const accountParentId =
                existingAccount.type === AccountType.Reseller ? existingAccount.parentId : existingAccount.resellerId;
            const resellerParentId = parent.parentId;
            if (!accountParentId) {
                setDisableButton(false);
                return;
            }
            await ApiService.moveAccount(
                existingAccount.id,
                accountParentId,
                existingAccount.type,
                parent.id,
                resellerParentId
            );
            onAccept();
            await Promise.all([
                getAccountData(resellerId),
                getActivities(activities, parent.id, setActivities, ApiService),
            ]);
            const allAccountsResponse = await ApiService.getAllAccounts();
            if (!allAccountsResponse || !account) {
                return;
            }
            const breadcrumbs = updateBreadcrumbs(account, []);
            breadcrumbs.push(account);
            setBreadcrumbs(breadcrumbs);
            resetForm();
            setDisableButton(false);
            return;
        }
        setDisableButton(false);
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    const resetForm = () => {
        setExistingAccount(undefined);
        setExitingAccountError(false);
    };

    const resellersData = parentResellers.map((r) => ({ name: r.name, type: AccountType.Reseller }));
    const accountsData = exitingAccounts.filter((a) => a.id !== parent?.id);

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={strings?.associate}
            onClose={handleClose}
            cancelText={strings?.cancel}
            title={strings?.associateExistingAccount}
            acceptDisabled={disableButton}
            content={
                <div className={classes.addCustomer}>
                    <AccountSearchInput
                        placeholder={strings?.parentReseller}
                        value={parent?.name ?? ''}
                        style={{ width: 336, marginBottom: 8 }}
                        onChange={onChangeParent}
                        accounts={resellersData}
                        error={parentError}
                    />
                    <SearchInput
                        options={accountsData}
                        getOptionLabel={(account: Account) => {
                            let name = account.name;
                            if (account.type === AccountType.Reseller) {
                                name = `${account.name} RESELLER`;
                            }
                            return name;
                        }}
                        onChange={onChangeExistingAccount}
                        error={existingAccountError}
                        placeholder={strings?.account}
                        value={existingAccount}
                    />
                </div>
            }
        />
    );
};

export default MoveAccountDialog;
