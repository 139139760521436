import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import GreyTextButton from '../buttons/GreyTextButton';
import { Colors } from 'common';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    text: {
        height: 16,
        display: 'flex',
        alignItems: 'center',
    },
});

const ViewAccountDetailsButton: FC = () => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const history = useHistory();

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => setMouseOver(false);
    const openAccountDetails = () => history.push('/partner-details/account-info');

    return (
        <GreyTextButton
            onClick={openAccountDetails}
            style={{ height: 40 }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <span className={classes.text}>View Account Details</span>
            <ChevronRightIcon small={true} color={mouseOver ? Colors.textSecondary : undefined} />
        </GreyTextButton>
    );
};

export default ViewAccountDetailsButton;
