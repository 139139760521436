import { FC, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import AddButton from '../buttons/AddButton';
import { AccessLevel, Account, AccountType, ResellerType } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import AccountTable from './AccountTable';
import SearchAccounts from './SearchAccounts';
import { usePrevious } from 'common';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    customers: {
        paddingTop: 24,
        paddingLeft: 24,
        paddingRight: 24,
    },
    searchButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 36,
    },
});

interface Props {
    account: Account;
}

const Customers: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const { user, accounts, setAccounts } = useStore();
    const previousAccountId = usePrevious<string>(account.id);
    const ApiService = useApiService();

    useEffect(() => {
        const getAccounts = async () => {
            const response = await ApiService.getAccounts(account.id);
            if (!response) {
                return;
            }
            const newAccounts = new Map(accounts);
            newAccounts.set(account.id, response.data);
            setAccounts(newAccounts);
        };
        if (account.id !== previousAccountId) {
            getAccounts();
        }
    }, [ApiService, account.id, accounts, previousAccountId, setAccounts, user]);

    if (!user) {
        return null;
    }

    const resellerAccounts = accounts.get(account.id);
    const parentId = account.type === AccountType.Customer ? account.resellerId : account.parentId;
    const isAdmin = user?.accessLevel === AccessLevel.PlatformAdmin;

    return (
        <div className={classes.customers}>
            <div className={classes.searchButton}>
                {((isAdmin && user?.resellerId === parentId) || !isAdmin) && (
                    <div style={{ marginRight: 16 }}>
                        <SearchAccounts
                            resellerId={account.id}
                            backgroundColor="#FAFAFA"
                            searchWidth={682}
                            resultsWidth={661}
                        />
                    </div>
                )}
                <AddButton resellerId={account.id} resellerName={account.name} parentId={parentId} />
            </div>
            {resellerAccounts && resellerAccounts.length > 0 && (
                <AccountTable
                    key={account.id + 'customers'}
                    accounts={resellerAccounts}
                    completeOnly={user.resellerType === ResellerType.Default}
                    reseller={true}
                />
            )}
        </div>
    );
};

export default Customers;
