import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { Customer } from '../../../types/types';
import commaNumber from 'comma-number';
import UsageBoxes from './UsageBoxes';

const useStyles = makeStyles({
    annualUsage: {},
    title: {
        marginLeft: 24,
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.8)',
        marginBottom: 12,
    },
    content: {
        padding: 24,
        paddingTop: 16,
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
    },
    totals: {},
    totalLine: {
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingTop: 8,
        paddingBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    totalLabel: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
    totalNumber: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.88)',
    },
});

interface Props {
    account: Customer;
}

const AnnualUsage: FC<Props> = ({ account }) => {
    const classes = useStyles();
    return (
        <div className={classes.annualUsage}>
            <div className={classes.title}>Annual Usage</div>
            <div className={classes.content}>
                <UsageBoxes />
                <div className={classes.totalLine}>
                    <div className={classes.totalLabel}>Total Month-Credits</div>
                    <div className={classes.totalNumber}>{commaNumber(2500)}</div>
                </div>
                <div className={classes.totalLine}>
                    <div className={classes.totalLabel}>Used to Date</div>
                    <div className={classes.totalNumber}>{commaNumber(0)}</div>
                </div>
                <div className={classes.totalLine}>
                    <div className={classes.totalLabel}>Remaining Month-Credits</div>
                    <div className={classes.totalNumber}>{commaNumber(2500)}</div>
                </div>
                <div className={classes.totalLine}>
                    <div className={classes.totalLabel}>Projected to use by Contract term</div>
                    <div className={classes.totalNumber}>{commaNumber(0)}</div>
                </div>
            </div>
        </div>
    );
};

export default AnnualUsage;
