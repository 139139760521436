import { FC, useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { Colors } from 'common';
import Download from './Download';
import InvoicesMoreButton from './InvoicesMoreButton';
import { useApiService } from '../../services/ApiServiceProvider';
import { useStore } from '../../store/StoreProvider';
import { CsvInvoiceType, Invoice } from '../../types/types';

const useStyles = makeStyles({
    invoices: {
        display: 'flex',
    },
    recentInvoice: {
        width: 341,
        minWidth: 341,
        height: 332,
        borderRadius: 4,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        backgroundColor: '#ffffff',
        padding: 24,
        marginRight: 32,
    },
    titleDueDate: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    dueDate: {
        width: 103,
        height: 16,
        borderRadius: 16,
        backgroundColor: 'rgba(250, 208, 70, 0.32)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: 0.5,
        color: '#ac5521',
        marginTop: 4,
    },
    pastDue: {
        width: 67,
        height: 16,
        borderRadius: 16,
        backgroundColor: '#d73d33',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: 0.5,
        color: '#ffffff',
        marginTop: 4,
    },
    paid: {
        width: 41,
        height: 16,
        borderRadius: 16,
        backgroundColor: 'rgba(142, 194, 11, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Open Sans',
        fontSize: 10,
        fontWeight: 600,
        lineHeight: 1.2,
        letterSpacing: 0.5,
        color: '#4e6b05',
        marginTop: 4,
    },
    invoiceCode: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.33,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.8)',
        marginBottom: 24,
    },
    total: {
        width: '100%',
        fontWeight: 'normal',
        paddingBottom: 24,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    pastInvoices: {
        width: 715,
        minWidth: 715,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        paddingTop: 24,
        paddingBottom: 16,
    },
    button: {
        height: 36,
        minHeight: 36,
        maxHeight: 36,
        borderRadius: 4,
        fontSize: 12,
        letterSpacing: 1,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.64)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        border: 0,
        outline: 0,
        padding: 0,
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        '&:focus': {
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
        paddingTop: 16,
    },
    firstCell: {
        paddingLeft: 24,
    },
    title: {
        paddingLeft: 24,
    },
    tableInvoiceCode: {
        color: '#654ea3',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    downloadIcon: {
        marginLeft: 4,
        height: 16,
    },
    innerDownload: {
        marginTop: 20,
        marginBottom: 8,
    },
    moreCell: {
        paddingRight: 8,
    },
});

interface Invoices {
    first: Invoice;
    all: Invoice[];
    initial: Invoice[];
}

interface Props {}

const Invoices: FC<Props> = () => {
    const classes = useStyles();
    const [showMore, setShowMore] = useState<boolean>(false);
    const [invoices, setInvoices] = useState<Invoices | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const ApiService = useApiService();
    const { user, strings } = useStore();

    useEffect(() => {
        const getData = async () => {
            if (!user) {
                return;
            }
            const response = await ApiService.getInvoices(user.resellerId);
            if (!response) {
                return;
            }
            const first =
                response.data.length > 0
                    ? response.data[0]
                    : { date: new Date().toISOString(), invoiceNumber: '', amount: '0.00' };
            setInvoices({ first, all: response.data.slice(1), initial: response.data.slice(1, 6) });
            setShowMore(response.data.length < 6);
        };
        if (!retrievedData) {
            setRetrievedData(true);
            getData();
        }
    }, [ApiService, retrievedData, user]);

    const onShowMore = () => setShowMore(true);

    const BodyCell: FC<{ first?: boolean }> = ({ children, first }) => (
        <TableCell className={first ? classes.firstCell : undefined}>
            <Typography variant="body2" component="div" style={{ color: 'rgba(0,0,0,0.88)' }}>
                {children}
            </Typography>
        </TableCell>
    );

    if (!invoices) {
        return null;
    }

    const viewAbleInvoices = invoices ? (!showMore ? invoices.initial : invoices.all) : [];
    const date = new Date(invoices?.first.date);
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return (
        <div className={classes.invoices}>
            <div className={classes.recentInvoice}>
                <div className={classes.titleDueDate}>
                    <Typography className={classes.total} variant="h6">
                        Recent Usage
                    </Typography>
                </div>
                <Typography variant="subtitle2" className={classes.innerDownload}>
                    Downloads:
                </Typography>
                <Download
                    inner={true}
                    resellerId={user?.resellerId ?? ''}
                    invoiceNumber={invoices.first.invoiceNumber}
                    invoiceType={CsvInvoiceType.Summary}
                    month={month}
                    year={year}
                >
                    {strings?.byCustomerAll}
                </Download>
                <Download
                    resellerId={user?.resellerId ?? ''}
                    invoiceNumber={invoices.first.invoiceNumber}
                    invoiceType={CsvInvoiceType.CustomerList}
                    month={month}
                    year={year}
                >
                    {strings?.customerList}
                </Download>
            </div>
            <div className={classes.pastInvoices}>
                <Typography variant="h6" className={classes.title}>
                    Past Usage Statement
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.firstCell}>
                                <Typography variant="caption">Date</Typography>
                            </TableCell>
                            <TableCell>{''}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {viewAbleInvoices.map((invoice) => {
                            const date = new Date(invoice.date);
                            return (
                                <TableRow>
                                    <BodyCell first={true}>{format(date, 'PP')}</BodyCell>
                                    <TableCell className={classes.moreCell}>
                                        <InvoicesMoreButton invoice={invoice} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {!showMore && (
                    <button onClick={onShowMore} className={classes.button}>
                        SHOW MORE
                    </button>
                )}
            </div>
        </div>
    );
};

export default Invoices;
