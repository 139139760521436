import { makeStyles } from '@material-ui/core';
import { FC, useState, ChangeEvent, MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Colors } from 'common';
import Header from './header/Header';
import { useStore } from '../store/StoreProvider';
import Page from './Page';
import PageContent from './PageContent';
import { useApiService } from '../services/ApiServiceProvider';

const useStyles = makeStyles({
    contact: {
        height: '100vh',
    },
    contactContainer: {
        padding: 24,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)',
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    thanks: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        marginBottom: 24,
    },
    thanksContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
        marginBottom: 24,
        marginTop: 16,
    },
    textArea: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        border: 0,
        height: 200,
        width: '100%',
        resize: 'none',
        outline: 'none',
        caretColor: 'rgba(101,78,163,0.88)',
    },
});

const Contact: FC = () => {
    const { user, strings } = useStore();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [body, setBody] = useState<string>('');
    const classes = useStyles();
    const history = useHistory();
    const ApiService = useApiService();

    if (!user) {
        return null;
    }

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => setBody(event.target.value);
    const submit = (event: MouseEvent) => {
        event.preventDefault();
        ApiService.sendContactEmail(body);
        setIsSubmitted(true);
    };
    const goBack = () => history.goBack();

    return (
        <Page>
            <Header />
            <PageContent>
                <div className={classes.contact}>
                    <div className={classes.title}>Contact</div>
                    {!isSubmitted ? (
                        <div className={classes.contactContainer}>
                            <textarea
                                className={classes.textArea}
                                placeholder={strings?.contactPlaceholder}
                                onChange={onChange}
                                value={body}
                            />
                            <div className={classes.bottom}>
                                <Button type="submit" onClick={submit} style={{ width: 124 }} disabled={body === ''}>
                                    {strings?.submit}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.thanksContainer}>
                            <div className={classes.thanks}>{strings?.contactSubmitted}</div>
                            <Button onClick={goBack} style={{ width: 100, margin: 'auto' }}>
                                {strings?.goBack}
                            </Button>
                        </div>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default Contact;
