import { FC, useEffect, useState } from 'react';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Button, Colors, ChevronDownIcon, ChevronUpIcon, Tooltip } from 'common';
import { AccessLevel, Customer, StarterOutcome } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import {
    OUTCOMES_PATH,
    StarterCollaboration,
    StarterGettingStarted,
    StarterOrganizingFiles,
    StarterOutlook,
} from '../../utils/constants';
import clsx from 'clsx';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    outcomePicker: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    outcomeContent: {},
    outcomeImageLarge: {
        width: 64,
        minWidth: 64,
        height: 64,
        minHeight: 64,
        marginRight: 16,
    },
    outcomeImageSmall: {
        width: 32,
        minWidth: 32,
        height: 32,
        minHeight: 32,
        marginRight: 8,
    },
    outcomeName: {
        fontFamily: 'Ubuntu',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.1,
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 4,
    },
    outcomeContainer: {
        padding: 16,
        display: 'flex',
        borderRadius: 4,
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        backgroundColor: '#ffffff',
        marginBottom: 16,
    },
    outcomeClickArea: {
        cursor: 'pointer',
    },
    outcomeContainerSelected: {
        border: 'solid 1px #654ea3',
        backgroundColor: '#eeeef8',
    },
    startingOutcome: {
        display: 'flex',
        marginBottom: 40,
    },
});

interface Props {
    account: Customer;
    isCompleteAction: (section: string) => boolean | undefined;
    initiallyClosed: boolean;
    getData: () => void;
}

const OutcomePicker: FC<Props> = ({ account, isCompleteAction, initiallyClosed, getData }) => {
    const classes = useStyles();
    const [closed, setClosed] = useState<boolean>(true);
    const [startingOutcome, setStartingOutcome] = useState<StarterOutcome | undefined>(undefined);
    const [outcomes, setOutcomes] = useState<StarterOutcome[] | undefined>(undefined);
    const [selectedOutcomeId, setSelectedOutcomeId] = useState<number | undefined>(undefined);
    const { user, strings } = useStore();
    const ApiService = useApiService();

    useEffect(() => {
        setClosed(initiallyClosed);
    }, [initiallyClosed]);

    useEffect(() => {
        const getOutcomes = async () => {
            if (!user) {
                return;
            }
            const response = await ApiService.getStarterOutcomes();
            if (!response) {
                return;
            }
            const newOutcomes: StarterOutcome[] = [];
            response.data.forEach((outcome) => {
                if (outcome.included) {
                    setStartingOutcome(outcome);
                } else {
                    newOutcomes.push(outcome);
                }
            });
            setOutcomes(newOutcomes);
        };
        if (!outcomes) {
            getOutcomes();
        }
    });

    const toggleClosed = () => setClosed(!closed);
    const selectOutcome = (id: number) => (event: unknown) => setSelectedOutcomeId(id);
    const setOutcome = async () => {
        if (!user || !selectedOutcomeId || !account) {
            return;
        }
        toggleClosed();
        await ApiService.setStarterOutcome(account.id, selectedOutcomeId);
        getData();
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    };

    const Outcome: FC<{ outcome: StarterOutcome }> = ({ outcome }) => (
        <div
            className={clsx(
                classes.outcomeContainer,
                selectedOutcomeId === outcome.id && classes.outcomeContainerSelected
            )}
        >
            <img
                onClick={selectOutcome(outcome.id)}
                className={clsx(classes.outcomeImageLarge, classes.outcomeClickArea)}
                alt="outcome option"
                src={`${OUTCOMES_PATH}${outcome.image}.svg`}
            />
            <div className={classes.outcomeContent}>
                <div
                    className={clsx(classes.outcomeName, classes.outcomeClickArea)}
                    onClick={selectOutcome(outcome.id)}
                >
                    {outcome.name}
                </div>
                <Typography variant="body2" onClick={selectOutcome(outcome.id)} className={classes.outcomeClickArea}>
                    {outcome.id === 100001
                        ? StarterGettingStarted
                        : outcome.id === 10009
                        ? StarterOrganizingFiles
                        : outcome.id === 10010
                        ? StarterOutlook
                        : StarterCollaboration}
                </Typography>
            </div>
        </div>
    );

    return (
        <div className={classes.outcomePicker}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    {!isCompleteAction('selectedStarterOutcome') ? (
                        <div className={classes.completeState}>
                            <Tooltip title="Not complete">
                                <div className={classes.notComplete} />
                            </Tooltip>
                        </div>
                    ) : (
                        <div className={classes.completeState}>
                            <Tooltip title="Complete">
                                <div className={classes.complete} />
                            </Tooltip>
                        </div>
                    )}
                    {strings?.starterOutcomes}
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Typography variant="body1" style={{ marginBottom: 16 }}>
                        {strings?.starterOutcomesAccess}
                    </Typography>
                    {startingOutcome && (
                        <div className={classes.startingOutcome}>
                            <img
                                className={classes.outcomeImageSmall}
                                alt="starting outcome"
                                src={`${OUTCOMES_PATH}${startingOutcome.image}.svg`}
                            />
                            <div className={classes.outcomeContent}>
                                <div className={classes.outcomeName}>{startingOutcome.name}</div>
                                <Typography variant="body2">{startingOutcome.description}</Typography>
                            </div>
                        </div>
                    )}
                    <Typography variant="body1" style={{ marginBottom: 16 }}>
                        {strings?.additionalOutcome}
                    </Typography>
                    {outcomes && outcomes.map((outcome) => <Outcome key={outcome.id} outcome={outcome} />)}
                    <Button
                        onClick={setOutcome}
                        style={{ width: 126 }}
                        disabled={!selectedOutcomeId || user?.accessLevel === AccessLevel.PlatformAdmin}
                    >
                        {strings?.setOutcome}
                    </Button>
                </div>
            </Collapse>
        </div>
    );
};

export default OutcomePicker;
