import { FC, useEffect, useState } from 'react';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Customer, StarterOutcome } from '../../types/types';
import { Colors, ChevronDownIcon, ChevronUpIcon, Tooltip } from 'common';
import { OUTCOMES_PATH } from '../../utils/constants';
import { useApiService } from '../../services/ApiServiceProvider';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    starterOutcomes: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    header: {
        height: 64,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.08)',
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        padding: 24,
        paddingBottom: 32,
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    outcomeImageSmall: {
        width: 32,
        minWidth: 32,
        height: 32,
        minHeight: 32,
        marginRight: 8,
    },
    outcomeName: {
        fontFamily: 'Ubuntu',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 0.1,
        color: 'rgba(0, 0, 0, 0.8)',
        marginBottom: 4,
    },
    outcomes: {
        display: 'flex',
        flexDirection: 'column',
    },
    outcome: {
        display: 'flex',
        marginTop: 8,
        marginBottom: 8,
    },
    outcomeContent: {},
});

interface Props {
    account: Customer;
    isComplete: (section: string) => boolean | undefined;
}

const StarterOutcomes: FC<Props> = ({ account, isComplete }) => {
    const classes = useStyles();
    const { strings } = useStore();
    const [outcomes, setOutcomes] = useState<StarterOutcome[] | undefined>(undefined);
    const [closed, setClosed] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        const getOutcomes = async () => {
            const response = await ApiService.getStarterOutcomes();
            if (!response) {
                return;
            }
            const newOutcomes: StarterOutcome[] = [];
            if (account.starterOutcomeId1) {
                const outcome = response.data.find((outcome) => outcome.id === account.starterOutcomeId1);
                if (outcome) {
                    newOutcomes.push(outcome);
                }
            }
            if (account.starterOutcomeId2) {
                const outcome = response.data.find((outcome) => outcome.id === account.starterOutcomeId2);
                if (outcome) {
                    newOutcomes.push(outcome);
                }
            }
            setOutcomes(newOutcomes);
        };
        if (!outcomes) {
            getOutcomes();
        }
    });

    const toggleClosed = () => setClosed(!closed);

    return (
        <div className={classes.starterOutcomes}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    {!isComplete('selectedStarterOutcome') ? (
                        <div className={classes.completeState}>
                            <Tooltip title={strings?.notComplete ?? ''}>
                                <div className={classes.notComplete} />
                            </Tooltip>
                        </div>
                    ) : (
                        <div className={classes.completeState}>
                            <Tooltip title={strings?.complete ?? ''}>
                                <div className={classes.complete} />
                            </Tooltip>
                        </div>
                    )}
                    {strings?.starterOutcomes}
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    <Typography variant="body1" style={{ marginBottom: 16 }}>
                        {strings?.includedStarterOutcomes}
                    </Typography>
                    <div className={classes.outcomes}>
                        {outcomes &&
                            outcomes.map((outcome) => (
                                <div className={classes.outcome}>
                                    <img
                                        className={classes.outcomeImageSmall}
                                        alt="outcome"
                                        src={`${OUTCOMES_PATH}${outcome.image}.svg`}
                                    />
                                    <div className={classes.outcomeContent}>
                                        <div className={classes.outcomeName}>{outcome.name}</div>
                                        <Typography variant="body2">{outcome.description}</Typography>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default StarterOutcomes;
