import { FC, MouseEvent, useState } from 'react';
import { makeStyles, Popover } from '@material-ui/core';
import { Colors, MoreHorizontalIcon } from 'common';
import { useStore } from '../../store/StoreProvider';
import Download from './Download';
import DownloadIcon from '../icons/DownloadIcon';
import { CsvInvoiceType, Invoice } from '../../types/types';

const useStyles = makeStyles({
    moreButton: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
        minHeight: 24,
        width: 24,
        minWidth: 24,
    },
    paper: {
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        borderRadius: 4,
        backgroundColor: Colors.white,
        overflowY: 'inherit',
        overflowX: 'inherit',
        padding: 16,
        width: 223,
        minWidth: 223,
    },
    arrowContainer: {
        background: 'transparent',
        boxShadow: 'none',
        borderRadius: 0,
    },
    arrow: {
        width: 0,
        height: 0,
        position: 'absolute',
        top: 12,
        left: 197,
        borderColor: 'transparent white white transparent',
        transformOrigin: '0 0',
        transform: 'rotate(-135deg)',
        boxShadow: '8px 8px 16px 0 rgba(0,0,0,0.08)',
        border: '8px solid white',
    },
    button: {
        height: 40,
        color: Colors.purple,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
    },
    downloadIcon: {
        marginLeft: 4,
        height: 16,
    },
});

interface Props {
    invoice: Invoice;
}

const InvoicesMoreButton: FC<Props> = ({ invoice }) => {
    const classes = useStyles();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const { user, strings } = useStore();

    if (!user) {
        return null;
    }

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => setMouseOver(false);
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);
    const onClick = (event: MouseEvent) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);

    const openMore = Boolean(anchorEl);
    const id = openMore ? 'partner-details-more' : undefined;
    const date = new Date(invoice.date);
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    return (
        <>
            <div
                className={classes.moreButton}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onClick={onClick}
                aria-describedby={id}
            >
                <MoreHorizontalIcon mouseOver={mouseOver} mouseDown={mouseDown} selected={openMore} />
            </div>
            <Popover
                id={id}
                open={openMore}
                onClose={onClose}
                anchorEl={anchorEl}
                classes={{
                    paper: classes.paper,
                }}
                anchorOrigin={{
                    vertical: 30,
                    horizontal: 38,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className={classes.arrow} />
                <Download
                    tall={true}
                    resellerId={user?.resellerId ?? ''}
                    invoiceNumber={invoice.invoiceNumber}
                    invoiceType={CsvInvoiceType.Summary}
                    month={month}
                    year={year}
                >
                    {strings?.byCustomerAll}
                    <div className={classes.downloadIcon}>
                        <DownloadIcon />
                    </div>
                </Download>
                <Download
                    tall={true}
                    resellerId={user?.resellerId ?? ''}
                    invoiceNumber={invoice.invoiceNumber}
                    invoiceType={CsvInvoiceType.CustomerList}
                    month={month}
                    year={year}
                >
                    {strings?.customerList}
                    <div className={classes.downloadIcon}>
                        <DownloadIcon />
                    </div>
                </Download>
            </Popover>
        </>
    );
};

export default InvoicesMoreButton;
