import { ChangeEvent, CSSProperties, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useStore } from '../../store/StoreProvider';
import { Colors, SearchIcon } from 'common';
import FuzzySearch from 'fuzzy-search';
import { useHistory } from 'react-router-dom';
import { useApiService } from '../../services/ApiServiceProvider';
import { useUpdateBreadcrumbs } from '../../utils/hooks';

const useStyles = makeStyles({
    searchAccounts: {
        position: 'relative',
    },
    search: {
        backgroundColor: Colors.white,
        height: 36,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    searchIcon: {
        marginRight: 8,
        height: 24,
        width: 24,
    },
    input: {
        width: '100%',
        border: 0,
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        color: Colors.textSecondary,
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: Colors.textTertiary,
        },
    },
    searchResults: {
        paddingTop: 4,
        paddingBottom: 8,
        backgroundColor: Colors.white,
        borderRadius: 4,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        marginTop: 4,
        position: 'absolute',
        zIndex: 1,
        width: 356,
        marginLeft: 10,
    },
    searchResult: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 8,
        paddingRight: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important',
        },
    },
});

type Props = {
    resellerId?: string;
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    resultsStyle?: CSSProperties;
    backgroundColor?: string;
    searchWidth?: number;
    resultsWidth?: number;
};

const SearchAccounts: FC<Props> = ({ resellerId, backgroundColor, searchWidth, resultsWidth }) => {
    const classes = useStyles();
    const { user, setShowWarning, setAllAccounts, allAccounts, setAccount, setWarningData, strings } = useStore();
    const [searchString, setSearchString] = useState<string>('');
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const searchRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [hasFocus, setHasFocus] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const [results, setResults] = useState<string[]>([]);
    const history = useHistory();
    const ApiService = useApiService();
    const updateBreadcrumbs = useUpdateBreadcrumbs();

    useEffect(() => {
        let input: HTMLInputElement;
        if (inputRef.current) {
            input = inputRef.current;
            inputRef.current.addEventListener('focus', () => setHasFocus(true));
            inputRef.current.addEventListener('blur', () => setHasFocus(false));
        }
        let searchInput: HTMLDivElement;
        if (searchRef.current) {
            document.addEventListener('click', (event: any) => {
                if (searchRef.current && !searchRef.current.contains(event.target)) {
                    setShowResults(false);
                }
            });
        }
        return () => {
            if (input) {
                input.removeEventListener('focus', () => setHasFocus(true));
                input.removeEventListener('blur', () => setHasFocus(false));
            }
            if (searchInput) {
                document.removeEventListener('click', (event: any) => {
                    if (searchInput && !searchInput.contains(event.target)) {
                        setShowResults(false);
                    }
                });
            }
        };
    });

    useEffect(() => {
        const getAllAccounts = async () => {
            const response = await ApiService.getAllAccounts();
            if (!response) {
                return;
            }
            setAllAccounts(response.data);
        };
        if (user && !retrievedData) {
            setRetrievedData(true);
            getAllAccounts();
        }
    }, [retrievedData, resellerId, allAccounts, setAllAccounts, setShowWarning, user, setWarningData, ApiService]);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const searcher = new FuzzySearch(allAccounts.map((a) => a.name));
        const newResults = searcher.search(value).slice(0, 5);
        setShowResults(value !== '');
        setResults(value !== '' ? newResults : []);
        setSearchString(event.target.value);
    };

    const onSelectOption = (value: string) => (event: MouseEvent) => {
        if (inputRef.current) {
            inputRef.current.value = value;
        }
        setResults([]);
        setSearchString('');
        const account = allAccounts.find((a) => a.name === value);
        if (account) {
            updateBreadcrumbs(account);
            setAccount(account);
            history.push(`/account-detail/${account.id}`);
        }
    };

    return (
        <div className={classes.searchAccounts} style={{ width: searchWidth }} ref={searchRef}>
            <div className={classes.search} style={{ backgroundColor }}>
                <div className={classes.searchIcon}>
                    <SearchIcon large={true} color={Colors.textTertiary} />
                </div>
                <input
                    className={classes.input}
                    style={{ backgroundColor }}
                    value={searchString}
                    onChange={onSearch}
                    placeholder={strings?.searchPlaceholder}
                    ref={inputRef}
                />
            </div>
            {((results.length > 0 && hasFocus) ||
                (results.length > 0 && inputRef.current && inputRef.current.value !== '' && showResults)) && (
                <div className={classes.searchResults} style={{ width: resultsWidth }}>
                    {results.map((result, index) => (
                        <div className={classes.searchResult} key={result + index} onClick={onSelectOption(result)}>
                            {result}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchAccounts;
