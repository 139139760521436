import { CSSProperties, FC, useState } from 'react';
import { Button } from 'common';
import { AccessLevel, Account } from '../../types/types';
import { makeStyles } from '@material-ui/core';
import AddLicensesDialog from '../dialogs/AddLicensesDialog';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    addLicensesButton: {},
});

interface Props {
    account: Account;
    title: string;
    getData: () => void;
    style?: CSSProperties;
    disabled?: boolean;
}

const AddLicensesButton: FC<Props> = ({ account, title, getData, style, disabled }) => {
    const classes = useStyles();
    const { user } = useStore();
    const [open, setOpen] = useState<boolean>(false);

    if (!user) {
        return null;
    }

    const toggleDialog = () => {
        setOpen(!open);
        getData();
    };

    return (
        <div className={classes.addLicensesButton}>
            <Button
                onClick={toggleDialog}
                style={!style ? { width: 160 } : style}
                disabled={user?.accessLevel === AccessLevel.PlatformAdmin || disabled}
            >
                {title}
            </Button>
            <AddLicensesDialog open={open} onClose={toggleDialog} onAccept={toggleDialog} account={account} />
        </div>
    );
};

export default AddLicensesButton;
