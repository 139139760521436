import { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './login/Login';
import Accounts from './accounts/Accounts';
import CustomerDetail from './accounts/AccountDetail';
import Contact from './Contact';
import PartnerDetails from './partnerDetails/PartnerDetails';
import Resources from './Resources';
import Partners from './accounts/Partners';

const Routes: FC = () => (
    <Switch>
        <Redirect exact={true} from="/" to="/login" />
        <Route path="/login">
            <Login />
        </Route>
        <Route path="/accounts">
            <Accounts />
        </Route>
        <Route path="/account-detail/:id">
            <CustomerDetail />
        </Route>
        <Route path="/resources">
            <Resources />
        </Route>
        <Route path="/contact">
            <Contact />
        </Route>
        <Route path="/partner-details">
            <PartnerDetails />
        </Route>
        <Route path="/partners">
            <Partners />
        </Route>
        <Redirect to="/login" />
    </Switch>
);

export default Routes;
