import { Card, CardContent, Theme } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { ACCEPTED_COOKIES, LOGOS_PATH } from '../../utils/constants';
import { Button, Colors } from 'common';
import { useStore } from '../../store/StoreProvider';
import { makeStyles } from '@material-ui/styles';
import { AuthService } from '../../services/AuthService';
import CookiesDialog from '../dialogs/CookiesDialog';
import { localizedStrings } from '../../utils/strings';

const useStyles = makeStyles((theme: Theme) => ({
    login: {
        background:
            `linear-gradient(to right, ${Colors.purple} 0, ${Colors.purple} 15%, ` +
            `${Colors.loginDarkGrey} 15%, ${Colors.loginDarkGrey} 16%, ${Colors.background} 16%, ` +
            `${Colors.background} 100%)`,
        backgroundSize: 'cover',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            background:
                `linear-gradient(to right, ${Colors.purple} 0, ${Colors.purple} 10%, ` +
                `${Colors.loginDarkGrey} 10%, ${Colors.loginDarkGrey} 11%, ${Colors.background} 11%, ` +
                `${Colors.background} 100%)`,
        },
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: 24,
        width: '100%',
    },
    card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        width: 800,
        height: 400,
        [theme.breakpoints.down('md')]: {
            width: 300,
        },
    },
    bigTitle: {
        fontSize: 80,
        color: Colors.purple,
        fontFamily: 'Ubuntu',
        [theme.breakpoints.down('md')]: {
            fontSize: 50,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 40,
        },
    },
    littleTitle: {
        fontSize: 40,
        color: Colors.purple,
        marginBottom: -8,
        fontFamily: 'Ubuntu',
        paddingLeft: 25,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: 30,
        },
    },
    loginArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    compoundTitle: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    imageLogo: {
        width: 350,
        [theme.breakpoints.down('md')]: {
            width: 250,
        },
        [theme.breakpoints.down('sm')]: {
            width: 200,
        },
    },
}));

const Login: FC = () => {
    const { loggingIn, strings, setStrings } = useStore();
    const classes = useStyles();
    const [openCookiesDialog, setOpenCookiesDialog] = useState<boolean>(false);

    useEffect(() => {
        window.localStorage.removeItem('isTestEnvironment');
        localizedStrings.setLanguage('en-US');
        setStrings(localizedStrings);
    });

    const login = () => {
        if (localStorage.getItem(ACCEPTED_COOKIES) !== 'true') {
            setOpenCookiesDialog(true);
            return;
        }
        AuthService.login();
    };

    return (
        <>
            <div className={classes.login}>
                <div className={classes.loginArea}>
                    <div className={classes.compoundTitle}>
                        <img className={classes.imageLogo} src={`${LOGOS_PATH}purpleLogo.svg`} alt="logo title" />
                        <div className={classes.littleTitle}>{strings?.partnerPortal}</div>
                    </div>
                    <Card className={classes.card}>
                        <CardContent>
                            <div className={classes.loginArea}>
                                <img src={`${LOGOS_PATH}Login_grey.svg`} alt="Login logo" />
                                {!loggingIn && (
                                    <Button
                                        id="login-button"
                                        onClick={login}
                                        style={{
                                            fontFamily: 'Ubuntu',
                                            marginTop: 50,
                                            fontSize: 40,
                                            width: 275,
                                            height: 75,
                                            minHeight: 75,
                                            maxHeight: 75,
                                        }}
                                    >
                                        {strings?.login}
                                    </Button>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <CookiesDialog open={openCookiesDialog} setOpen={setOpenCookiesDialog} />
        </>
    );
};

export default Login;
