import { FC } from 'react';
import { useStore } from '../../store/StoreProvider';
import { Dialog } from 'common';
import { Account, AccountType } from '../../types/types';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import config from '../../partner-config.json';

const useStyles = makeStyles({
    connectToTenantDialog: {},
    text: {
        color: 'rgba(0,0,0,0.64)',
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 1.5,
    },
    topText: {
        marginBottom: 32,
    },
});

interface Props {
    account: Account;
    open: boolean;
    onClose: () => void;
}

const ConnectToTenantDialog: FC<Props> = ({ account, open, onClose }) => {
    const classes = useStyles();
    const { strings } = useStore();

    const copyUrl = () => {
        if (account.type === AccountType.Customer) {
            navigator.clipboard.writeText(`${config.registrationUrl}?id=${account.id}&appId=${account.appId}`);
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onAccept={copyUrl}
            title={strings?.connectToTenant}
            acceptText={strings?.copyUrl}
            cancelText={strings?.cancel}
            acceptButtonWidth={168}
            width={714}
            content={
                <div className={classes.connectToTenantDialog}>
                    <div className={clsx(classes.text, classes.topText)}>{strings?.connectTenantRegister}</div>
                    <div className={classes.text}>{strings?.connectTenantRegisterAfter}</div>
                </div>
            }
        />
    );
};

export default ConnectToTenantDialog;
