import { FC, useEffect, useState } from 'react';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { Account, AccountSetupProgress, AccountType, ActionStatusType, LicenseClass } from '../../types/types';
import { Button, Colors, SecondaryButton, ChevronDownIcon, ChevronUpIcon, Tooltip } from 'common';
import clsx from 'clsx';
import { useStore } from '../../store/StoreProvider';
import { format } from 'date-fns';
import ConnectToTenantDialog from '../dialogs/ConnectToTenantDialog';
import SendConnectInstructionsDialog from '../dialogs/SendConnectInstructionsDialog';

const useStyles = makeStyles({
    connection: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
        paddingTop: 16,
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    label: {
        color: 'rgba(0, 0, 0, 0.88)',
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: 1.5,
    },
    bold: {
        fontWeight: 600,
    },
    starterNoOutcomes: {
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 0.5,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.4)',
    },
    notConnected: {
        display: 'flex',
        alignItems: 'center',
    },
    connectBox: {
        width: 325,
        height: 148,
        borderRadius: 2,
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    leftConnectBox: {
        marginRight: 16,
    },
});

interface Props {
    account: Account;
    isCompleteAction: (section: string) => boolean | undefined;
    initiallyClosed: boolean;
    setupProgress?: AccountSetupProgress;
}

const Connection: FC<Props> = ({ account, isCompleteAction, initiallyClosed, setupProgress }) => {
    const classes = useStyles();
    const [openSendInstructionsDialog, setOpenSendInstructionsDialog] = useState<boolean>(false);
    const [openConnectDialog, setOpenConnectDialog] = useState<boolean>(false);
    const [reconnectTenant, setReconnectTenant] = useState<boolean>(false);
    const { user, strings } = useStore();
    const [closed, setClosed] = useState<boolean>(true);

    useEffect(() => {
        setClosed(initiallyClosed);
    }, [initiallyClosed]);

    const toggleClosed = () => setClosed(!closed);

    if (!user || account.type !== AccountType.Customer || !setupProgress) {
        return null;
    }

    const toggleTenantDialog = () => {
        setOpenConnectDialog(!openConnectDialog);
        setReconnectTenant(false);
    };
    const toggleSendInstructionsDialog = () => {
        setOpenSendInstructionsDialog(!openSendInstructionsDialog);
        setReconnectTenant(false);
    };
    const reconnect = () => setReconnectTenant(true);

    const isNewStarter =
        [LicenseClass.Starter, LicenseClass.StarterCompleteSync].includes(account.licenseClass) &&
        !account.starterOutcomeId2;
    const isCompleteConnection = setupProgress.establishedConnection.status === ActionStatusType.Completed;
    const isConnected = isCompleteConnection || Boolean(account.connected);
    const isUnconnected = !isConnected;

    return (
        <>
            <div className={classes.connection}>
                <div className={classes.header} onClick={toggleClosed}>
                    <Typography variant="h6" className={classes.title}>
                        {!isCompleteAction('establishedConnection') ? (
                            <div className={classes.completeState}>
                                <Tooltip title="Not complete">
                                    <div className={classes.notComplete} />
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={classes.completeState}>
                                <Tooltip title="Complete">
                                    <div className={classes.complete} />
                                </Tooltip>
                            </div>
                        )}
                        {strings?.connection}
                    </Typography>
                    {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </div>
                <Collapse in={closed}>
                    <div className={classes.body}>
                        {isNewStarter ? (
                            <div className={classes.starterNoOutcomes}>{strings?.beforeConnectionText}</div>
                        ) : isUnconnected || reconnectTenant ? (
                            <div className={classes.notConnected}>
                                <div className={clsx(classes.connectBox, classes.leftConnectBox)}>
                                    <div className={classes.label}>{strings?.amAdminForTenant}</div>
                                    <Button onClick={toggleTenantDialog} style={{ width: 176, marginTop: 16 }}>
                                        {strings?.connect}
                                    </Button>
                                </div>
                                <div className={classes.connectBox}>
                                    <div className={classes.label}>{strings?.notAdminForTenant}</div>
                                    <Button
                                        onClick={toggleSendInstructionsDialog}
                                        style={{ width: 196, marginTop: 16 }}
                                    >
                                        {strings?.sendInstructions}
                                    </Button>
                                </div>
                            </div>
                        ) : isConnected ? (
                            <>
                                <div className={classes.label}>
                                    {isCompleteConnection ? 'Successfully connected on ' : 'Initiated connection on '}
                                    <span className={classes.bold}>
                                        {format(
                                            new Date(
                                                isCompleteConnection
                                                    ? (setupProgress.establishedConnection.date as string)
                                                    : (account.connected as string)
                                            ),
                                            'PP'
                                        )}
                                    </span>
                                </div>
                                <SecondaryButton onClick={reconnect} style={{ width: 144, marginTop: 16 }}>
                                    {strings?.reconnect}
                                </SecondaryButton>
                            </>
                        ) : null}
                    </div>
                </Collapse>
            </div>
            <ConnectToTenantDialog account={account} open={openConnectDialog} onClose={toggleTenantDialog} />
            <SendConnectInstructionsDialog
                account={account}
                open={openSendInstructionsDialog}
                onClose={toggleSendInstructionsDialog}
            />
        </>
    );
};

export default Connection;
