import { FC } from 'react';
import { Colors } from 'common';

interface Props {
    small?: boolean;
    color?: string;
}

const ChevronRightIcon: FC<Props> = ({ small, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-chevron-right"
        color={color ? color : Colors.iconDefault}
        style={{ height: small ? 16 : undefined, width: small ? 16 : undefined }}
    >
        <polyline points="9 18 15 12 9 6" />
    </svg>
);

export default ChevronRightIcon;
