import { createContext, FC, useContext, useState } from 'react';
import {
    Account,
    AccountInfo,
    AccountInfo as IAccountInfo,
    AccountSetupProgress,
    Activity,
    Administrator,
    User,
    WarningData,
    SnackbarData,
    OrderByAccounts,
} from '../types/types';
import { Order } from 'common-v2';
import { Strings } from '../utils/strings';

export interface Store {
    copyButtonDisabled: boolean;
    setCopyButtonDisabled: (setCopyButtonDisabled: boolean) => void;
    loggingIn: boolean;
    setLoggingIn: (loggingIn: boolean) => void;
    user?: User;
    setUser: (user?: User) => void;
    account?: Account;
    setAccount: (account?: Account) => void;
    warningData?: WarningData;
    setWarningData: (data: WarningData) => void;
    showWarning: boolean;
    setShowWarning: (showWarning: boolean) => void;
    accounts: Map<string, Account[]>;
    setAccounts: (accounts: Map<string, Account[]>) => void;
    administrators?: Administrator[];
    setAdministrators: (administrators?: Administrator[]) => void;
    breadcrumbs?: Account[];
    setBreadcrumbs: (breadcrumbs: Account[]) => void;
    activities: Map<string, Activity[]>;
    setActivities: (activities: Map<string, Activity[]>) => void;
    allAccounts: Account[];
    setAllAccounts: (accounts: Account[]) => void;
    setupData?: Map<string, AccountSetupProgress>;
    setSetupData: (setupData: Map<string, AccountSetupProgress>) => void;
    partners?: Account[];
    setPartners: (partners: Account[]) => void;
    strings?: Strings;
    setStrings: (strings: Strings) => void;
    accountInfo?: AccountInfo;
    setAccountInfo: (accountInfo: AccountInfo) => void;
    openSnackbar: boolean;
    setOpenSnackbar: (open: boolean) => void;
    snackbarData?: SnackbarData;
    setSnackbarData: (data: SnackbarData) => void;
    orderAccounts: Order;
    setOrderAccounts: (order: Order) => void;
    orderByAccounts: OrderByAccounts;
    setOrderByAccounts: (orderBy: OrderByAccounts) => void;
    accountsTablePage: number;
    setAccountsTablePage: (page: number) => void;
    accountsRowsPerPage: number;
    setAccountsRowsPerPage: (rowsPerPage: number) => void;
}

export const StoreContext = createContext<Store>({} as Store);
export const useStore = () => useContext<Store>(StoreContext);

const StoreProvider: FC = ({ children }) => {
    const [copyButtonDisabled, setCopyButtonDisabled] = useState<boolean>(false);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const [account, setAccount] = useState<Account | undefined>(undefined);
    const [warningData, setWarningData] = useState<WarningData | undefined>(undefined);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<Map<string, Account[]>>(new Map<string, Account[]>());
    const [administrators, setAdministrators] = useState<Administrator[] | undefined>(undefined);
    const [breadcrumbs, setBreadcrumbs] = useState<Account[] | undefined>(undefined);
    const [activities, setActivities] = useState<Map<string, Activity[]>>(new Map<string, Activity[]>());
    const [allAccounts, setAllAccounts] = useState<Account[]>([]);
    const [setupData, setSetupData] = useState<Map<string, AccountSetupProgress> | undefined>(undefined);
    const [partners, setPartners] = useState<Account[] | undefined>(undefined);
    const [strings, setStrings] = useState<Strings | undefined>(undefined);
    const [accountInfo, setAccountInfo] = useState<IAccountInfo | undefined>(undefined);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarData, setSnackbarData] = useState<SnackbarData | undefined>(undefined);
    const [orderAccounts, setOrderAccounts] = useState<Order>('asc');
    const [orderByAccounts, setOrderByAccounts] = useState<OrderByAccounts>('name');
    const [accountsTablePage, setAccountsTablePage] = useState<number>(0);
    const [accountsRowsPerPage, setAccountsRowsPerPage] = useState<number>(20);

    const store: Store = {
        copyButtonDisabled,
        setCopyButtonDisabled,
        user,
        setUser,
        loggingIn,
        setLoggingIn,
        account,
        setAccount,
        warningData,
        setWarningData,
        showWarning,
        setShowWarning,
        accounts,
        setAccounts,
        administrators,
        setAdministrators,
        breadcrumbs,
        setBreadcrumbs,
        activities,
        setActivities,
        allAccounts,
        setAllAccounts,
        setupData,
        setSetupData,
        partners,
        setPartners,
        strings,
        setStrings,
        accountInfo,
        setAccountInfo,
        openSnackbar,
        setOpenSnackbar,
        snackbarData,
        setSnackbarData,
        orderAccounts,
        setOrderAccounts,
        orderByAccounts,
        setOrderByAccounts,
        accountsTablePage,
        setAccountsTablePage,
        accountsRowsPerPage,
        setAccountsRowsPerPage,
    };

    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default StoreProvider;
