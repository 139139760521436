import { FC } from 'react';

const EditIcon: FC = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" style={{ height: 16, width: 16 }}>
        <path
            d="M7.16666 2.66666H2.49999C2.14637 2.66666 1.80723 2.80713 1.55718 3.05718C1.30713 3.30723 1.16666 3.64637 1.16666 3.99999V13.3333C1.16666 13.6869 1.30713 14.0261 1.55718 14.2761C1.80723 14.5262 2.14637 14.6667 2.49999 14.6667H11.8333C12.1869 14.6667 12.5261 14.5262 12.7761 14.2761C13.0262 14.0261 13.1667 13.6869 13.1667 13.3333V8.66666"
            stroke="black"
            strokeOpacity="0.32"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.1667 1.66665C12.4319 1.40144 12.7916 1.25244 13.1667 1.25244C13.5417 1.25244 13.9014 1.40144 14.1667 1.66665C14.4319 1.93187 14.5809 2.29158 14.5809 2.66665C14.5809 3.04173 14.4319 3.40144 14.1667 3.66665L7.83332 9.99999L5.16666 10.6667L5.83332 7.99999L12.1667 1.66665Z"
            stroke="black"
            strokeOpacity="0.32"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default EditIcon;
