import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Opportunities, Account } from '../../types/types';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    accountOpportunities: {
        width: 342,
        borderRadius: 4,
        backgroundColor: 'white',
        padding: 24,
        paddingBottom: 32,
        marginBottom: 24,
    },
    title: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.88)',
        marginBottom: 16,
    },
    titleDescription: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        lineHeight: 1.43,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.64)',
        marginBottom: 24,
    },
    subtitle: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.6)',
        marginBottom: 4,
    },
    subtitleDescription: {
        fontFamily: 'Open Sans',
        fontSize: 12,
        lineHeight: 1.67,
        letterSpacing: 0.5,
        color: 'rgba(0,0,0,0.64)',
    },
    number: {
        fontFamily: 'Ubuntu',
        fontSize: 32,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.8)',
        marginBottom: 4,
    },
    top: {
        marginBottom: 20,
    },
});

interface Props {
    account: Account;
}

const AccountOpportunities: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const { strings } = useStore();
    const [opportunities, setOpportunities] = useState<Opportunities | undefined>(undefined);
    const ApiService = useApiService();

    useEffect(() => {
        const getOpportunities = async () => {
            const response = await ApiService.getOpportunities(account.id);
            if (!response) {
                return;
            }
            setOpportunities(response.data);
        };
        if (!opportunities) {
            getOpportunities();
        }
    });

    if (
        (!opportunities?.completedStarterCount || opportunities?.completedStarterCount === 0) &&
        (!opportunities?.requestUpgradeCount || opportunities?.requestUpgradeCount === 0)
    ) {
        return null;
    }

    return (
        <div className={classes.accountOpportunities}>
            <div className={classes.title}>{strings?.accountOpportunitiesTitle}</div>
            <div className={classes.titleDescription}>{strings?.accountOpportunitiesText}</div>
            <div className={classes.top}>
                <div className={classes.number}>{opportunities ? opportunities.requestUpgradeCount : 0}</div>
                <div className={classes.subtitle}>{strings?.accountOpportunitiesTitleRequestComplete}</div>
                <div className={classes.subtitleDescription}>{strings?.accountOpportunitiesTextRequestComplete}</div>
            </div>
            <div className={classes.number}>{opportunities ? opportunities.completedStarterCount : 0}</div>
            <div className={classes.subtitle}>{strings?.accountOpportunitiesTitleCompleteStarter}</div>
            <div className={classes.subtitleDescription}>{strings?.accountOpportunitiesTextCompleteStarter}</div>
        </div>
    );
};

export default AccountOpportunities;
