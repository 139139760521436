import { FC } from 'react';
import { Dialog } from 'common';
import { useStore } from '../../store/StoreProvider';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const NoAccountDialog: FC<Props> = ({ open, setOpen }) => {
    const { strings } = useStore();

    const onClose = () => setOpen(!open);
    const onAccept = () => {
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            title={strings?.noAccountDialogTitle}
            onAccept={onAccept}
            text={strings?.noAccountDialogMessage}
            acceptText={strings?.okay}
        />
    );
};

export default NoAccountDialog;
