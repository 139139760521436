import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Colors, ChevronDownIcon, ChevronUpIcon } from 'common';
import clsx from 'clsx';
import AddCustomerDialog from '../dialogs/AddCustomerDialog';
import { AccessLevel, Account, AccountType, ResellerSimple } from '../../types/types';
import AddResellerDialog from '../dialogs/AddResellerDialog';
import MoveAccountDialog from '../dialogs/MoveAccountDialog';
import { useStore } from '../../store/StoreProvider';
import { getAllAccounts } from '../../utils/helper';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    addButton: {
        position: 'relative',
    },
    button: {
        height: 36,
        minHeight: 36,
        width: 149,
        minWidth: 149,
        borderRadius: 4,
        boxShadow: '0 1.5px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.16)',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 6.5px 16px 1px rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.08)',
        },
    },
    addCustomer: {
        height: '100%',
        width: '100%',
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 12,
        letterSpacing: 1,
        lineHeight: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        '&:active': {
            backgroundColor: Colors.darkPurple,
            boxShadow: 'none',
        },
    },
    select: {
        width: 32,
        minWidth: 32,
        height: '100%',
        borderLeft: '1px solid rgba(255,255,255,0.16)',
        backgroundColor: Colors.purple,
        color: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        '&:active': {
            backgroundColor: Colors.darkPurple,
            boxShadow: 'none',
        },
    },
    options: {
        width: 211,
        backgroundColor: Colors.white,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        borderRadius: 4,
        marginTop: 4,
        paddingTop: 4,
        paddingBottom: 8,
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 1,
    },
    selectOption: {
        width: '100%',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: Colors.purple,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        paddingLeft: 16,
        paddingRight: 16,
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.08) !important',
        },
    },
    expandedSelect: {
        backgroundColor: Colors.darkPurple,
        borderLeft: '1px solid #44346E',
    },
});

interface Props {
    resellerId: string;
    resellerName: string;
    parentId?: string;
}

const AddButton: FC<Props> = ({ resellerId, resellerName, parentId }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [openAddCustomer, setOpenAddCustomer] = useState<boolean>(false);
    const [openAddReseller, setOpenAddReseller] = useState<boolean>(false);
    const [openAssociateAccount, setOpenAssociateAccount] = useState<boolean>(false);
    const [allAccounts, setAllAccounts] = useState<Account[] | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const { user, accounts, strings } = useStore();
    const ApiService = useApiService();

    useEffect(() => {
        if (user && !retrievedData) {
            setRetrievedData(true);
            getAllAccounts(setAllAccounts, ApiService);
        }
    }, [ApiService, retrievedData, user]);

    if (!user) {
        return null;
    }

    const toggleExpand = () => setExpanded(!expanded);
    const toggleAddCustomer = () => {
        setOpenAddCustomer(!openAddCustomer);
        setExpanded(false);
    };
    const toggleAddReseller = () => {
        setOpenAddReseller(!openAddReseller);
        setExpanded(false);
    };
    const toggleAssociateAccount = () => {
        setOpenAssociateAccount(!openAssociateAccount);
        setExpanded(false);
    };

    const resellerAccounts = accounts.get(resellerId);

    let resellers: ResellerSimple[] = resellerAccounts
        ? resellerAccounts
              .filter((a) => a.type === AccountType.Reseller)
              .map((a) => ({
                  id: a.id,
                  name: a.name,
                  parentId: a.type === AccountType.Reseller ? a.parentId : a.resellerId,
              }))
        : [];
    resellers = [{ id: resellerId, name: resellerName, parentId }, ...resellers];

    return (
        <div className={classes.addButton}>
            {user?.accessLevel !== AccessLevel.PlatformAdmin && (
                <div className={classes.button}>
                    <div className={classes.addCustomer} onClick={toggleAddCustomer}>
                        {strings?.addButtonCustomer}
                    </div>
                    <div
                        className={clsx(classes.select, expanded ? classes.expandedSelect : undefined)}
                        onClick={toggleExpand}
                    >
                        {!expanded ? (
                            <ChevronDownIcon small={true} color={Colors.white} />
                        ) : (
                            <ChevronUpIcon small={true} color={Colors.white} />
                        )}
                    </div>
                </div>
            )}
            {expanded && (
                <div className={classes.options}>
                    <div className={classes.selectOption} onClick={toggleAddReseller}>
                        {strings?.addButtonReseller}
                    </div>
                    <div className={classes.selectOption} onClick={toggleAssociateAccount}>
                        {strings?.addButtonMoveAccount}
                    </div>
                </div>
            )}
            <AddCustomerDialog
                open={openAddCustomer}
                onClose={toggleAddCustomer}
                onAccept={toggleAddCustomer}
                initialReseller={resellers[0]}
                resellers={resellers}
            />
            <AddResellerDialog
                open={openAddReseller}
                onClose={toggleAddReseller}
                onAccept={toggleAddReseller}
                initialParent={resellers[0]}
                parentResellers={resellers}
            />
            <MoveAccountDialog
                open={openAssociateAccount}
                onClose={toggleAssociateAccount}
                onAccept={toggleAssociateAccount}
                initialParent={resellers[0]}
                parentResellers={resellers}
                exitingAccounts={allAccounts ? allAccounts : []}
                resellerId={resellerId}
            />
        </div>
    );
};

export default AddButton;
