import { FC, MouseEvent } from 'react';
import { makeStyles, Typography, Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { Colors } from 'common';
import { useStore } from '../../store/StoreProvider';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { AccessLevel, Account } from '../../types/types';
import { useHistory } from 'react-router-dom';
import { useUpdateBreadcrumbs } from '../../utils/hooks';

const useStyles = makeStyles({
    breadcrumbs: {
        marginBottom: 40,
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        '&:hover': {
            color: Colors.textPrimary,
        },
        cursor: 'pointer',
    },
    chevron: {
        marginLeft: 8,
        marginRight: 8,
        marginBottom: -4,
    },
    breadcrumb: {
        display: 'flex',
        alignItems: 'center',
    },
});

const Breadcrumbs: FC = () => {
    const classes = useStyles();
    const { breadcrumbs, setBreadcrumbs, user, setAccount } = useStore();
    const history = useHistory();
    const updateBreadcrumbs = useUpdateBreadcrumbs();

    if (!user) {
        return null;
    }

    const onClick = (account?: Account) => (event: MouseEvent) => {
        if (account) {
            updateBreadcrumbs(account);
            setAccount(account);
            history.push(`/account-detail/${account.id}`);
            return;
        }
        setBreadcrumbs([]);
        setAccount(undefined);
        history.push(user.accessLevel === AccessLevel.PlatformAdmin ? '/partners' : '/accounts');
    };

    return (
        <div className={classes.breadcrumbs}>
            <MuiBreadcrumbs separator={<ChevronRightIcon />} aria-label="breadcrumb">
                <Typography variant="body2" className={classes.text} key="root0" onClick={onClick()}>
                    {user?.accessLevel === AccessLevel.PlatformAdmin ? 'Partners' : user?.resellerName ?? ''}
                </Typography>
                {breadcrumbs &&
                    breadcrumbs.length > 0 &&
                    breadcrumbs.map((breadcrumb) => (
                        <Typography
                            variant="body2"
                            className={classes.text}
                            key={breadcrumb.name + breadcrumb.id}
                            onClick={onClick(breadcrumb)}
                        >
                            {breadcrumb.name}
                        </Typography>
                    ))}
            </MuiBreadcrumbs>
        </div>
    );
};

export default Breadcrumbs;
