import { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CsvInvoiceType } from '../../types/types';
import { useDownloadCsv } from '../../utils/hooks';

const useStyles = makeStyles({
    download: {
        color: '#654ea3',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    innerDownload: {
        marginBottom: 8,
    },
    tallDownload: {
        height: 40,
    },
});

interface Props {
    resellerId: string;
    invoiceNumber: string;
    year: number;
    month: number;
    invoiceType: CsvInvoiceType;
    inner?: boolean;
    tall?: boolean;
}

const Download: FC<Props> = ({ inner, children, tall, resellerId, invoiceNumber, invoiceType, year, month }) => {
    const classes = useStyles();
    const downloadCsv = useDownloadCsv();

    return (
        <Typography
            variant="body2"
            component="div"
            className={clsx(classes.download, inner && classes.innerDownload, tall && classes.tallDownload)}
            onClick={downloadCsv(resellerId, invoiceNumber, year, month, invoiceType)}
        >
            {children}
        </Typography>
    );
};

export default Download;
