import { FC, MouseEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccessLevel, Administrator as IAdministrator } from '../../types/types';
import Input from 'common/dist/inputs/Input';
import { Colors } from 'common';
import XCircleIcon from '../icons/XCircleIcon';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    administrator: {
        display: 'flex',
        alignItems: 'center',
    },
    nameContainer: {
        width: 200,
        minWidth: 200,
        marginRight: 16,
    },
    emailContainer: {
        width: 320,
        minWidth: 320,
        marginRight: 16,
    },
    label: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: '20px',
    },
    remove: {
        height: 14.67,
        minHeight: 14.67,
        width: 14.67,
        minWidth: 14.67,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    verifying: {
        color: Colors.textTertiary,
        fontFamily: 'Open Sans',
        fontSie: 12,
        letterSpacing: 0.5,
        lineHeight: '20px',
        marginLeft: 8,
    },
    removeVerifying: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface Props {
    administrator: IAdministrator;
    onRemove: (administrator: IAdministrator) => (event: MouseEvent) => void;
}

const Administrator: FC<Props> = ({ administrator, onRemove }) => {
    const classes = useStyles();
    const { user } = useStore();
    const [mouseOver, setMouseOver] = useState<boolean>(false);
    const [mouseDown, setMouseDown] = useState<boolean>(false);

    const onMouseOver = () => setMouseOver(true);
    const onMouseOut = () => {
        setMouseOver(false);
        setMouseDown(false);
    };
    const onMouseDown = () => setMouseDown(true);
    const onMouseUp = () => setMouseDown(false);

    return (
        <div className={classes.administrator}>
            <div className={classes.nameContainer}>
                <Input style={{ width: '100%' }} value={administrator.name} readOnly={true} />
            </div>
            <div className={classes.emailContainer}>
                <Input style={{ width: '100%' }} value={administrator.email} readOnly={true} />
            </div>
            <div className={classes.removeVerifying}>
                {user?.accessLevel !== AccessLevel.PlatformAdmin && (
                    <div
                        className={classes.remove}
                        onMouseOver={onMouseOver}
                        onMouseOut={onMouseOut}
                        onMouseDown={onMouseDown}
                        onMouseUp={onMouseUp}
                        onClick={onRemove(administrator)}
                    >
                        <XCircleIcon mouseOver={mouseOver} mouseDown={mouseDown} />
                    </div>
                )}
                {administrator.state !== undefined && (
                    <div className={classes.verifying}>{administrator.errorMessage}</div>
                )}
            </div>
        </div>
    );
};

export default Administrator;
