import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { Collapse, makeStyles, Typography } from '@material-ui/core';
import { AccessLevel, AccountStatus, Administrator as IAdministrator, Customer } from '../../types/types';
import { Colors, SecondaryButton, Dialog, ChevronDownIcon, ChevronUpIcon, Tooltip, logError } from 'common';
import Input from 'common/dist/inputs/Input';
import { useStore } from '../../store/StoreProvider';
import Administrator from './Administrator';
import clsx from 'clsx';
import { ResponseCode } from '../../types/responseTypes';
import isEmail from 'validator/lib/isEmail';
import { useApiService } from '../../services/ApiServiceProvider';

const useStyles = makeStyles({
    administrators: {
        width: 714,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
        marginBottom: 24,
    },
    header: {
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    body: {
        borderTop: 'solid 1px rgba(0, 0, 0, 0.08)',
        padding: 24,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
    },
    completeState: {
        marginRight: 16,
    },
    complete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: '#b0cb3e',
    },
    notComplete: {
        height: 12,
        minHeight: 12,
        width: 12,
        minWidth: 12,
        maxWidth: 12,
        borderRadius: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    text: {
        color: 'rgba(0, 0, 0, 0.64)',
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 1.43,
        marginBottom: 21,
    },
    entry: {
        display: 'flex',
        alignItems: 'center',
    },
    nameContainer: {
        width: 200,
        minWidth: 200,
        marginRight: 16,
    },
    emailContainer: {
        width: 320,
        minWidth: 320,
        marginRight: 16,
    },
    label: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: '20px',
    },
    labels: {
        display: 'flex',
        alignItems: 'center',
    },
    firstLabel: {
        marginRight: 180,
    },
    notConnected: {
        fontFamily: 'Ubuntu',
        fontSize: 20,
        lineHeight: 1.2,
        letterSpacing: 0.25,
        color: 'rgba(0,0,0,0.4)',
        textAlign: 'center',
        marginBottom: 16,
    },
});

interface Props {
    account: Customer;
    isCompleteAction: (section: string) => boolean | undefined;
    initiallyClosed: boolean;
}

const Administrators: FC<Props> = ({ account, isCompleteAction, initiallyClosed }) => {
    const classes = useStyles();
    const { user, setShowWarning, strings } = useStore();
    const [administrators, setAdministrators] = useState<IAdministrator[] | undefined>(undefined);
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [nameError, setNameError] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [openUserNotFound, setOpenUserNotFound] = useState<boolean>(false);
    const [showConfirmRemove, setShowConfirmRemove] = useState<boolean>(false);
    const [administrator, setAdministrator] = useState<IAdministrator | undefined>(undefined);
    const [closed, setClosed] = useState<boolean>(true);
    const ApiService = useApiService();

    useEffect(() => {
        setClosed(initiallyClosed);
    }, [initiallyClosed]);

    const toggleClosed = () => setClosed(!closed);

    const getAdministrators = useCallback(async () => {
        if (!user) {
            return;
        }
        const response = await ApiService.getAdministrators(account.id, account.resellerId);
        if (!response) {
            return;
        }
        setAdministrators(response.data);
    }, [ApiService, account.id, account.resellerId, user]);

    useEffect(() => {
        if (!retrievedData) {
            setRetrievedData(true);
            getAdministrators();
        }
    }, [retrievedData, getAdministrators]);

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
        setNameError(false);
    };
    const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError(false);
    };
    const onAdd = async () => {
        let hasNameError = false;
        let hasEmailError = false;
        if (name === '' || name === undefined) {
            hasNameError = true;
        }
        if (email === '' || email === undefined || !isEmail(email)) {
            hasEmailError = true;
        }
        if (user && !hasNameError && !hasEmailError) {
            const response = await ApiService.addAdministrator(account.id, name, email);
            if (response && [ResponseCode.UserNotFound, ResponseCode.TenantNotFound].includes(response.code)) {
                setOpenUserNotFound(true);
                setNameError(true);
                setEmailError(true);
                return;
            } else if (
                !response ||
                (response && response.status < 0 && response.code !== ResponseCode.TenantNotFound)
            ) {
                setShowWarning(true);
                logError(Error(`Failed to add administrators to account ${account.id}`));
                return;
            }
            setName('');
            setEmail('');
            getAdministrators();
        }
        setNameError(hasNameError);
        setEmailError(hasEmailError);
    };

    const openConfirmRemove = (administrator: IAdministrator) => (event: MouseEvent) => {
        setAdministrator(administrator);
        setShowConfirmRemove(true);
    };
    const closeConfirmRemove = () => setShowConfirmRemove(false);

    const onRemove = async () => {
        if (!user || !administrator) {
            return;
        }
        closeConfirmRemove();
        await ApiService.removeAdministrator(account.id, administrator.name, administrator.email);
        setName('');
        setEmail('');
        getAdministrators();
    };

    const toggleOpenUserNotFound = () => setOpenUserNotFound(!openUserNotFound);
    const isConnected = ![AccountStatus.Initiated, AccountStatus.New].includes(account.status);

    return (
        <div className={classes.administrators}>
            <div className={classes.header} onClick={toggleClosed}>
                <Typography variant="h6" className={classes.title}>
                    {!isCompleteAction('addedNuliaAdmins') ? (
                        <div className={classes.completeState}>
                            <Tooltip title="Not complete">
                                <div className={classes.notComplete} />
                            </Tooltip>
                        </div>
                    ) : (
                        <div className={classes.completeState}>
                            <Tooltip title="Complete">
                                <div className={classes.complete} />
                            </Tooltip>
                        </div>
                    )}
                    {strings?.nuliaAdmins}
                </Typography>
                {closed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            <Collapse in={closed}>
                <div className={classes.body}>
                    {!isConnected && <div className={classes.notConnected}>{strings?.adminsNotConnected}</div>}
                    <div className={classes.text}>{strings?.adminsBulkAssign}</div>
                    <div className={classes.labels}>
                        <div className={clsx(classes.label, classes.firstLabel)}>{strings?.name}</div>
                        <div className={classes.label}>{strings?.email}</div>
                    </div>
                    {administrators &&
                        administrators.length > 0 &&
                        administrators.map((admin) => (
                            <Administrator administrator={admin} onRemove={openConfirmRemove} key={admin.email} />
                        ))}
                    <div className={classes.entry}>
                        <div className={classes.nameContainer}>
                            <Input onChange={onChangeName} value={name} style={{ width: '100%' }} error={nameError} />
                        </div>
                        <div className={classes.emailContainer}>
                            <Input
                                onChange={onChangeEmail}
                                value={email}
                                style={{ width: '100%' }}
                                error={emailError}
                            />
                        </div>
                    </div>
                    <SecondaryButton
                        onClick={onAdd}
                        style={{ width: administrators && administrators.length > 0 ? 129 : 100, marginTop: 24 }}
                        disabled={user?.accessLevel === AccessLevel.PlatformAdmin}
                    >
                        {administrators && administrators.length > 0 ? strings?.addAnother : strings?.add}
                    </SecondaryButton>
                    <Dialog
                        open={openUserNotFound}
                        onClose={toggleOpenUserNotFound}
                        onAccept={toggleOpenUserNotFound}
                        acceptText={strings?.okay}
                        title={strings?.adminsUserNotFoundDialogTitle}
                        text={strings?.adminsUserNotFoundDialogText}
                    />
                    {administrator && (
                        <Dialog
                            open={showConfirmRemove}
                            onClose={closeConfirmRemove}
                            onAccept={onRemove}
                            cancelText={strings?.cancel}
                            acceptText={strings?.confirm}
                            title={strings?.removeAdminDialogTitle}
                            text={strings?.removeAdminDialogText(administrator.name)}
                        />
                    )}
                </div>
            </Collapse>
        </div>
    );
};

export default Administrators;
