import { ChangeEvent, FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { AccountType, ResellerSimple } from '../../types/types';
import { Dialog, CheckboxChecked, CheckboxUnchecked, Tooltip, logError, Input } from 'common';
import AccountSearchInput from '../inputs/AccountSearchInput';
import { getActivities } from '../../utils/helper';
import { ResponseCode } from '../../types/responseTypes';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
import { useGetAccountData } from '../../utils/hooks';

const useStyles = makeStyles({
    addCustomer: {},
    inputs: {
        paddingBottom: 16,
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    checkboxLabel: {
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        marginLeft: 16,
    },
});

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept: () => void;
    initialReseller?: ResellerSimple;
    resellers: ResellerSimple[];
}

const AddCustomer: FC<Props> = ({ open, onClose, initialReseller, resellers, onAccept }) => {
    const classes = useStyles();
    const [reseller, setReseller] = useState<ResellerSimple | undefined>(undefined);
    const [customerName, setCustomerName] = useState<string>('');
    const [licenseCount, setLicenseCount] = useState<string>('');
    const [resellerError, setResellerError] = useState<boolean>(false);
    const [customerNameError, setCustomerNameError] = useState<boolean>(false);
    const [gdpr, setGdpr] = useState<boolean>(false);
    const [acting, setActing] = useState<boolean>(false);
    const { user, setShowWarning, activities, setActivities, strings } = useStore();
    const ApiService = useApiService();
    const getAccountData = useGetAccountData();

    useEffect(() => {
        setReseller(initialReseller);
    }, [initialReseller]);

    const toggleGdpr = () => setGdpr(!gdpr);

    const onChangeReseller = (value: string) => {
        const reseller = resellers.find((r) => r.name === value);
        setReseller(reseller);
        setResellerError(false);
    };
    const onChangeCustomerName = (event: ChangeEvent<HTMLInputElement>) => {
        setCustomerName(event.target.value);
        setCustomerNameError(false);
    };
    const handleAccept = async () => {
        let hasResellerError = false;
        let hasCustomerNameError = false;
        const resellerNames = resellers.map((r) => r.name);
        if (reseller === undefined || !resellerNames.includes(reseller.name)) {
            hasResellerError = true;
        }
        if (customerName === '') {
            hasCustomerNameError = true;
        }
        setResellerError(hasResellerError);
        setCustomerNameError(hasCustomerNameError);
        if (user && reseller && !hasResellerError && !hasCustomerNameError) {
            if (!reseller.id) {
                logError(Error(`Tried to add a customer with no reseller id for oid ${user.oid}`));
                setShowWarning(true);
                return;
            }
            setActing(true);
            const response = await ApiService.addCustomer(reseller.id, customerName, parseInt(licenseCount, 10), gdpr);
            if (!response || (response && response.status < 0 && response.code !== ResponseCode.CustomerExists)) {
                setShowWarning(true);
                return;
            } else if (response && response.status < 0 && response.code === ResponseCode.CustomerExists) {
                setCustomerNameError(true);
                return;
            }
            onAccept();
            await Promise.all([
                getAccountData(reseller.id),
                getActivities(activities, reseller.id, setActivities, ApiService),
            ]);
            resetForm();
            return;
        }
    };
    const handleClose = () => {
        resetForm();
        onClose();
    };
    const resetForm = () => {
        setReseller(initialReseller ? initialReseller : undefined);
        setCustomerName('');
        setLicenseCount('');
        setResellerError(false);
        setCustomerNameError(false);
        setGdpr(false);
        setActing(false);
    };

    const resellerData = resellers.map((r) => ({ name: r.name, type: AccountType.Reseller }));

    return (
        <Dialog
            open={open}
            onAccept={handleAccept}
            acceptText={strings?.create}
            acceptDisabled={acting}
            onClose={handleClose}
            cancelText={strings?.cancel}
            title={strings?.addCustomer}
            content={
                <div className={classes.addCustomer}>
                    <div className={classes.inputs}>
                        <AccountSearchInput
                            onChange={onChangeReseller}
                            value={reseller ? reseller.name : ''}
                            placeholder={strings?.reseller}
                            accounts={resellerData}
                            style={{ width: 336, marginBottom: 8 }}
                            error={resellerError}
                        />
                        <Input
                            autoFocus={true}
                            onChange={onChangeCustomerName}
                            value={customerName}
                            placeholder={strings?.customerName}
                            style={{ width: 336, marginBottom: 8 }}
                            error={customerNameError}
                        />
                    </div>
                    <Tooltip title={strings?.addCustomerGDPRTooltip ?? ''}>
                        <div className={classes.checkbox} onClick={toggleGdpr}>
                            {gdpr ? <CheckboxChecked /> : <CheckboxUnchecked />}
                            <div className={classes.checkboxLabel}>{strings?.addCustomerGDPRLabel}</div>
                        </div>
                    </Tooltip>
                </div>
            }
        />
    );
};

export default AddCustomer;
