import { FC } from 'react';
import { Colors } from 'common';

type Props = {
    selected?: boolean;
    mouseOver: boolean;
    mouseDown: boolean;
    disabled?: boolean;
};

const XCircleIcon: FC<Props> = ({ disabled, selected, mouseOver, mouseDown }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-x-circle"
        color={
            disabled
                ? Colors.disabled
                : mouseOver && !mouseDown
                ? Colors.textPrimary
                : selected || mouseDown
                ? Colors.purple
                : Colors.iconDefault
        }
    >
        <circle cx="12" cy="12" r="10" />
        <line x1="15" y1="9" x2="9" y2="15" />
        <line x1="9" y1="9" x2="15" y2="15" />
    </svg>
);

export default XCircleIcon;
