import { FC, useState } from 'react';
import { makeStyles, Typography, Box } from '@material-ui/core';
import InfoButton from '../buttons/InfoButton';
import { Button } from 'common';
import AddMonthCreditsDialog from '../dialogs/AddMonthCreditsDialog';
import { Customer } from '../../types/types';

const useStyles = makeStyles({
    monthCredits: {
        border: 'solid 1px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        width: 326,
        height: 118,
        padding: 16,
        paddingTop: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    monthCreditsContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flex: 1,
        marginBottom: -1,
    },
    text: {
        lineHeight: 'normal',
    },
});

interface Props {
    account: Customer;
}

const MonthCredits: FC<Props> = ({ account }) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const toggleDialog = () => setOpenDialog(!openDialog);

    return (
        <>
            <div className={classes.monthCredits}>
                <div className={classes.container}>
                    <Typography variant="overline">MONTH-CREDITS</Typography>
                    <InfoButton title="Month-Credits are ..." style={{ marginLeft: 8 }} />
                </div>
                <div className={classes.monthCreditsContainer}>
                    <div>
                        <div className={classes.container} style={{ marginBottom: 2 }}>
                            <Typography
                                variant="subtitle2"
                                component="div"
                                className={classes.text}
                                style={{ marginRight: 8 }}
                            >
                                Available
                            </Typography>
                            <Typography variant="body2" component="div" className={classes.text}>
                                2500
                            </Typography>
                        </div>
                        <div className={classes.container}>
                            <Box marginRight={1} className={classes.container}>
                                <Typography variant="subtitle2" component="div" className={classes.text}>
                                    Used Monthly
                                </Typography>
                                <InfoButton title="Month-Credits are ..." style={{ marginLeft: 8, marginBottom: 4 }} />
                            </Box>
                            <Typography variant="body2" component="div" className={classes.text}>
                                0
                            </Typography>
                        </div>
                    </div>
                    <Button onClick={toggleDialog} style={{ width: 80, marginBottom: 4 }}>
                        ADD
                    </Button>
                </div>
            </div>
            <AddMonthCreditsDialog open={openDialog} onClose={toggleDialog} account={account} />
        </>
    );
};

export default MonthCredits;
