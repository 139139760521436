import { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckIcon: FC = () => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" style={{ height: 16, width: 16 }}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.13807 8.19526C2.87772 7.93491 2.45561 7.93491 2.19526 8.19526C1.93491 8.45561 1.93491 8.87772 2.19526 9.13807L5.5286 12.4714C5.78895 12.7318 6.21105 12.7318 6.4714 12.4714L13.8047 5.13807C14.0651 4.87772 14.0651 4.45561 13.8047 4.19526C13.5444 3.93491 13.1223 3.93491 12.8619 4.19526L6 11.0572L3.13807 8.19526Z"
            fill="#654EA3"
        />
    </SvgIcon>
);

export default CheckIcon;
