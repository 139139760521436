import { FC, useCallback, useEffect, useState } from 'react';
import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import { AccessLevel } from '../../types/types';
import { useApiService } from '../../services/ApiServiceProvider';
import { useStore } from '../../store/StoreProvider';

const useStyles = makeStyles({
    licenseSyncToggle: ({ width }: { width?: number }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: width ? 'space-between' : undefined,
        width: width,
        minWidth: width,
        maxWidth: width,
    }),
    helpContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 8,
    },
    toggle: {
        display: 'flex',
        alignItems: 'center',
        height: 24,
    },
});

interface Props {
    customerId: string;
    width?: number;
}

const EmailToggle: FC<Props> = ({ customerId, width }) => {
    const classes = useStyles({ width });
    const [receivedData, setReceivedData] = useState<boolean>(false);
    const [onboardingEmails, setOnboardingEmails] = useState<boolean>(false);
    const [weeklyEmail, setWeeklyEmail] = useState<boolean>(false);
    const ApiService = useApiService();
    const { user } = useStore();

    const getEmailSettings = useCallback(async () => {
        const response = await ApiService.getEmailSettings(customerId);

        setOnboardingEmails(response?.data.disableOnboarding ?? false);
        setWeeklyEmail(response?.data.disableWeekly ?? false);
    }, [ApiService, customerId]);

    useEffect(() => {
        if (!receivedData) {
            getEmailSettings();
            setReceivedData(true);
        }
    }, [getEmailSettings, receivedData]);

    const onChangeOnboarding = async () => {
        await ApiService.setEmailSettings(customerId, !onboardingEmails, weeklyEmail);
        setOnboardingEmails(!onboardingEmails);
    };

    const onChangeWeekly = async () => {
        await ApiService.setEmailSettings(customerId, onboardingEmails, !weeklyEmail);
        setWeeklyEmail(!weeklyEmail);
    };

    return (
        <div style={{ width: '70%', marginTop: 16, marginBottom: 16 }}>
            <Box className={classes.licenseSyncToggle} justifyContent="space-between">
                <div className={classes.helpContainer}>
                    <Typography variant="caption">Send Onboarding Emails</Typography>
                </div>
                <div className={classes.toggle}>
                    <Typography variant="body2" component="div">
                        {onboardingEmails ? 'Off' : 'On'}
                    </Typography>
                    <Switch
                        checked={!onboardingEmails}
                        onChange={onChangeOnboarding}
                        disabled={user?.accessLevel === AccessLevel.PlatformAdmin}
                    />
                </div>
            </Box>
            <Box className={classes.licenseSyncToggle} justifyContent="space-between">
                <div className={classes.helpContainer}>
                    <Typography variant="caption">Send Weekly Emails</Typography>
                </div>
                <div className={classes.toggle}>
                    <Typography variant="body2" component="div">
                        {weeklyEmail ? 'Off' : 'On'}
                    </Typography>
                    <Switch
                        checked={!weeklyEmail}
                        onChange={onChangeWeekly}
                        disabled={user?.accessLevel === AccessLevel.PlatformAdmin}
                    />
                </div>
            </Box>
        </div>
    );
};

export default EmailToggle;
