import { FC } from 'react';
import { SvgIcon } from '@material-ui/core';

const DownloadIcon: FC = () => (
    <SvgIcon viewBox="0 0 16 16" style={{ height: 16, width: 16 }}>
        <path
            fill="#654EA3"
            d="M14 9.333c.342 0 .624.258.662.59l.005.077v2.667a2 2 0 0 1-1.883 1.996l-.117.004H3.333a2 2 0 0 1-1.996-1.883l-.004-.117V10a.667.667 0 0 1 1.33-.078l.004.078v2.667c0 .342.257.623.589.662l.077.004h9.334a.667.667 0 0 0 .662-.589l.004-.077V10c0-.368.299-.667.667-.667zM7.922 1.338L8 1.333c.342 0 .624.258.662.59L8.667 2v6.39l2.195-2.195a.667.667 0 0 1 .88-.055l.063.055c.24.24.259.619.055.88l-.055.063-3.334 3.333-.029.028-.045.037-.074.048-.075.035-.07.024-.079.016-.06.007H7.96l-.039-.004-.077-.014-.072-.021-.054-.023-.065-.035-.064-.044a.67.67 0 0 1-.06-.054L4.195 7.138a.667.667 0 0 1 .88-.998l.063.055L7.333 8.39V2c0-.342.258-.624.59-.662L8 1.333l-.078.005z"
        />
    </SvgIcon>
);

export default DownloadIcon;
