import { FC, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from './Page';
import PageContent from './PageContent';
import { RESOURCES_PATH } from '../utils/constants';
import { ResellerType, Resource } from '../types/types';
import Header from './header/Header';
import clsx from 'clsx';
import VideoDialog from './dialogs/VideoDialog';
import { useStore } from '../store/StoreProvider';
import { Button, Colors } from 'common';
import { useApiService } from '../services/ApiServiceProvider';

const useStyles = makeStyles({
    resources: {
        height: '100vh',
    },
    resourcesContainer: {
        padding: 24,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)',
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
        marginBottom: 24,
        marginTop: 16,
    },
    resource: {
        border: '1px solid rgba(101,78,163,0.12)',
        backgroundColor: '#FFFFFF',
        borderRadius: 4,
        padding: 24,
        display: 'flex',
        justifyContent: 'space-between',
    },
    innerResource: {
        marginBottom: 16,
    },
    name: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '20px',
    },
    description: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        marginTop: 8,
    },
    buttons: {
        width: 116,
        marginLeft: 24,
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    badge: {
        backgroundColor: Colors.textPrimary,
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 56,
        marginRight: 8,
    },
    badgeText: {
        fontSize: 12,
        fontFamily: 'Ubuntu',
        color: Colors.white,
    },
});

const Resources: FC = () => {
    const classes = useStyles();
    const { user, setShowWarning, setWarningData, strings } = useStore();
    const [openVideo, setOpenVideo] = useState<boolean>(false);
    const [videoSrc, setVideoSrc] = useState<string>('');
    const [resources, setResources] = useState<Resource[] | undefined>(undefined);
    const [receivedData, setReceivedData] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        if (!user) {
            return;
        }
        const getResources = async () => {
            const response = await ApiService.getResources(user.resellerType === ResellerType.Starter);
            if (!response) {
                return;
            }
            setResources(response);
        };

        if (!receivedData) {
            setReceivedData(true);
            getResources();
        }
    }, [user, receivedData, setWarningData, setShowWarning, ApiService]);

    const LoadingResource: FC<{ inner?: boolean }> = ({ inner }) => (
        <Box marginBottom={inner ? 2 : undefined}>
            <Skeleton variant="rect" height={118} width={1040} />
        </Box>
    );

    const onClick = (resource: Resource) => (event: unknown) => {
        if (resource.type === 'pdf') {
            window.open(`${RESOURCES_PATH}${resource.url}`, '_blank');
        } else {
            setVideoSrc(`${RESOURCES_PATH}${resource.url}`);
            setOpenVideo(true);
        }
    };
    const closeVideo = () => setOpenVideo(false);

    return (
        <Page>
            <Header />
            <PageContent>
                <div className={classes.resources}>
                    <div className={classes.title}>Resources</div>
                    <div className={classes.resourcesContainer}>
                        {resources ? (
                            <>
                                {resources.map((resource, index) => (
                                    <div
                                        key={`${resource.title}${index}`}
                                        className={clsx(
                                            classes.resource,
                                            index < resources.length - 1 ? classes.innerResource : undefined
                                        )}
                                    >
                                        <div>
                                            {resource.starter ? (
                                                <div className={classes.titleWrapper}>
                                                    <div className={classes.badge}>
                                                        <div className={classes.badgeText}>{strings?.starter}</div>
                                                    </div>
                                                    <div className={classes.name}>{resource.title}</div>
                                                </div>
                                            ) : (
                                                <div className={classes.name}>{resource.title}</div>
                                            )}
                                            <div className={classes.description}>{resource.description}</div>
                                        </div>
                                        <div className={classes.buttons}>
                                            <Button onClick={onClick(resource)} style={{ width: 120 }}>
                                                {strings?.view}
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource />
                            </>
                        )}
                    </div>
                </div>
                <VideoDialog open={openVideo} onClose={closeVideo} src={videoSrc} />
            </PageContent>
        </Page>
    );
};

export default Resources;
