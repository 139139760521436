import { makeStyles } from '@material-ui/styles';
import { CSSProperties, FC, MouseEvent } from 'react';
import { Colors } from 'common';

const useStyles = makeStyles({
    button: {
        height: 36,
        minHeight: 36,
        maxHeight: 36,
        borderRadius: 4,
        fontSize: 10,
        letterSpacing: 1.25,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.4)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        border: 0,
        outline: 0,
        padding: 0,
        '&:hover': {
            color: Colors.textSecondary,
        },
        '&:focus': {
            outline: 0,
        },
        '&:active': {
            color: Colors.purple,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabled: {
        height: 36,
        minHeight: 36,
        maxHeight: 36,
        borderRadius: 4,
        fontSize: 10,
        letterSpacing: 1.25,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        textTransform: 'uppercase',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: 0,
        color: Colors.disabled,
        cursor: 'not-allowed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 0,
    },
});

interface Props {
    id?: string;
    onClick: (event: MouseEvent) => void;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
    autoFocus?: boolean;
    title?: string;
    key?: string;
    className?: string;
    style?: CSSProperties;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
}

const GreyTextButton: FC<Props> = ({
    id,
    onClick,
    disabled,
    title,
    key,
    className,
    style,
    children,
    onMouseOver,
    onMouseOut,
}) => {
    const classes = useStyles();
    return (
        <button
            id={id}
            onClick={onClick}
            title={title}
            key={key}
            className={disabled ? classes.disabled : className ? className : classes.button}
            style={style}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {children}
        </button>
    );
};

export default GreyTextButton;
